import React from 'react';
import { useDispatch } from 'react-redux';
import { convertNumberToLocal, convertStringToLocal, sortBy, sum } from '../../../services';
import { NUMBER_DECIMAL_LENGTH, TRANSLATIONS } from '../../../constants';
import { CostsPerYearService, History } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { setSelectedDropdownValues } from '../../../store';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Grid from '../../common/Grid';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';

export interface HistoryHeaderProps {
    costsTotalVolume: number;
    currentHistory: History | undefined;
    getPlannedOrActualCost: (service: CostsPerYearService, fiscalYearId: number) => number;
}

const HistoryHeader = ({ costsTotalVolume, currentHistory, getPlannedOrActualCost }: HistoryHeaderProps) => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    const costsActualPlan = currentHistory?.costsPerYears?.reduce((currentSum, x) =>
        x.services.length
            ? currentSum + x.services.reduce((serviceSum, service) =>
                serviceSum + getPlannedOrActualCost(service, x.fiscalYearId)
                , 0)
            : currentSum
        , 0) ?? 0;

    const costsSupportedVolume = costsTotalVolume - sum(currentHistory?.volumes ?? [], 'softwareSales');

    const costsCalculated = sum(currentHistory?.costsCalculations ?? [], 'cost', 'quantity');
    const costsActualPlanOfVolume = convertStringToLocal((costsSupportedVolume ? costsActualPlan / costsSupportedVolume * 100 : 0).toFixed(NUMBER_DECIMAL_LENGTH));
    const costsCalculatedOfVolume = convertStringToLocal((costsSupportedVolume ? costsCalculated / costsSupportedVolume * 100 : 0).toFixed(NUMBER_DECIMAL_LENGTH));
    const dateSubstringLength = 10;
    return (
        <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} >
            <Grid columns={4} columnGap={15} rowGap={15} >
                <Select
                    label={translate(TRANSLATIONS.main.dateOfAuthorizedFolder)} value={currentHistory}
                    values={sortBy([...project?.histories ?? []], x => x.pegaStartDate)}
                    mapToString={x => x?.pegaStartDate?.substring(0, dateSubstringLength) ?? ''}
                    mapToKey={x => x?.pegaStartDate ?? ''}
                    onSelect={x => dispatch(setSelectedDropdownValues({ historyDateOfAuthorizedFolder: x }))}
                />
                <TextInput label={translate(TRANSLATIONS.main.totalVolume)} name='volumeTotalSales' value={convertNumberToLocal(costsTotalVolume)} disabled />
                <TextInput label={translate(TRANSLATIONS.main.costsCalculatedOfSupportedVolume)} name='costsCalculated' value={convertNumberToLocal(costsCalculated)} disabled />
                <TextInput label={translate(TRANSLATIONS.main.costsPlan)} name='costsActualPlan' value={convertNumberToLocal(costsActualPlan)} disabled />
                <TextInput label={translate(TRANSLATIONS.main.approvalNo)} name='ppaNo' value={currentHistory?.ppaNumber ?? ''} disabled />
                <TextInput label={translate(TRANSLATIONS.main.supportedVolume)} name='supportedVolume' value={convertNumberToLocal(costsSupportedVolume)} disabled />
                <TextInput label={translate(TRANSLATIONS.main.costsCalculated)} name='costsCalculatedOfSupportedVolume' value={`${costsCalculatedOfVolume} %`} disabled />
                <TextInput label={translate(TRANSLATIONS.main.costsPlanOfSupportedVolume)} name='costsPlanOfSupportedVolume' value={`${costsActualPlanOfVolume} %`} disabled />
            </Grid>
        </Flex>
    );
};

export default HistoryHeader;
