import React, { KeyboardEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getTabLink, removeAccessToken } from '../../services';
import { Language } from '../../enums';
import { TABS, PROTECTED_TABS, PROJECT_TABS, TRANSLATIONS, ADMIN_TABS, ADMIN } from '../../constants';
import { removeBanner, setLanguage, setProjectSearchIds } from '../../store';
import { useAppSelector, useOpenAnimations, useResized, useScrolledPast, useTranslate } from '../../hooks/common';
import { useHook } from '../../hooks';
import HeaderAction, { HeaderActionProps } from './HeaderAction';
import HeaderMegaMenu from './HeaderMegaMenu';
import HeaderTabs, { Tab } from './HeaderTabs';
import Banners from '../common/Banners';
import Button, { ButtonType } from '../common/Button';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import MenuIcon from '../common/MenuIcon';
import Modal from '../common/Modal';
import Title from '../common/Title';
import siemensLogo from '../../../assets/images/siemens-logos/siemens-logo-petrol.svg';
import ProjectHeader from './ProjectHeader';
import ProjectSearch from '../application/Project/ProjectSearch';

interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { onProjectApprove, onProjectCreate, onProjectSaveInBackground, onProjectUpdate } = useHook(x => x.projectSave)();
    const { onGeneratePega } = useHook(x => x.documentGeneration)();
    const activeTabType = useAppSelector(state => state.layout.activeTabType);
    const banners = useAppSelector(state => state.banners.banners);
    const project = useAppSelector(state => state.project.project);
    const projectChanged = useAppSelector(state => state.project.projectChanged);
    const projectHeaderDisabled = useAppSelector(state => state.project.projectHeaderDisabled);
    const projectSearchIds = useAppSelector(state => state.project.projectSeacrhIds);
    const userRoles = useAppSelector(state => state.user.roles);
    const [animationClass, handleAnimationEnd] = useOpenAnimations(projectHeaderDisabled);
    const [menuOpen, setMenuOpen] = useState(false);
    const [newId, setNewId] = useState('');
    const [noSearchIds, setNoSearchIds] = useState(false);
    const [returnToSearch, setReturnToSearch] = useState(false);
    const [showSaveProjectModal, setShowSaveProjectModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const projectChangeHandled = useRef(false);
    const mobileView = useResized(x => x <= 767);
    const scrolled = useScrolledPast();
    const translate = useTranslate();
    const languages = Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(x => Number(x));
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const hideTabs = scrolled || mobileView;
    const availableTabs = isAuthenticated
        ? project
            ? PROJECT_TABS.map<Tab>(x => ({ ...x, to: x.to.replace('{id}', project?.id ? project.id : 'new') }))
            : [...PROTECTED_TABS, ...userRoles.some(x => x.name == ADMIN) ? ADMIN_TABS : []]
        : TABS;
    const handleLogout = () => {
        instance.logoutRedirect();
        removeAccessToken();
    };

    const headerActions: HeaderActionProps[] = [
        ...project && project.id
            ? [{
                dropdownActions: [
                    ...projectSearchIds && projectSearchIds.length
                        ? [
                            {
                                name: translate(TRANSLATIONS.header.nextProject).toString(),
                                iconType: IconType.ArrowRight,
                                onClick: () => handleSwitchClick(projectSearchIds, project.id, 1),
                                disabled: project.id === projectSearchIds[projectSearchIds.length - 1],
                                keepOpen: true
                            }, {
                                name: translate(TRANSLATIONS.header.previousProject).toString(),
                                iconType: IconType.ArrowLeft,
                                onClick: () => handleSwitchClick(projectSearchIds, project.id, -1),
                                disabled: project.id === projectSearchIds[0],
                                keepOpen: true
                            }
                        ]
                        : [],
                    {
                        name: translate(TRANSLATIONS.header.searchProject).toString(),
                        iconType: IconType.SearchSmall,
                        onClick: () => handleSearchClick()
                    }
                ],
                iconType: IconType.Sort,
                title: translate(TRANSLATIONS.header.switchProject).toString()
            }]
            : [],
        {
            dropdownActions: [
                {
                    name: isAuthenticated ? translate(TRANSLATIONS.header.logout).toString() : translate(TRANSLATIONS.header.login).toString(),
                    onClick: () =>
                    {
                        isAuthenticated
                            ? handleLogout()
                            : instance.loginRedirect();
                    },
                    iconType: IconType.Login
                }],
            iconType: IconType.User,
            title: accounts[0] ? `${accounts[0].name}` : translate(TRANSLATIONS.header.anonymus)
        },       
        {
            dropdownActions: [{ name: translate(TRANSLATIONS.header.reportProblem).toString(), iconType: IconType.Email, onClick: () => window.location.href = 'mailto:apps-help.industry@siemens.com' }],
            iconType: IconType.Support,
            title: translate(TRANSLATIONS.header.support).toString()
        }
    ];

    const getSearchId = (ids: string[], id: string, shift: number) => ids[ids.findIndex(x => x === id) + shift];

    const navigateToProject = (id: string) => navigate(`project/${id}${getTabLink(activeTabType)}`);

    const handleLanguageChange = (language: Language) => {
        dispatch(setLanguage(language));
        window.localStorage.setItem('language', language.toString());
    };

    const closeSearch = () => {
        noSearchIds && dispatch(setProjectSearchIds(undefined));
        setShowSearchModal(false);
    };

    const cancelSave = () => {
        setShowSaveProjectModal(false);
        returnToSearch && setShowSearchModal(true);
    };

    const switchAndSave = async () => {
        setShowSaveProjectModal(false);

        const success = await (project?.id ? onProjectUpdate() : onProjectCreate());

        if (success) {
            navigateToProject(newId);
            projectChangeHandled.current = true;
        }  
    };

    const switchWithoutSave = () => {
        setShowSaveProjectModal(false);
        navigateToProject(newId);
        projectChangeHandled.current = true;
    };

    const handleProjectClick = (id: string) => {
        if (projectChanged) {
            setNewId(id);
            setShowSearchModal(false);
            setReturnToSearch(true);
            setShowSaveProjectModal(true);
        } else {
            setShowSearchModal(false);
            navigateToProject(id);
        }
    };

    const handleSearchClick = () => {
        setNoSearchIds(!projectSearchIds?.length);
        setShowSearchModal(true);
    };

    const handleSwitchClick = (projectSearchIds: string[], projectId: string, shift: 1 | -1) => {
        const id = getSearchId(projectSearchIds, projectId, shift);

        if (projectChanged) {
            setNewId(id);
            setReturnToSearch(false);
            setShowSaveProjectModal(true);
        } else {
            navigateToProject(id);
        }
    };

    const handleSkipButtonKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();

            location.hash = '';
            location.hash = '#main';
        }
    };

    const renderSaveProjectModal = () => (
        <Modal open={showSaveProjectModal} movable>
            <Title>{translate(TRANSLATIONS.main.switchProject).toString()}</Title>
            <div style={{ margin: '10px 0' }}>{translate(TRANSLATIONS.main.switchProjectMessage).toString()}</div>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                <Button type={ButtonType.Tertiary} onClick={cancelSave}>
                    {translate(TRANSLATIONS.main.cancel).toString()}
                </Button>
                <Button type={ButtonType.Secondary} onClick={switchWithoutSave}>
                    {translate(TRANSLATIONS.main.discard).toString()}
                </Button>
                <Button type={ButtonType.Primary} onClick={switchAndSave}>
                    {translate(TRANSLATIONS.main.save).toString()}
                </Button>
            </Flex>
        </Modal>
    );

    const renderSearchModal = () => (
        <Modal open={showSearchModal} onClose={closeSearch} allowClickAway fullWidth>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Button type={ButtonType.Tertiary} onClick={closeSearch}>
                    <Icon type={IconType.Close} /> 
                </Button>
            </Flex>
            <ProjectSearch key={Boolean(project).toString()} onProjectClick={handleProjectClick} hideNewButton />
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                <Button type={ButtonType.Tertiary} onClick={closeSearch}>
                    {translate(TRANSLATIONS.main.cancel).toString()}
                </Button>
            </Flex>
        </Modal>
    );

    return (
        <header>
            <div className='main-row-container'>
                <ContentContainer header>
                    <div className='main-row'>
                        <div className='skip-button' tabIndex={0} onKeyDown={handleSkipButtonKeyDown}>
                            {translate(TRANSLATIONS.header.skipToMainContent).toString()}
                        </div>
                        <div className='header-row-container'>
                            <img className='siemens-logo' src={siemensLogo} alt='Siemens Logo' />
                        </div>
                        {title &&
                            <div className='header-row-container'>
                                <div className='title'>{title}</div>
                            </div>
                        }
                        <div className='action-container'>
                            {headerActions.map(x => <HeaderAction key={x.title} dropdownActions={x.dropdownActions} iconType={x.iconType} title={x.title} />)}
                            <div className='header-row-container'>
                                <MenuIcon open={menuOpen} onClick={() => setMenuOpen(x => !x)} />
                            </div>
                        </div>
                    </div>
                    {project &&
                        <ProjectHeader projectChangeHandled={projectChangeHandled} onProjectApprove={onProjectApprove} onProjectCreate={onProjectCreate}
                            onProjectSaveInBackground={onProjectSaveInBackground} onProjectUpdate={onProjectUpdate} onGeneratePega={onGeneratePega} />
                    }
                </ContentContainer>
            </div>
            <div className={`addtitional-rows-container ${scrolled ? 'hiden' : ''}`}>
                <div className='secondary-row-container'>
                    <ContentContainer header>
                        <div className='secondary-row'>
                            <HeaderTabs hidden={hideTabs} tabs={availableTabs} />
                        </div>
                    </ContentContainer>
                </div>
                <Banners banners={banners} removeBanner={x => dispatch(removeBanner(x))} />
            </div>
            <HeaderMegaMenu navigationItems={availableTabs.map(x => ({ ...x }))} open={menuOpen} onClose={() => setMenuOpen(false)} />
            <div className={`header-mask ${animationClass}  ${hideTabs ? 'hide-tabs' : ''}`} onAnimationEnd={handleAnimationEnd} />
            {renderSaveProjectModal()}
            {renderSearchModal()}
        </header>
    );
};

export default Header;
