import React from 'react';
import { TRANSLATIONS } from '../../../constants';
import { useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import Title from '../../common/Title';

export interface KuebModalProps {
    handleKuebModalCancel: () => void;
    handleKuebModalCreateDocument: () => void;
    showKuebModal: boolean;
}

const KuebModal = ({ handleKuebModalCancel, handleKuebModalCreateDocument, showKuebModal }: KuebModalProps) => {

    const translate = useTranslate();

    return <Modal open={showKuebModal} movable>
            <SpacingContainer>
                <Title>{translate(TRANSLATIONS.main.createKueb)}</Title>
                {translate(TRANSLATIONS.main.kuebWarningMessage)}
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={handleKuebModalCancel}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary}
                        onClick={handleKuebModalCreateDocument}
                    >
                        {translate(TRANSLATIONS.main.createDocument)}
                    </Button>
                </Flex>
            </SpacingContainer>
        </Modal>;
};

export default KuebModal;
