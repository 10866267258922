import React from 'react';
import { isDateValid } from '../../../services';
import { TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { GeneralInputsWithDateProps } from './BaseInputs';
import DateInput from '../../common/DateInput';

const DateInputs = ({ closed, handleChange, getDateValue }: GeneralInputsWithDateProps) => {
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    return (
        <div className='form-group'>
            <div className='length-3' >
                <DateInput label={translate(TRANSLATIONS.main.firstTimeCreated)} value={getDateValue(project?.created)} disabled onChange={x => handleChange({ created: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.discuss)} value={getDateValue(project?.discussStatusDate)} disabled onChange={x => handleChange({ discussStatusDate: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.offer)} value={getDateValue(project?.offer)} disabled={closed} error={!isDateValid(project?.offer)} onChange={x => handleChange({ offer: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.decision)} value={getDateValue(project?.decision)} disabled={closed} error={!isDateValid(project?.decision)} onChange={x => handleChange({ decision: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.order)} value={getDateValue(project?.orderStatusDate)} disabled onChange={x => handleChange({ orderStatusDate: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.sop)} value={getDateValue(project?.sop)} disabled={closed} error={!isDateValid(project?.sop)} onChange={x => handleChange({ sop: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.endOfWarrantyDate)} value={getDateValue(project?.endOfWarrantyDate)} disabled={closed} error={!isDateValid(project?.endOfWarrantyDate)} onChange={x => handleChange({ endOfWarrantyDate: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.closed)} value={getDateValue(project?.closedStatusDate)} disabled onChange={x => handleChange({ closedStatusDate: x })} />
            </div>
        </div>
    );
};

export default DateInputs;
