import { EMPTY_COST_PER_YEAR_SERVICE } from '../constants';
import { CostCalculation, CostsPerYear, CostsPerYearService } from '../models';
import { sum } from './Calculations';

export const getPlannedActualSums = (serviceList: CostCalculation[], costsPerYears?: CostsPerYear[], key: keyof CostsPerYearService = 'plannedCost') => {
    return serviceList
        .map(x => costsPerYears?.filter(y => y.services.find(z => z.serviceCategoryId === x.serviceCategoryId))
            .map(y => y.services.find(z => z.serviceCategoryId === x.serviceCategoryId) ?? EMPTY_COST_PER_YEAR_SERVICE) ?? [EMPTY_COST_PER_YEAR_SERVICE]
        ).map(s => sum(s, key));
};

export const hasServiceActualCost = (services: CostsPerYearService[]) => services.some(x => x.actualCost != null);
