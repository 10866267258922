import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api } from '../../services';
import { Layout, LayoutSettingsTemplateCreate, LayoutSettingsTemplateUpdate } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { useMounted, useTranslate } from '../common';
import { addSnackbar, setReportModalLoading, setReportPageLoading } from '../../store';
import { SnackbarType } from '../../components/common/Snackbar';

export const useLayouts = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const mounted = useMounted();
    const [layouts, setLayouts] = useState<Layout[]>([]);

    const handleLayoutsLoad = async () => {
        dispatch(setReportPageLoading(true));

        await loadLayouts();

        dispatch(setReportPageLoading(false));
    };

    const handleLayoutSettingsTemplateCreate = async (layoutSettingsTemplate: LayoutSettingsTemplateCreate) => {
        let success = false;

        dispatch(setReportModalLoading(true));

        await apiCall(
            Api.createLayoutSettingsTemplate(layoutSettingsTemplate),
            async () => {
                success = true;

                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.layoutSettingsTemplateCreatedSuccessfully), type: SnackbarType.Success }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotCreateLayoutSettingsTemplate), type: SnackbarType.Error }));
            }
        );

        await loadLayouts();

        dispatch(setReportModalLoading(false));

        return success;
    };

    const handleLayoutSettingsTemplateDelete = async (id: number) => {
        let success = false;

        dispatch(setReportModalLoading(true));

        await apiCall(
            Api.deleteLayoutSettingsTemplate(id),
            async () => {
                success = true;

                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.layoutSettingsTemplateDeletedSuccessfully), type: SnackbarType.Success }));

                await loadLayouts();
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotDeleteLayoutSettingsTemplate), type: SnackbarType.Error }));
            }
        );

        dispatch(setReportModalLoading(false));

        return success;
    };

    const handleLayoutSettingsTemplateUpdate = async (layoutSettingsTemplate: LayoutSettingsTemplateUpdate) => {
        let success = false;

        dispatch(setReportModalLoading(true));

        await apiCall(
            Api.updateLayoutSettingsTemplate(layoutSettingsTemplate),
            async () => {
                success = true;

                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.layoutSettingsTemplateUpdatedSuccessfully), type: SnackbarType.Success }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotUpdateLayoutSettingsTemplate), type: SnackbarType.Error }));
            }
        );

        await loadLayouts();

        dispatch(setReportModalLoading(false));

        return success;
    };

    const loadLayouts = async () => {
        return apiCall(
            Api.getLayouts(),
            async x => {
                mounted.current && setLayouts(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadLayouts), type: SnackbarType.Error }));
            }
        );
    };

    return {
        layouts,
        onLayoutsLoad: handleLayoutsLoad,
        onLayoutSettingsTemplateCreate: handleLayoutSettingsTemplateCreate,
        onLayoutSettingsTemplateDelete: handleLayoutSettingsTemplateDelete,
        onLayoutSettingsTemplateUpdate: handleLayoutSettingsTemplateUpdate
    };
};
