import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';
import { TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { useTranslate } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';
import ContentContainer from '../../common/ContentContainer';


const Home = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Home));
    }, []);

    return (
        <ContentContainer>
            <h1>{translate(TRANSLATIONS.helperPages.homeMessage)}</h1>
            {isAuthenticated && <p>{translate(TRANSLATIONS.helperPages.homeLoginMessage)}</p>}
        </ContentContainer>
    );
};

export default Home;
