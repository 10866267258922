import { Country } from '../models';
import { Continent } from '../enums/Continent';

export const EMPTY_COUNTRY: Country = {
    id: 0,
    city: '',
    continent: Continent.Africa,
    currency: '',
    exchangeRate: 1,
    filenameSaContract: '',
    filenameTpa: '',
    isoKey: '',
    name: '',
    nameGerman: '',
    orgId: '',
    originalId: 0,
    postcode: '',
    rcKey: '',
    regionalCompany: '',
    signatoryKB: '',
    signatoryTL: '',
    street: '',
    taxLawManuallyEnabled: false
};
