import React, { useEffect, useState } from 'react';
import { HeaderTabType } from '../../../enums';
import { setActiveTabType } from '../../../store';
import { useDispatch } from 'react-redux';
import ProjectAttachmentsUploadModal from './Modals/ProjectAttachmentsUploadModal';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Title from '../../common/Title';
import Button, { ButtonType } from '../../common/Button';
import Table from '../../common/Table';
import Icon, { IconType } from '../../common/Icon';
import { getFormattedDateTime, toggleListElement } from '../../../services';
import Checkbox from '../../common/Checkbox';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useHook } from '../../../hooks';
import { TRANSLATIONS } from '../../../constants';

const ProjectAttachmentsTab = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const { onDeleteProjectAttachments, onDownloadAttachment } = useHook(x => x.projectAttachment)();
    const projectId = useAppSelector(state => state.project.project?.id);
    const projectAttachments = useAppSelector(state => state.project.projectAttachments);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.ProjectAttachments));
    }, []);

    const renderCheckbox = (id: string) => (
        <Flex direction={FlexDirection.Column} justification={FlexJustification.Center} style={{ paddingLeft: 3, paddingRight: 3 }} >
            <Checkbox label='' checked={selectedIds.includes(id)} onChange={() => setSelectedIds(toggleListElement(selectedIds, id))} />
        </Flex>
    );

    const deleteSelectedItems = () => {
        if (projectId) {
            onDeleteProjectAttachments(selectedIds, projectId);
            setSelectedIds([]);
        }
    }; 

    const downloadSelectedItems = () => {
        const downloadableAttachments = projectAttachments
            .filter(item => selectedIds.includes(item.id))
            .map(attachment => attachment.azureContainerTargetFileName);
        if (projectId) {
            downloadableAttachments.forEach(item => {
                onDownloadAttachment(item, projectId);
            });
        }
    };

    return (
        <>
            <Flex justification={FlexJustification.SpaceBetween} direction={FlexDirection.Row} style={{ marginBottom: 32, marginTop: 24 }}>
                <Title>{translate(TRANSLATIONS.main.attachments)}</Title>
                <Flex justification={FlexJustification.SpaceBetween} direction={FlexDirection.Row} gap={8}>
                    <Button type={ButtonType.Primary} onClick={() => setUploadModalOpen(true)}>
                        <Icon type={IconType.Upload} />
                        {translate(TRANSLATIONS.main.upload)}
                    </Button>
                    {Boolean(selectedIds.length) && 
                        <>
                            <Button type={ButtonType.Tertiary} onClick={() => deleteSelectedItems()}>
                                <Icon type={IconType.Trash} />
                                {translate(TRANSLATIONS.main.delete)}
                            </Button>
                            <Button type={ButtonType.Tertiary} onClick={() => downloadSelectedItems()}>
                                <Icon type={IconType.Download} />
                                {translate(TRANSLATIONS.main.download)}
                            </Button>
                        </>
                    }
                </Flex>
            </Flex>
            <Table
                columns={[
                    { key: 'checkbox', min: 'max-content', max: 'max-content', uninteractive: true },
                    { label: 'Filename', bold: true },
                    { label: 'Created by', bold: true },
                    { label: 'Created at', bold: true }
                ]}
                rows={projectAttachments.map(item => (
                    {
                        key: item.id,
                        selected: selectedIds.includes(item.id),
                        onClick: () => setSelectedIds(toggleListElement(selectedIds, item.id)),
                        cells: [
                            { content: renderCheckbox(item.id) },
                            { content: item.azureContainerTargetFileName },
                            { content: item.createdBy },
                            { content: getFormattedDateTime(item.created.toString()) }
                            ]
                    }
                ))}
                hoverable
            />
            {projectId && <ProjectAttachmentsUploadModal open={uploadModalOpen} onOpenChange={setUploadModalOpen} projectId={projectId} />}
        </>
    );
};

export default ProjectAttachmentsTab;
