import { ProjectStatus } from '../enums';
import { Project } from '../models';
import { EMPTY_CONTACT } from './EmptyContact';
import { EMPTY_TECHNICAL_INFO } from './EmptyTechnicalInfo';

export const EMPTY_PROJECT: Project = {
    id: '',
    comment: '',
    competitor: '',
    endUserAgreementLink: '',
    endUserAgreementSigned: false,
    hqCommercialResponsible: EMPTY_CONTACT,
    hqTechnicalResponsible: EMPTY_CONTACT,
    isChanged: false,
    isLocked: false,
    localTechnicalResponsible: EMPTY_CONTACT,
    location: '',
    name: '',
    originalId: 0,
    productionArea: '',
    reminder: '',
    sapNr: '',
    status: ProjectStatus.Intern,
    technicalInfo: EMPTY_TECHNICAL_INFO
};
