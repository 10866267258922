export enum ProjectStatusType {
    InternalProject,
    Info,
    Discuss,
    Cancelled,
    Lost,
    Order,
    Production,
    Closed
}
