import React, { RefObject } from 'react';
import { InputColors } from '../../../enums';
import { convertNumberToLocal, sum } from '../../../services';
import { CostCalculation, CostsPerYear, CostsPerYearService } from '../../../models';
import { MAX_INTEGER, NUMBER_FORMAT_WITH_SEPARATOR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common'; 
import { useProjectClosed } from '../../../hooks/application';
import Button, { ButtonType } from '../../common/Button';
import Icon, { IconType } from '../../common/Icon';
import NumberInput from '../../common/NumberInput';
import Select from '../../common/Select';
import Table, { Cell, Column, Row } from '../../common/Table';
import { hasServiceActualCost } from '../../../services/CostYearsHelper';

export interface CostYearsTableProps {
    currentlyShownServices: CostCalculation[];
    fiscalYearSorting: (a: CostsPerYear, b: CostsPerYear) => 1 | -1;
    getCellColor: (fiscalYearId: number, serviceCategoryId: number, plannedCost?: number, displayOnly?: boolean) => InputColors | undefined;
    getServiceActualCostsForRow: (services: CostsPerYearService[]) => Cell[];
    getServiceColumns: () => Column[];
    handleChange: (value: Partial<CostsPerYear>, index: number) => void;
    handleServiceClick: (index: number) => void;
    handleServiceCostChange: (value: Partial<CostsPerYearService>, textValue: string, serviceCategoryId: number, inputCostsPerYearIndex: number) => void;
    mainTableRef: RefObject<HTMLDivElement>;
    removeCostPerYear: (index: number) => void;
}

const CostYearsTable = ({
    currentlyShownServices,
    fiscalYearSorting,
    getCellColor,
    getServiceActualCostsForRow,
    getServiceColumns,
    handleChange,
    handleServiceClick,
    handleServiceCostChange,
    mainTableRef,
    removeCostPerYear
}: CostYearsTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const closed = useProjectClosed();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    const getServicePlannedCostsForRow = (services: CostsPerYearService[], costsPerYearIndex: number, disabled?: boolean, fiscalYearId?: number): Cell[] => {
        return [
            ...currentlyShownServices.map<Cell>((x, index) => ({
                content:
                    <NumberInput
                        color={getCellColor(fiscalYearId ?? 0, x.serviceCategoryId ?? 0, services.find(y => y.serviceCategoryId == x.serviceCategoryId)?.plannedCost)}
                        label={translate(TRANSLATIONS.main.cost)}
                        key={`${x.serviceCategoryId} servicePlannedCost`}
                        name={`${index} servicePlannedCost`}
                        value={services.find(y => y.serviceCategoryId == x.serviceCategoryId)?.plannedCost?.toString() ?? '0'}
                        onChange={y => handleServiceCostChange({ plannedCost: Number(y) }, y, Number(x.serviceCategoryId), costsPerYearIndex)}
                        disabled={disabled}
                        decimal
                        negative
                        maxValue={MAX_INTEGER}
                        numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                        numberOfDigits={x.serviceCategory.index === 6 ? undefined : 2}
                        tooltipId={`cost_${x.serviceCategoryId?.toString()}`}
                    />,
                inputStyle: true
            }))
        ];
    };

    return (
        <Table
            thickBottomLine={i => i % 2 === 0}
            tableRef={mainTableRef}
            scrollId={SCROLL_ID.COST_YEARS}
            noScrollbar
            noHeaderLineBreak
            onHeaderClick={handleServiceClick}
            columns={[
                { key: 'fiscalYear', label: '', min: '130px', max: '1fr', bold: true, notClickable: true },
                { label: translate(TRANSLATIONS.main.costType), min: '120px', max: '2fr', bold: true, notClickable: true },
                { label: translate(TRANSLATIONS.main.totalCosts), min: '120px', max: '2fr', bold: true, notClickable: true },
                ...getServiceColumns(),
                { key: 'deleteIcon', label: '', min: '80px', max: '80px', notClickable: true }]}
            rows={[
                ...[...project?.costsPerYears ?? []].sort(fiscalYearSorting).map<Row[]>((x, index) => {
                    const fiscalYear = availableValues.fiscalYears.find(y => y.id === x.fiscalYearId);
                    const fiscalYears = availableValues.fiscalYears.filter(y => !y.closed && !project?.costsPerYears?.map(z => z.fiscalYearId).includes(y.id));
                    const isDisabled = closed || fiscalYear?.closed;

                    return [
                        {
                            key: `${x.id ?? x.key ?? index}-Plan`,
                            cells: [
                                {
                                    content: <Select
                                        label={translate(TRANSLATIONS.main.fy)}
                                        key={`${index} fiscalYear`}
                                        value={fiscalYear}
                                        values={fiscalYears}
                                        mapToString={y => y?.name ?? ''}
                                        onSelect={y => handleChange({ fiscalYearId: y?.id }, index)}
                                        disabled={isDisabled}
                                        required
                                        error={!x.fiscalYearId}
                                    />,
                                    inputStyle: true
                                },
                                { content: translate(TRANSLATIONS.main.fyPlan) },
                                {
                                    content:
                                        <NumberInput
                                            color={getCellColor(x.fiscalYearId ?? 0, 0, 0, true)}
                                            label={convertNumberToLocal(sum(x.services, 'plannedCost'))}
                                            key='totalCost'
                                            negative
                                            name=''
                                            value=''
                                            readOnly
                                        />,
                                    inputStyle: true
                                },
                                ...getServicePlannedCostsForRow(x.services, index, isDisabled, x.fiscalYearId),
                                {
                                    content: <Button
                                        key={`${index} remove`}
                                        type={ButtonType.Tertiary}
                                        onClick={() => removeCostPerYear(index)}
                                        disabled={isDisabled || hasServiceActualCost(x.services)}>
                                        <Icon type={IconType.Trash} />
                                    </Button>,
                                    inputStyle: true
                                }
                            ]
                        },
                        {
                            key: `${x.id ?? x.key ?? index}-Actual`,
                            cells: [
                                { content: '' },
                                { content: translate(TRANSLATIONS.main.fyCharged) },
                                { content: convertNumberToLocal(sum(x.services, 'actualCost')) },
                                ...getServiceActualCostsForRow(x.services)
                            ]
                        }
                    ];
                }),
                { key: 'divider', divider: true, cells: [] }
            ].flat()}
        />
    );
};

export default CostYearsTable;
