import React, { ReactNode, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import Icon, { IconType } from './Icon';

export interface ListLocation {
    id: string;
    index: number;
}

export interface ListItem {
    content: ReactNode;
    id: string;
    isDragDisabled?: boolean;
}

export interface List {
    id: string;
    items: ListItem[];
    name: string;
}

interface MutableListProps {
    lists: List[],
    onChange: (itemId: string, source: ListLocation, destination: ListLocation) => void;
}

const MutableList = ({ lists, onChange }: MutableListProps) => {
    const handleDragEnd = useCallback((result: DropResult) => {
        const { destination, draggableId, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        onChange(draggableId, { id: source.droppableId, index: source.index }, { id: destination.droppableId, index: destination.index });
    }, [onChange]);

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            {lists.map(x =>
                <div key={x.id} className='mutable-list'>
                    <div className='label'>
                        {x.name}
                    </div>
                    <Droppable droppableId={x.id}>
                        {provided =>
                            <div
                                ref={provided.innerRef}
                                className='items'
                                {...provided.droppableProps}
                            >
                                {x.items.map((y, j) =>
                                    <Draggable key={y.id} draggableId={y.id} index={j} isDragDisabled={y.isDragDisabled}>
                                        {(provided, snapshot) =>
                                            <div
                                                ref={provided.innerRef}
                                                className={`item ${snapshot.isDragging ? 'dragging' : ''} ${y.isDragDisabled ? 'disabled' : ''}`}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Icon type={IconType.Apps} />
                                                <div className='name'>
                                                    {y.content}
                                                </div>
                                            </div>
                                        }
                                    </Draggable>
                                        
                                )}
                                {provided.placeholder}
                            </div>
                        }
                    </Droppable>
                </div>
            )}
        </DragDropContext>
    );
};

export default MutableList;
