import { Company } from '../models';

export const EMPTY_CUSTOMER: Company = {
    city: '',
    comment: '',
    group: '',
    ifaNo: '',
    mailbox: '',
    mailboxPostcode: '',
    name: '',
    postcode: '',
    street: ''
};
