import React from 'react';
import { EMPTY_CONTACT, TRANSLATIONS } from '../../../constants';
import { ContactType } from '../../../enums';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';
import { GeneralInputsProps } from './BaseInputs';

const ResponsibleInputs = ({ closed, handleChange }: GeneralInputsProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const departmentTranslationKey = TRANSLATIONS.main.department;

    return (
        <div className='form-group'>
            <div className='length-4'>
                <Select
                    label={translate(TRANSLATIONS.main.responsibleHeadquarterTechnical)}
                    value={project?.hqTechnicalResponsible}
                    values={availableValues.contacts.filter(x => x.contactType === ContactType.HeadquarterTechnical)}
                    required
                    disabled={closed}
                    mapToString={x => x?.name ?? ''}
                    filled={x => Boolean(x?.name) && x?.name !== '-'}
                    error={!project?.hqTechnicalResponsible?.name || project?.hqTechnicalResponsible?.name === '-'}
                    onSelect={x => handleChange({ hqTechnicalResponsible: x })}
                    onReset={() => handleChange({ hqTechnicalResponsible: EMPTY_CONTACT })}
                />
                <TextInput
                    label={translate(departmentTranslationKey).toString()}
                    name='department'
                    value={project?.hqTechnicalResponsible === null ? '' : project?.hqTechnicalResponsible?.department ?? ''}
                    disabled
                    onChange={(x, y) => handleChange({ [y]: x })}
                />
            </div>
            <div className='length-4'>
                <Select
                    label={translate(TRANSLATIONS.main.responsibleHeadquarterCommercial)}
                    value={project?.hqCommercialResponsible}
                    values={availableValues.contacts.filter(x => x.contactType === ContactType.HeadquarterCommercial)}
                    disabled={closed}
                    mapToString={x => x?.name ?? ''}
                    filled={x => Boolean(x?.name) && x?.name !== '-'}
                    onSelect={x => handleChange({ hqCommercialResponsible: x })}
                    onReset={() => handleChange({ hqCommercialResponsible: EMPTY_CONTACT })}
                />
                <TextInput
                    label={translate(departmentTranslationKey).toString()}
                    name='department'
                    value={project?.hqCommercialResponsible === null ? '' : project?.hqCommercialResponsible.department ?? ''}
                    disabled
                    onChange={(x, y) => handleChange({ [y]: x })}
                />
            </div>
            <div className='length-4'>
                <Select
                    label={translate(TRANSLATIONS.main.localProjectManagement)}
                    value={project?.localTechnicalResponsible}
                    values={availableValues.contacts.filter(x => x.contactType === ContactType.LocalProjectManagement)}
                    disabled={closed}
                    mapToString={x => x?.name ?? ''}
                    filled={x => Boolean(x?.name) && x?.name !== '-'}
                    onSelect={x => handleChange({ localTechnicalResponsible: x })}
                    onReset={() => handleChange({ localTechnicalResponsible: EMPTY_CONTACT })}
                />
                <TextInput
                    label={translate(departmentTranslationKey).toString()}
                    name='department'
                    value={project?.localTechnicalResponsible === null ? '' : project?.localTechnicalResponsible.department ?? ''}
                    disabled
                    onChange={(x, y) => handleChange({ [y]: x })}
                />
            </div>
        </div>
    );
};

export default ResponsibleInputs;
