import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../../constants/AuthConfig';

export interface MsalWrapperProps {
    children?: ReactNode
}

const MsalWrapper = ({ children }: MsalWrapperProps) => {
    const msalInstance = useMemo(() => new PublicClientApplication(msalConfig), []);
    const [msalInitialized, setMsalInitialized] = useState(false);

    const handleMsalInitialization = useCallback(async () => {
        await msalInstance.initialize();
        setMsalInitialized(true);
    }, [msalInstance]);

    useEffect(() => {
        if (!msalInitialized) {
            handleMsalInitialization();
        }
    }, [handleMsalInitialization, msalInitialized]);

    return msalInitialized ? <MsalProvider instance={msalInstance} >{children}</MsalProvider > : <></>;
};

export default MsalWrapper;
