import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiCall, Api } from '../../../services';
import { AvailableValues } from '../../../models';
import { EMPTY_AVAILABLE_VALUES, TRANSLATIONS } from '../../../constants';
import { useMounted, useTranslate } from '../../common';
import { addSnackbar } from '../../../store';
import { SnackbarType } from '../../../components/common/Snackbar';

export const useAvailableValues = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const mounted = useMounted();
    const [availableValues, setAvailableValues] = useState<AvailableValues>(EMPTY_AVAILABLE_VALUES);

    const handleAvailableValuesLoad = async () => {
        return apiCall(
            Api.getAvailableValues(),
            async x => {
                mounted.current && setAvailableValues(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadAvailableValues), type: SnackbarType.Error }));
            }
        );
    };

    return {
        availableValues: availableValues,
        onAvailableValuesLoad: handleAvailableValuesLoad
    };
};
