import React from 'react';
import { CountryCostsPerYearWithOriginalIndex, DocumentGenerationData, FiscalYear, OrderRequest } from '../../../models';
import { DOCUMENT_GENERATION_KUEB_COUNTRY_ISO_KEY, DOCUMENT_GENERATION_PRICE_LIMIT, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useHook } from '../../../hooks';
import { ButtonType } from '../../common/Button';
import { IconType } from '../../common/Icon';
import { sum } from '../../../services';
import ButtonWithPopup from '../../common/ButtonWithPopup';

export interface CostYearsCountriesDropdownButtonProps {
    countryCostYearWithIndex: CountryCostsPerYearWithOriginalIndex;
    fiscalYearClosed: boolean;
    fiscalYearSelected: FiscalYear;
    index: number;
    setKuebData: (kuebData: DocumentGenerationData) => void;
    setOrderRequest: (orderRequest: OrderRequest) => void;
    setShowKuebModal: (value: boolean) => void;
    setShowOrderRequestModal: (value: boolean) => void;
}

const CostYearsCountriesDropdownButton = ({ countryCostYearWithIndex, fiscalYearClosed, fiscalYearSelected, index, setKuebData, setOrderRequest, setShowKuebModal, setShowOrderRequestModal }: CostYearsCountriesDropdownButtonProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { onCreatePoaClick, onCreateSaLightClick, onGenerateKuebClick, onOrderOverviewClick, onOrderRequestClick, onUseValuesFromCostYearsClick, removeCountryCostPerYear } = useHook(x => x.costYearsCountriesDropdown)();
    const { onGenerateKueb, onGenerateOrderOverview, onGenerateSaLight, onGeneratePoa } = useHook(x => x.documentGeneration)();

    const canGenerateOrderRequest = countryCostYearWithIndex.countryCostsPerYear.fiscalYearId === fiscalYearSelected.id;
    const costSum = sum(countryCostYearWithIndex.countryCostsPerYear.countryCostsPerYearServices, 'plannedCost');
    const countryId = countryCostYearWithIndex.countryCostsPerYear.countryId;
    const fiscalYearId = countryCostYearWithIndex.countryCostsPerYear.fiscalYearId;
    const hasCostYearsValues = project?.costsPerYears?.some(x => x.fiscalYearId == fiscalYearSelected.id) ?? false;
    const hasServiceActualCost = countryCostYearWithIndex.countryCostsPerYear.countryCostsPerYearServices.some(x => x.actualCost != null && x.actualCost != 0);
    const originalIndex = countryCostYearWithIndex.originalIndex;

    const isKuebCountry = availableValues.unfilteredCountries.find(x => x.id == countryId)?.isoKey == DOCUMENT_GENERATION_KUEB_COUNTRY_ISO_KEY;
    const isExceedingLimit = costSum > DOCUMENT_GENERATION_PRICE_LIMIT;

    const tableRowDropdownActions = [
        ...canGenerateOrderRequest && !fiscalYearClosed
            ? [{
                name: translate(TRANSLATIONS.main.createOrderOverview),
                iconType: IconType.Document,
                onClick: () => onOrderOverviewClick(countryId, fiscalYearId, originalIndex, onGenerateOrderOverview)
            }]
            : [],
        ...canGenerateOrderRequest && !fiscalYearClosed
            ? [{
                name: translate(TRANSLATIONS.main.createOrderRequest),
                iconType: IconType.Document,
                onClick: () => onOrderRequestClick(countryId, fiscalYearId, originalIndex, setShowOrderRequestModal, setOrderRequest)
            }]
            : [],
        ...!fiscalYearClosed && !isKuebCountry && isExceedingLimit
            ? [{
                name: translate(TRANSLATIONS.main.createSaLight),
                iconType: IconType.Document,
                onClick: () => onCreateSaLightClick(countryId, fiscalYearId, originalIndex, onGenerateSaLight)
            }]
            : [],
        ...!fiscalYearClosed && isKuebCountry
            ? [{
                name: translate(TRANSLATIONS.main.createKueb),
                iconType: IconType.Document,
                onClick: () => onGenerateKuebClick(countryId, fiscalYearId, originalIndex, setShowKuebModal, setKuebData, onGenerateKueb)
            }]
            : [],
        ...!fiscalYearClosed && !isKuebCountry && !isExceedingLimit
            ? [{
                name: translate(TRANSLATIONS.main.createPoa),
                iconType: IconType.Document,
                onClick: () => onCreatePoaClick(countryId, fiscalYearId, originalIndex, onGeneratePoa)
            }]
            : [],
        ...!fiscalYearClosed && hasCostYearsValues
            ? [{
                name: translate(TRANSLATIONS.main.useValuesFromCostYears),
                iconType: IconType.Copy,
                onClick: () => onUseValuesFromCostYearsClick(originalIndex, fiscalYearId)
            }]
            : [],
        ...!hasServiceActualCost && !fiscalYearClosed
            ? [{
                name: 'Delete',
                iconType: IconType.Trash,
                onClick: () => removeCountryCostPerYear(originalIndex)
            }]
            : []
    ];

    return <ButtonWithPopup
        key={`${countryCostYearWithIndex.countryCostsPerYear.id ?? countryCostYearWithIndex.countryCostsPerYear.key ?? index} rowAction`}
        buttonIconType={IconType.OptionsHorizontal}
        buttonType={ButtonType.Tertiary}
        disabled={closed || fiscalYearClosed}
        dropdownActions={tableRowDropdownActions}
    />;
};

export default CostYearsCountriesDropdownButton;
