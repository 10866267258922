import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthenticationInProgress } from './authentication';

const Login = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated && inProgress === 'none') {
            instance.loginRedirect();
        }
    }, [inProgress, instance, isAuthenticated]);

    return isAuthenticated ? <Navigate to='/' replace /> : <AuthenticationInProgress />;
};

export default Login;
