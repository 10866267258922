import React, { useCallback, useState } from 'react';
import { NumberFormatValues, NumericFormatProps, numericFormatter } from 'react-number-format';
import { InputColors } from '../../enums';
import { convertToDecimalString } from '../../services';
import TextInput, { InputProps } from './TextInput';
import Tooltip from './Tooltip';

interface NumberInputProps extends InputProps {
    decimal?: boolean
    maxValue?: number
    negative?: boolean
    numberFormat?: NumericFormatProps
    numberOfDigits?: number
    percentageFromMultiplier?: boolean
    tooltipId?: string
    color?: InputColors
}

const NumberInput = ({ color, maxValue, negative, numberFormat, numberOfDigits, percentageFromMultiplier, tooltipId, value, onChange, ...rest }: NumberInputProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);
    const nonZeroValue = value === '0' ? '' : value;
    const handledValue = percentageFromMultiplier ? convertToDecimalString(nonZeroValue, 'percentage') : nonZeroValue;

    const inLimit = (values: NumberFormatValues) => {
        const { value } = values;
        const inLimit = !value || Number(value) <= (maxValue ?? Number(Number.MAX_VALUE.toFixed(numberOfDigits)));

        if (inLimit) {
            setShowTooltip(false);
        } else {
            setShowTooltip(true);
        }

        return inLimit;
    };

    const handleNumberChange = (value: string, name: string) => {
        const result = percentageFromMultiplier ? convertToDecimalString(value, 'multiplier') : value;

        onChange && onChange(result, name);
    };

    return (
        <Tooltip id={tooltipId ?? 'maxValueTooltip'} text={`Maximum value of input is ${numericFormatter((maxValue ?? Number.MAX_VALUE).toString(), { ...numberFormat, decimalScale: numberOfDigits })}`} hidden={!showTooltip} delayShow={100} >
            <TextInput 
                value={handledValue}
                numberFormatOptions={{ ...numberFormat, decimalScale: numberOfDigits, allowNegative: negative, isAllowed: inLimit }}
                onChange={handleNumberChange}
                className={color ?? undefined}
                {...rest}
            />
        </Tooltip>
    );
};

export default NumberInput;
