import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { useProjectClosed } from '../../../hooks/application';
import { useForceUpdate, useTranslate } from '../../../hooks/common';
import { useHook } from '../../../hooks';
import { setActiveTabType } from '../../../store';
import CostYearsCountriesStickyTable from './CostYearsCountriesStickyTable';
import CostYearsCountriesTable from './CostYearsCountriesTable';
import KuebModal from './KuebModal';
import OrderRequestModal from './OrderRequestModal';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Select from '../../common/Select';
import SpacingContainer from '../../common/SpacingContainer';
import Sticky from '../../common/Sticky';

const CostYearsCountriesTab = () => {    
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const closed = useProjectClosed();
    const translate = useTranslate();
    const { addCountryCostPerYear, changeFiscalYear, currentlyShownServices, serviceArray, fiscalYears, fiscalYearSelected,
        currentServiceStartIndex, countryCostsInFiscalYear, serviceDisplayLimit, handleChange, handleServiceClick,
        handleServiceCostChange, fiscalYearClosed, getSelectableCountries, getServiceColumns } = useHook(x => x.costYearsCountries)();
    const { handleOrderRequestModalCancel, handleOrderRequestModalCreateDocument, handleKuebModalCancel,
        handleKuebModalCreateDocument, setShowKuebModal, setShowOrderRequestModal, showOrderRequestModal,
        showKuebModal, orderRequest, setOrderRequest, setKuebData } = useHook(x => x.costYearsCountriesModals)();
    const mainTabledRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.CostYearsCountries));
        forceUpdate(); // A rerender is necessary after mounting to set the real value of the table reference
    }, []);
  
    return (
        <div style={{ marginBottom: '50px', backgroundColor: 'inherit' }}>
            <SpacingContainer>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 20 }}>
                    <Select
                        label={translate(TRANSLATIONS.main.fy)}
                        key='fiscalYear'
                        value={fiscalYearSelected}
                        values={fiscalYears}
                        mapToString={x => x?.name ?? ''}
                        onSelect={x => changeFiscalYear(x)}
                    />
                    <Button type={ButtonType.Primary} onClick={addCountryCostPerYear} disabled={closed || Boolean(!(fiscalYears ?? [])) || fiscalYearClosed}>Create new line</Button>
                </Flex>
                <ScrollSync>
                    <>
                        <CostYearsCountriesTable
                            countryCostsInFiscalYear={countryCostsInFiscalYear}
                            currentServiceStartIndex={currentServiceStartIndex}
                            fiscalYearClosed={fiscalYearClosed}
                            fiscalYearSelected={fiscalYearSelected}
                            getSelectableCountries={getSelectableCountries}
                            getServiceColumns={getServiceColumns}
                            handleChange={handleChange}
                            handleServiceClick={handleServiceClick}
                            handleServiceCostChange={handleServiceCostChange}
                            mainTableRef={mainTabledRef}
                            serviceArray={serviceArray}
                            serviceDisplayLimit={serviceDisplayLimit}
                            serviceListCurrent={currentlyShownServices}
                            setKuebData={setKuebData}
                            setOrderRequest={setOrderRequest}
                            setShowKuebModal={setShowKuebModal}
                            setShowOrderRequestModal={setShowOrderRequestModal}
                        />    
                        <Sticky anchor='bottom'>
                            <CostYearsCountriesStickyTable
                                currentServiceStartIndex={currentServiceStartIndex}
                                fiscalYearSelected={fiscalYearSelected}
                                getServiceColumns={getServiceColumns}
                                handleServiceClick={handleServiceClick}
                                mainTableRef={mainTabledRef}
                                serviceArray={serviceArray}
                                serviceDisplayLimit={serviceDisplayLimit}
                                serviceListCurrent={currentlyShownServices}
                            />  
                        </Sticky>
                    </>
                </ScrollSync>
            </SpacingContainer>
            <OrderRequestModal
                showOrderRequestModal={showOrderRequestModal}
                orderRequest={orderRequest}
                handleOrderRequestModalCancel={handleOrderRequestModalCancel}
                handleOrderRequestModalCreateDocument={handleOrderRequestModalCreateDocument} />
            <KuebModal
                showKuebModal={showKuebModal}
                handleKuebModalCancel={handleKuebModalCancel}
                handleKuebModalCreateDocument={handleKuebModalCreateDocument} />
        </div>
    );
};

export default CostYearsCountriesTab;
