import React, { ChangeEvent, DragEvent, useCallback, useRef, useState } from 'react';
import Button, { ButtonType } from './Button';
import Icon, { IconType } from './Icon';
import { convertMegabytesToBytes } from '../../services';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants';

interface FileUploadProps {
    onFileChange: (file: File) => void;
    dark?: boolean;
    fileName?: string;
    highlighted?: boolean;
    maxSizeInMegabytes?: number;
    validTypes: string[]
}

const FileUpload = ({ dark, fileName, highlighted, onFileChange, maxSizeInMegabytes, validTypes }: FileUploadProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const translate = useTranslate();
    const translations = TRANSLATIONS;
    const [errorMessage, setErrorMessage] = useState('');

    const isFileValid = (file: File) => {
        const isValidType = validTypes.some(x => x === file.type);
        return isValidType;
    };

    const handleClick = useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        handleFiles(event.dataTransfer.files);
    };

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleFiles(event.currentTarget.files);
        
        event.target.value = ''; // The file stored in the input should be reset to enable adding the same file multiple times.
    };

    const preventDefault = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFiles = (files: FileList | null) => {
        if (files && files.length) {
            const file = files[0];
            const isValidSize = maxSizeInMegabytes ? file.size < convertMegabytesToBytes(maxSizeInMegabytes) : true;

            if (isValidSize) {
                if (isFileValid(file)) {
                    setErrorMessage('');
                    onFileChange(file);
                } else {
                    setErrorMessage(translate(translations.main.fileTypeNotPermitted));
                }
            } else {
                setErrorMessage(translate(translations.main.fileTooBig));
            }
        }
    };

    const renderErrorMessage = () => {
        return (
            <>
                {errorMessage &&
                    <div className='error-message'>
                        {errorMessage}
                    </div>
                }
            </>
        );
    };

    return (
        <div className={`file-upload ${highlighted ? 'highlighted' : ''} ${dark ? 'dark' : ''}`} onDragOver={preventDefault} onDragEnter={preventDefault}
            onDragLeave={preventDefault} onDrop={handleDrop}>
            <div className='file-upload-label'>
                <Icon type={fileName ? IconType.Document : IconType.Upload} />
                {fileName ? fileName : 'Drop file here'}
            </div>
            <Button type={ButtonType.Secondary} onClick={handleClick} dark={dark}>
                {fileName ? 'Select other file' : 'Or select here'}
            </Button>
            <input ref={fileInputRef} type='file' onChange={handleFileInputChange} />
            {renderErrorMessage()}
        </div>
    );
};

export default FileUpload;
