import React, { FocusEvent, KeyboardEvent, useRef, useState } from 'react';
import { ReactText } from 'react';

interface DateInputProps {
    value?: string;
    label: string;
    onChange: (value: string) => void;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    required?: boolean;
    getMessage?: (value?: string) => string;
}

const DateInput = ({ dark, disabled, error, label, required, value, getMessage, onChange }: DateInputProps) => {
    const [dateStep, setDateStep] = useState(-1);
    const [selectedDigit, setSelectedDigit] = useState(0);
    const [zeroTyped, setZeroTyped] = useState(false);
    const inputFieldRef = useRef<HTMLDivElement>(null);
    const message = getMessage ? getMessage(value) : '';

    const handleBlur = () => {
        setSelectedDigit(0);
        setZeroTyped(false);
        setDateStep(-1);
        value && onChange(adjustDay(value));
    };

    const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
        // Get focus from outside
        if (!event.relatedTarget || !event.target.contains(event.relatedTarget as HTMLElement)) {
            setDateStep(0);
        }
    };

    const handleValueFocus = (event: FocusEvent<HTMLDivElement>, step: number) => {
        event.stopPropagation();

        inputFieldRef.current?.focus();
        setDateStep(step);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (value) {
            switch (event.key) {
                case 'ArrowRight':
                    if (dateStep < 2) {
                        setDateStep(x => x + 1);
                        setSelectedDigit(0);
                        setZeroTyped(false);
                        onChange(adjustDay(value));
                    }
                    break;
                case 'ArrowLeft':
                    if (dateStep > 0) {
                        setDateStep(x => x - 1);
                        setSelectedDigit(0);
                        setZeroTyped(false);
                        onChange(adjustDay(value));
                    }
                    break;
                case 'Enter':
                case 'Escape':
                    inputFieldRef.current?.blur();
                    break;
                case 'Backspace':
                    setSelectedDigit(0);
                    setZeroTyped(false);

                    switch (dateStep) {
                        case 0:
                            onChange(setYear(value, 'YYYY'));
                            break;
                        case 1:
                            onChange(setMonth(value, 'MM'));
                            break;
                        case 2:
                            onChange(setDay(value, 'DD'));
                            break;
                    }
                    break;
                default:
                    if (!isNaN(Number(event.key))) {
                        const digit = Number(event.key);

                        switch (dateStep) {
                            case 0:
                                if (selectedDigit === 0) {
                                    onChange(setYear(value, `000${digit}`));
                                } else {
                                    let newDateString = setYear(value, `${value.slice(1, 4)}${digit}`);

                                    if (selectedDigit === 3) {
                                        newDateString = adjustDay(newDateString);
                                    }

                                    onChange(newDateString);
                                }

                                setSelectedDigit(x => x + 1);

                                if (selectedDigit === 3) {
                                    setDateStep(x => x + 1);
                                    setSelectedDigit(0);
                                }
                                break;
                            case 1:
                                if (selectedDigit) {
                                    onChange(adjustDay(setMonth(value, zeroTyped ? addFirstDigit(digit) : addSecondDigit(value[6], digit, 12))));

                                    setDateStep(x => x + 1);
                                    setSelectedDigit(0);
                                    setZeroTyped(false);
                                } else {
                                    const newDateString = setMonth(value, addFirstDigit(digit));

                                    if (digit > 1) {
                                        onChange(adjustDay(newDateString));
                                        setDateStep(x => x + 1);
                                        setSelectedDigit(0);
                                    } else {
                                        onChange(newDateString);
                                        setSelectedDigit(1);
                                        setZeroTyped(digit === 0);
                                    }
                                }
                                break;
                            case 2:
                                if (selectedDigit) {
                                    onChange(adjustDay(setDay(value, zeroTyped ? addFirstDigit(digit) : addSecondDigit(value[9], digit, 31))));

                                    setSelectedDigit(0);
                                    setZeroTyped(false);
                                } else {
                                    onChange(setDay(value, addFirstDigit(digit)));

                                    if (digit <= 3) {
                                        setSelectedDigit(1);
                                        setZeroTyped(digit === 0);
                                    }
                                }
                                break;
                        }
                    }
                    break;
            }
        }
    };

    const addFirstDigit = (digit: number) => `0${Math.max(digit, 1)}`;

    const addSecondDigit = (firstDigit: string, secondDigit: number, max: number) => Math.min(Number(firstDigit) * 10 + secondDigit, max);

    const adjustDay = (dateString: string) => {
        const year = Number(getYear(dateString));
        const month = Number(getMonth(dateString));
        const day = Number(getDay(dateString));
        const maxDay = new Date(!isNaN(year) ? year : 2020, month, 0).getDate();

        return !isNaN(day) && !isNaN(month) && day > maxDay ? setDay(dateString, maxDay) : dateString;
    };

    const getYear = (value: string) => value.slice(0, 4);

    const getMonth = (value: string) => value.slice(5, 7);

    const getDay = (value: string) => value.slice(8, 10);

    const setYear = (value: string, year: ReactText) => `${year}${value.slice(4, value.length)}`;

    const setMonth = (value: string, month: ReactText) => `${value.slice(0, 5)}${month}${value.slice(7, value.length)}`;

    const setDay = (value: string, day: ReactText) => `${value.slice(0, 8)}${day}${value.slice(10, value.length)}`;

    const renderDateInputValue = (step: number, value: string) => (
        <div className={`date-input-value ${step === dateStep ? 'active' : ''}`} tabIndex={disabled ? undefined : -1} onFocus={e => handleValueFocus(e, step)}>
            {value}
        </div>
    );

    return (
        <div className={`date-input ${disabled ? 'disabled' : ''} ${error ? 'error' : ''} ${dark ? 'dark' : ''}`}>
            <div ref={inputFieldRef} className='date-input-field' tabIndex={disabled || !value ? undefined : 0} onBlur={handleBlur} onFocus={handleFocus} onKeyDown={handleKeyDown}>
                <div className={`date-input-label ${value ? 'filled' : ''} ${required ? 'required' : ''}`}>
                    {label}
                </div>
                {value
                    ? <div className='date-input-value-container'>
                        {renderDateInputValue(0, getYear(value))}
                        /
                        {renderDateInputValue(1, getMonth(value))}
                        /
                        {renderDateInputValue(2, getDay(value))}
                    </div>
                    : <br />
                }

            </div>
            {getMessage &&
                <div className={`date-input-message ${error ? 'error' : ''}`}>
                    {message ? message : <br />}
                </div>
            }
        </div>
    );
};

export default DateInput;
