import react from 'react';
import { Project, AvailableValues, FilterValues, PageAndOrdering, OrderRequestData, SelectedDropdownValues, ProjectAttachment } from '../models';
import { ProjectStatus } from '../enums';
import { EMPTY_AVAILABLE_VALUES, EMPTY_HISTORY, EMPTY_ORDER_REQUEST_DATA, INITIAL_FILTER_VALUES } from '../constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProjectState {
    availableValues: AvailableValues;
    filterValues: FilterValues;
    initialHash?: string;
    initialStatus?: ProjectStatus;
    orderRequestData: OrderRequestData;
    pegaLoading: boolean;
    project?: Project;
    projectChanged: boolean;
    projectHeaderDisabled: boolean;
    projectLoading: boolean;
    projectLockedPageAndOrdering?: PageAndOrdering;
    projectRemindedPageAndOrdering?: PageAndOrdering;
    projectSeacrhIds?: string[];
    projectSearchPageAndOrdering?: PageAndOrdering;
    projectAttachments: ProjectAttachment[];
    selectedDropdownValues: SelectedDropdownValues | Partial<SelectedDropdownValues>;
}

const initialState: ProjectState = {
    availableValues: EMPTY_AVAILABLE_VALUES,
    filterValues: INITIAL_FILTER_VALUES,
    orderRequestData: EMPTY_ORDER_REQUEST_DATA,
    pegaLoading: false,
    projectChanged: false,
    projectHeaderDisabled: false,
    projectLoading: false,
    projectAttachments: [],
    selectedDropdownValues: {
        historyDateOfAuthorizedFolder: EMPTY_HISTORY
    }
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setAvailableValues: (state: ProjectState, action: PayloadAction<AvailableValues>) => {
            state.availableValues = action.payload;
        },
        setFilterValues: (state: ProjectState, action: PayloadAction<Partial<FilterValues>>) => {
            state.filterValues = {
                ...state.filterValues, ...action.payload
            };
        },
        setInitialHash: (state: ProjectState, action: PayloadAction<string | undefined>) => {
            state.initialHash = action.payload;
        },
        setInitialStatus: (state: ProjectState, action: PayloadAction<ProjectStatus>) => {
            state.initialStatus = action.payload;
        },
        setOrderRequestData: (state: ProjectState, action: PayloadAction<OrderRequestData>) => {
            state.orderRequestData = action.payload;
        },
        setPegaLoading: (state: ProjectState, action: PayloadAction<boolean>) => {
            state.pegaLoading = action.payload;
        },
        setProject: (state: ProjectState, action: PayloadAction<Project | undefined>) => {
            state.project = action.payload;
        },
        setProjectChanged: (state: ProjectState, action: PayloadAction<boolean>) => {
            state.projectChanged = action.payload;
        },
        setProjectHeaderDisabled: (state: ProjectState, action: PayloadAction<boolean>) => {
            state.projectHeaderDisabled = action.payload;
        },
        setProjectLoading: (state: ProjectState, action: PayloadAction<boolean>) => {
            state.projectLoading = action.payload;
        },
        setProjectLockedPageAndOrdering: (state: ProjectState, action: PayloadAction<PageAndOrdering>) => {
            state.projectLockedPageAndOrdering = action.payload;
        },
        setProjectRemindedPageAndOrdering: (state: ProjectState, action: PayloadAction<PageAndOrdering>) => {
            state.projectRemindedPageAndOrdering = action.payload;
        },
        setProjectSearchIds: (state: ProjectState, action: PayloadAction<string[] | undefined>) => {
            state.projectSeacrhIds = action.payload;
        },
        setProjectSearchPageAndOrdering: (state: ProjectState, action: PayloadAction<PageAndOrdering>) => {
            state.projectSearchPageAndOrdering = action.payload;
        },
        setSelectedDropdownValues: (state: ProjectState, action: PayloadAction<SelectedDropdownValues | Partial<SelectedDropdownValues>>) => {
            state.selectedDropdownValues = { ...state.selectedDropdownValues, ...action.payload };
        },
        setProjectAttachments: (state: ProjectState, action: PayloadAction<ProjectAttachment[]>) => {
            state.projectAttachments = action.payload;
        }
    }
});

export const {
    setAvailableValues,
    setFilterValues,
    setInitialHash,
    setInitialStatus,
    setOrderRequestData,
    setPegaLoading,
    setProject,
    setProjectChanged,
    setProjectHeaderDisabled,
    setProjectLoading,
    setProjectLockedPageAndOrdering,
    setProjectRemindedPageAndOrdering,
    setProjectSearchIds,
    setProjectSearchPageAndOrdering,
    setSelectedDropdownValues,
    setProjectAttachments
} = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
