import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { convertNumberToLocal, sum } from '../../../services';
import { DEFAULT_SELECTED_FISCAL_YEAR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { FiscalYear, Order, OrderService } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { setActiveTabType, setSelectedDropdownValues } from '../../../store';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Select from '../../common/Select';
import Sticky from '../../common/Sticky';
import Table, { Column } from '../../common/Table';
import Tooltip from '../../common/Tooltip';

const OrdersTab = () => {
    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const selectedDropdownValues = useAppSelector(state => state.project.selectedDropdownValues);
    const translate = useTranslate();
    const fiscalYears = [
        DEFAULT_SELECTED_FISCAL_YEAR,
        ...availableValues.fiscalYears.filter(x => project?.orders?.map(y => y.fiscalYearId).includes(x.id))
    ];
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(selectedDropdownValues?.ordersFiscalYear ?? DEFAULT_SELECTED_FISCAL_YEAR);
    const [filteredOrders, setFilteredOrders] = useState((project?.orders ?? []).filter(x => selectedFiscalYear?.id === 0 || x.fiscalYearId === selectedFiscalYear?.id));

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Orders));
        let fiscalYearToShow = selectedDropdownValues.ordersFiscalYear;

        if (!fiscalYearToShow) {
            dispatch(setSelectedDropdownValues({ ordersFiscalYear: DEFAULT_SELECTED_FISCAL_YEAR }));
            fiscalYearToShow = DEFAULT_SELECTED_FISCAL_YEAR;
        }
        setSelectedFiscalYear(fiscalYearToShow ?? DEFAULT_SELECTED_FISCAL_YEAR);
        setFilteredOrders((project?.orders ?? []).filter(x => fiscalYearToShow?.id === 0 || x.fiscalYearId === fiscalYearToShow?.id));
    }, [project]);
    
    const renderTooltipWrapper = (text: string) => <Tooltip key={text} id={`${text}`} delayShow={750} text={text}>{text}</Tooltip>;
    
    const getColumns = (order: Order): Column[] => [
        { label: `${availableValues.unfilteredCountries?.find(x => x.id === order.supplierCountryId)?.name}, ${order.orderNumber}, ${order.orderDate.substring(0, 10)}`, min: '300px', max: '2fr', bold: true },
        { label: translate(TRANSLATIONS.main.orderValue), min: '125px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.charged), min: '125px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.deviation), min: '125px', max: '1fr', bold: true }
    ];
    
    const getOrderServiceName = (order: Order, orderService: OrderService) => 
        `${availableValues.serviceCategories.find(x => x.id === orderService.serviceCategoryId)?.index.toString().padStart(2, '0')}: ${order.sapServiceCategoryName}`;
        
    const getBottomTableColumns = (): Column[] => [
        { label: '', key: 'unnamedColumn', min: '300px', max: '2fr' },
        ...['', '', ''].map((x, index) => ({ label: x, key: `unnamedColumn${index}`, min: '125px', max: '1fr' }))
    ];

    const onFiscalYearSelect = (fiscalYear: FiscalYear) => {
        dispatch(setSelectedDropdownValues({ ordersFiscalYear: fiscalYear }));
        setSelectedFiscalYear(fiscalYear ?? DEFAULT_SELECTED_FISCAL_YEAR);
        setFilteredOrders((project?.orders ?? []).filter(x => fiscalYear?.id === 0 || x.fiscalYearId === fiscalYear?.id));
    }; 

    const getOrderTables = () => (
        <>
            {filteredOrders.map(x => {
                return (
                    <Table
                        key={`${x.orderNumber}-${selectedFiscalYear?.id}`}
                        scrollId={SCROLL_ID.ORDERS}
                        noScrollbar
                        columns={getColumns(x)}
                        rows={[...x.orderServices.map(y => ({
                                key: y.id,
                                cells: [
                                    {
                                        content: renderTooltipWrapper(getOrderServiceName(x, y))
                                    },
                                    {
                                        content: convertNumberToLocal(y.plannedCost ?? 0)
                                    },
                                    {
                                        content: convertNumberToLocal(y.actualCost ?? 0)
                                    },
                                    {
                                        content: convertNumberToLocal((y.plannedCost ?? 0) - (y.actualCost ?? 0))
                                    }
                                ]
                            }))
                        ]}
                    />
                );
            })}
        </>
    );

    const getBottomTable = () => {
        const totalOrderValue = sum(filteredOrders.flatMap(x => x.orderServices), 'plannedCost');
        const totalOrderAccured = sum(filteredOrders.flatMap(x => x.orderServices), 'actualCost');
            
        return (
            <div style={{ marginTop: 40 }}>
                <Table
                    scrollId={SCROLL_ID.ORDERS}
                    noHeader
                    columns={getBottomTableColumns()}
                    rows={[
                        {
                            key: 'totalOrderValue',
                            cells: [
                                {
                                    content: translate(TRANSLATIONS.main.total)
                                },
                                {
                                    content: convertNumberToLocal(totalOrderValue) ?? ''
                                },
                                {
                                    content: convertNumberToLocal(totalOrderAccured) ?? ''
                                },
                                {
                                    content: convertNumberToLocal(totalOrderValue - totalOrderAccured) ?? ''
                                }
                            ]
                        }
                    ]}
                />
            </div>
        );
    };
    
    return (
        <div style={{ paddingBottom: 40, backgroundColor: 'inherit' }}>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 20 }}>
                <Select
                    label={translate(TRANSLATIONS.main.fy)}
                    value={selectedFiscalYear}
                    values={fiscalYears}
                    mapToString={x => x?.name ?? ''}
                    onSelect={x => onFiscalYearSelect(x)}
                />
            </Flex>
            <ScrollSync>
                <>
                    {getOrderTables()}
                    <Sticky anchor='bottom'>
                        {getBottomTable()}
                    </Sticky>
                </>
            </ScrollSync>
        </div>
    );
};

export default OrdersTab;
