import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/common';
import { removeSnackbar } from '../../store';
import Footer from './Footer';
import Header from './Header';
import Snackbars from '../common/Snackbars';

interface LayoutProps {
    children?: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const dispatch = useDispatch();
    const snackbars = useAppSelector(state => state.snackbars.snackbars);
    const projectSelected = useAppSelector(state => Boolean(state.project.project));

    return (
        <div className='page'>
            <Header title='POWERTRAIN' />
            <main id='main' className={projectSelected ? 'with-project-header' : ''} tabIndex={-1}>
                {children}
            </main>
            <Snackbars snackbars={snackbars} removeSnackbar={x => dispatch(removeSnackbar(x))} />
            <Footer />
        </div>
    );
};

export default Layout;
