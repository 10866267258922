import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EMPTY_FISCAL_YEAR, TRANSLATIONS } from '../../constants';
import { FiscalYear } from '../../models';
import { HeaderTabType } from '../../enums';
import { useHook } from '../../hooks';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { setActiveTabType } from '../../store';
import Button, { ButtonType } from '../common/Button';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Loader from '../common/Loader';
import Modal from '../common/Modal';
import Select from '../common/Select';
import SpacingContainer from '../common/SpacingContainer';
import Title from '../common/Title';

const Administration = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { currentFiscalYear, fiscalYears } = useAppSelector(state => state.fiscalYears);
    const { handleCurrentFiscalYearGet, handleFiscalYearsGet, handleFiscalYearSave } = useHook(x => x.fiscalYears)();
    const { isKafkaImportLoading, handleImportKafkaData } = useHook(x => x.kafkaData)();
    const [selectedFiscalYear, setSelectedFiscalYear] = useState<FiscalYear>(currentFiscalYear ?? EMPTY_FISCAL_YEAR);
    const [showFiscalYearClosingModal, setShowFiscalYearClosingModal] = useState(false);
    const [showFiscalYearCloseModal, setShowFiscalYearCloseModal] = useState(false);
    const [showImportKafkaDataModal, setShowImportKafkaDataModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Administration));
        handleCurrentFiscalYearGet();
        handleFiscalYearsGet();
    }, []);

    useEffect(() => {
        currentFiscalYear && setSelectedFiscalYear(currentFiscalYear);
    }, [currentFiscalYear]);

    const onFiscalYearSelect = (fiscalYear: FiscalYear) => {
        setSelectedFiscalYear(fiscalYear);
    }; 

    const handleFiscalYearClose = () => {
        setShowFiscalYearCloseModal(false);
        handleFiscalYearSave({ ...selectedFiscalYear, closed: true, closing: false });
    };

    const onCancelFiscalYearClosing = () => {
        handleFiscalYearSave({ ...selectedFiscalYear, closing: false });
    };

    const handleFiscalYearClosing = () => {
        setShowFiscalYearClosingModal(false);
        handleFiscalYearSave({ ...selectedFiscalYear, closing: true });
    };

    const onImportKafkaData = () => {
        setShowImportKafkaDataModal(false);
        handleImportKafkaData();
    };

    const renderFiscalYearButtons = () => {
        const buttons = [];
        if (selectedFiscalYear.id) {
            selectedFiscalYear.closed
                ? buttons.push(<Button key='closedFiscalYear' onClick={() => { }} disabled type={ButtonType.Primary}>{translate(TRANSLATIONS.main.fiscalYearClosed)}</Button>)
                : selectedFiscalYear.closing
                    ? buttons.push(...[
                        <Button key='closeFiscalYear' onClick={() => setShowFiscalYearCloseModal(true)} type={ButtonType.Primary}>Close</Button>,
                        <Button key='cancelFiscalYearClosing' onClick={onCancelFiscalYearClosing} type={ButtonType.Secondary}>{translate(TRANSLATIONS.main.cancelFiscalYearClosing)}</Button>
                        ])
                    : buttons.push(<Button key='prepareFiscalYearClosing' onClick={() => setShowFiscalYearClosingModal(true)} type={ButtonType.Primary}>{translate(TRANSLATIONS.main.prepareFiscalYearForClosing)}</Button>);
        }

        return buttons;
    };

    const renderFiscalYearClosingModal = () => {
        return <Modal open={showFiscalYearClosingModal} movable>
            <SpacingContainer>
                <Title>{translate(TRANSLATIONS.main.prepareFiscalYearForClosing)}</Title>
                {translate(TRANSLATIONS.main.fiscalYearClosingMessage)}
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={() => setShowFiscalYearClosingModal(false)}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary}
                        onClick={handleFiscalYearClosing}
                    >
                        {translate(TRANSLATIONS.main.prepareFiscalYearForClosing)}
                    </Button>
                </Flex>
            </SpacingContainer>
        </Modal>;
    };

    const renderFiscalYearCloseModal = () => {
        return <Modal open={showFiscalYearCloseModal} movable>
            <SpacingContainer>
                <Title>{translate(TRANSLATIONS.main.closeFiscalYear)}</Title>
                {translate(TRANSLATIONS.main.fiscalYearCloseMessage)}
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={() => setShowFiscalYearCloseModal(false)}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary}
                        onClick={handleFiscalYearClose}
                    >
                        {translate(TRANSLATIONS.main.close)}
                    </Button>
                </Flex>
            </SpacingContainer>
        </Modal>;
    };

    const renderImportKafkaDataButton = () =>
        <Button key='importKafkaDataButton'
            onClick={() => setShowImportKafkaDataModal(true)}
            type={ButtonType.Primary}>
            {translate(TRANSLATIONS.main.importKafkaData)}
        </Button>;

    const renderImportKafkaDataModal = () => {
        return <Modal open={showImportKafkaDataModal} movable>
            <SpacingContainer>
                <Title>{translate(TRANSLATIONS.main.importKafkaData)}</Title>
                {translate(TRANSLATIONS.main.importKafkaDataMessage)}
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={() => setShowImportKafkaDataModal(false)}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary}
                        onClick={onImportKafkaData}
                    >
                        {translate(TRANSLATIONS.main.yes)}
                    </Button>
                </Flex>
            </SpacingContainer>
        </Modal>;
    };

    return (
        <ContentContainer>
            <Loader loading={isKafkaImportLoading} margin={10}>
                <div style={{ marginTop: 40, marginBottom: 60 }}>
                    <Title style={{ marginTop: 20 }}>{translate(TRANSLATIONS.main.fiscalYearClosing)}</Title>
                    <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} gap={20} style={{ marginTop: 40, marginBottom: 20 }}>
                        <Select
                            label={translate(TRANSLATIONS.main.fiscalYears)}
                            value={selectedFiscalYear}
                            values={fiscalYears ?? []}
                            mapToString={x => x?.name ?? ''}
                            onSelect={x => onFiscalYearSelect(x)}
                        />
                        {renderFiscalYearButtons()}
                        {renderFiscalYearCloseModal()}
                        {renderFiscalYearClosingModal()}
                    </Flex>
                </div>
                <div style={{ marginTop: 40, marginBottom: 60 }}>
                    <Title style={{ marginTop: 20 }}>{translate(TRANSLATIONS.main.importKafkaData)}</Title>
                    <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexStart} gap={20} style={{ marginTop: 40, marginBottom: 20 }}>
                        {renderImportKafkaDataButton()}
                        {renderImportKafkaDataModal()}
                    </Flex>
                </div>
            </Loader>
        </ContentContainer>
    );
};

export default Administration;
