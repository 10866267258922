export enum ProjectStatus {
    Intern,
    Info,
    Discuss,
    Cancelled,
    Lost,
    Order,
    Production,
    Closed
}
