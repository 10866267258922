import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { HeaderTabType } from '../../../enums';
import { useHook } from '../../../hooks';
import { useProjectClosed } from '../../../hooks/application';
import { useForceUpdate } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import SpacingContainer from '../../common/SpacingContainer';
import CostYearsTable from './CostYearsTable';
import CostYearsStickyTable from './CostYearsStickyTable';

const CostYearsTab = () => {
    const dispatch = useDispatch();
    const closed = useProjectClosed();
    const mainTableRef = useRef<HTMLDivElement>(null);
    const forceUpdate = useForceUpdate();
    const { addCostPerYear, currentlyShownServices, getCellColor, getServiceActualCostsForRow, getServiceColumns, handleChange, handleServiceClick, handleServiceCostChange, removeCostPerYear, serviceArray, fiscalYearSorting } = useHook(x => x.costYears)();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.CostsPerYear));
        forceUpdate(); // A rerender is necessary after mounting to set the real value of the table reference
    }, []);
    
    return (
        <div style={{ marginBottom: '50px', backgroundColor: 'inherit' }}>
            <SpacingContainer>
                <Flex direction={FlexDirection.RowReverse} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 20 }}>
                    <Button type={ButtonType.Primary} onClick={addCostPerYear} disabled={closed}>Create new line</Button>
                </Flex>
                <ScrollSync>
                    <>
                        <CostYearsTable
                            currentlyShownServices={currentlyShownServices}
                            fiscalYearSorting={fiscalYearSorting}
                            getCellColor={getCellColor}
                            getServiceActualCostsForRow={getServiceActualCostsForRow}
                            getServiceColumns={getServiceColumns}
                            handleChange={handleChange}
                            handleServiceClick={handleServiceClick}
                            handleServiceCostChange={handleServiceCostChange}
                            mainTableRef={mainTableRef}
                            removeCostPerYear={removeCostPerYear}
                        />
                        <CostYearsStickyTable
                            currentlyShownServices={currentlyShownServices}
                            getServiceColumns={getServiceColumns}
                            handleServiceClick={handleServiceClick}
                            mainTableRef={mainTableRef}
                            serviceArray={serviceArray}
                        />
                    </>
                </ScrollSync>
            </SpacingContainer>
        </div>
    );
};

export default CostYearsTab;
