import React from 'react';
import { ORDER_REQUEST_CONTACT, ORDER_REQUEST_COUNTRY, ORDER_REQUEST_DESCRIPTION, ORDER_REQUEST_MAINGROUP, ORDER_REQUEST_SAP, ORDER_REQUEST_VOLUME, TRANSLATIONS } from '../../../constants';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { OrderRequest } from '../../../models';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Modal from '../../common/Modal';
import SpacingContainer from '../../common/SpacingContainer';
import TextInput from '../../common/TextInput';
import Title from '../../common/Title';
import Tooltip from '../../common/Tooltip';

export interface OrderRequestModalProps {
    handleOrderRequestModalCancel: () => void;
    handleOrderRequestModalCreateDocument: () => void;
    orderRequest?: OrderRequest;
    showOrderRequestModal: boolean;
}

const OrderRequestModal = ({ handleOrderRequestModalCancel, handleOrderRequestModalCreateDocument, orderRequest, showOrderRequestModal }: OrderRequestModalProps) => {
    const orderRequestData = useAppSelector(state => state.project.orderRequestData);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { handleOrderRequestFormChange } = useHook(x => x.costYearsCountriesModals)();

    const emptyOrderRequestMandatoryFields = [
        ...orderRequestData && orderRequestData.mainGroup.length ? [] : [ORDER_REQUEST_MAINGROUP],
        ...orderRequestData && orderRequestData.description.length ? [] : [ORDER_REQUEST_DESCRIPTION],
        ...orderRequestData && orderRequestData.volume.length ? [] : [ORDER_REQUEST_VOLUME],
        ...orderRequestData && orderRequestData.contact.length ? [] : [ORDER_REQUEST_CONTACT],
        ...project && project.sapNr && project.sapNr.length ? [] : [ORDER_REQUEST_SAP],
        ...orderRequest && orderRequest.countryId ? [] : [ORDER_REQUEST_COUNTRY]
    ];

    const orderRequestTooltipText = `<div style = 'text-align: center' > ${translate(TRANSLATIONS.main.missingOrderRequestMandatoryFields)}: ${emptyOrderRequestMandatoryFields.map(x => { 
        type ObjectKey = keyof typeof TRANSLATIONS.main;
        const key = x as ObjectKey;

        return `<br />${translate(TRANSLATIONS.main[key])}`;
    }).join('')}</div>`;

    return <Modal open={showOrderRequestModal} movable>
        <SpacingContainer>
            <Title>{translate(TRANSLATIONS.main.createOrderRequest)}</Title>
            <Flex direction={FlexDirection.Column} justification={FlexJustification.FlexStart} gap={10}>
                <div>
                    <TextInput
                        label={translate(TRANSLATIONS.main.maingroupExample)}
                        name='maingroup'
                        value={orderRequestData?.mainGroup ?? ''}
                        required
                        error={!orderRequestData?.mainGroup}
                        onChange={x => handleOrderRequestFormChange({ mainGroup: x })}
                    />
                </div>
                <div>
                    <TextInput
                        label={translate(TRANSLATIONS.main.descriptionExample)}
                        name='description' value={orderRequestData?.description ?? ''}
                        required
                        error={!orderRequestData?.description}
                        onChange={x => handleOrderRequestFormChange({ description: x })}
                    />
                </div>
                <div>
                    <TextInput
                        label={translate(TRANSLATIONS.main.volumeExample)}
                        name='volume'
                        value={orderRequestData?.volume ?? ''}
                        required
                        error={!orderRequestData?.volume}
                        onChange={x => handleOrderRequestFormChange({ volume: x })}
                    />
                </div>
                <div>
                    <TextInput
                        label={translate(TRANSLATIONS.main.contactExample)}
                        name='contact'
                        value={orderRequestData?.contact ?? ''}
                        required error={!orderRequestData?.contact}
                        onChange={x => handleOrderRequestFormChange({ contact: x })}
                    />
                </div>
            </Flex>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                <Button type={ButtonType.Tertiary} onClick={handleOrderRequestModalCancel}>
                    {translate(TRANSLATIONS.main.cancel)}
                </Button>
                <Tooltip
                    key={orderRequestTooltipText}
                    id='saveTooltip'
                    delayShow={450}
                    text={orderRequestTooltipText}
                    place='bottom'
                    hide={!emptyOrderRequestMandatoryFields.length}
                >
                    <Button type={ButtonType.Primary}
                        onClick={handleOrderRequestModalCreateDocument}
                        disabled={Boolean(emptyOrderRequestMandatoryFields.length)}
                    >
                        {translate(TRANSLATIONS.main.createDocument)}
                    </Button>
                </Tooltip>
            </Flex>
        </SpacingContainer>
    </Modal>;
};

export default OrderRequestModal;
