import React, { ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useAppSelector } from './ts/hooks/common';
import Unauthorized from './ts/components/application/helperPages/authentication/Unauthorized';
import { AuthenticationInProgress } from './ts/components/application/helperPages/authentication';

interface RequireAuthProps {
    children: ReactNode;
    roles?: string[];
}

const RequireAuth = ({ children, roles }: RequireAuthProps): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const userRoles = useAppSelector(state => state.user.roles);
    const authCallFinished = useAppSelector(state => state.user.authCallFinished);
    const authorized = Boolean(isAuthenticated && userRoles.length && roles && roles.some(x => userRoles.map(y => y.name).includes(x)));

    return authorized
        ? <>{children}</>
        : authCallFinished
            ? <Unauthorized />
            : <AuthenticationInProgress />;
};

export default RequireAuth;
