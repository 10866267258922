import React from 'react';
import { TRANSLATIONS } from '../../../../constants';
import { useTranslate } from '../../../../hooks/common';
import ContentContainer from '../../../common/ContentContainer';
import Layout from '../../../layout/Layout';

export const AuthenticationInProgress = () => {
    const translate = useTranslate();

    return (
            <Layout>
            <ContentContainer>
                <h1>{translate(TRANSLATIONS.helperPages.authProgress)}</h1>
                    <p>{translate(TRANSLATIONS.helperPages.authProgressMessage)}</p>
                </ContentContainer>
            </Layout>
    );
};
