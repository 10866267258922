import { AvailableValues } from '../models';

export const EMPTY_AVAILABLE_VALUES: AvailableValues = {
    cities: [],
    contacts : [],
    corporationGroups: [],
    countries : [],
    unfilteredCountries: [],
    customerGroups: [],
    customers: [],
    customerCities: [],
    fiscalYears: [],
    machineSubCategories: [],
    machineTypes: [],
    serviceCategories: [],
    serviceCategoryNames: [],
    technicalInfoDrives: [],
    technicalInfoNcs: [],
    technicalInfoOperatingSystems: [],
    technicalInfoPlcs: [],
    technicalInfoVisualizations: []
};
