import React, { RefObject } from 'react';
import { AvailableValues, BlobResponse, CostCalculation, CostsPerYearService, CountryCostsPerYearService, Project, ReportRequest, Result } from '../models';
import { HeaderTabType } from '../enums';
import { EMPTY_COST_CALCULATION, EMPTY_SERVICE_CATEGORY, HEADER_HEIGHT } from '../constants';
import { NUMBER_LOCALE } from '../constants/NumberFormat';

export const apiCall = async <T extends Result>(responsePromise: Promise<T>, onSuccess: (reponse: T) => Promise<void>, onError: (response?: T) => Promise<void>) => {
    let response: T = null as any;
    
    try {
        response = await responsePromise;
    } catch { }

    if (response && !response.hasError) {
        await onSuccess(response);
    } else {
        await onError(response);
    }
};

export const apiCalls = async <T extends Result>(responsePromises: Promise<T[]>, onSuccess: (reponses: T[]) => Promise<void>, onError: () => Promise<void>) => {
    let responses: T[] = [];
    
    try {
        responses = await responsePromises;
    } catch { }
    
    if (responses && responses.every(x => x && !x.hasError)) {
        await onSuccess(responses);
    } else {
        await onError();
    }
};

export const fileDownload = async <T extends BlobResponse>(responsePromise: Promise<T>, onError: () => Promise<void>, defaultFileName: string, target?: string, openInNewTab?: boolean) => {
    let response: T = null as any;
    
    try {
        response = await responsePromise;
    } catch { }
    
    if (response && response.blob.size > 0) {
        const fileUrl = URL.createObjectURL(response.blob);
        const link = document.createElement('a');
        const fileName = response.fileName.length ? response.fileName : defaultFileName;
        openInNewTab && window.open(fileUrl, fileName);
        link.href = fileUrl;
        link.referrerPolicy = 'noopener noreferrer';
        link.target = target ?? '_blank';
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        await onError();
    }
};

export const convertNumberToLocal = (value: number) => value.toLocaleString(NUMBER_LOCALE);

export const convertStringToLocal = (value: string) => convertNumberToLocal(Number(value));

export const getAccessToken = () => {
    if (!process.env.REACT_APP_TOKEN_NAME) {
        return '';
    }

    const accountTokens = window.sessionStorage.getItem(process.env.REACT_APP_TOKEN_NAME);

    return accountTokens ?? '';
};

export const setAccessToken = (token: string) => {
    process.env.REACT_APP_TOKEN_NAME && window.sessionStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
};

export const removeAccessToken = () => {
    process.env.REACT_APP_TOKEN_NAME && window.sessionStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
};

export const mergeRefs = <T>(...refs: React.ForwardedRef<T>[]): React.RefCallback<T> => {
    return (node: T) => {
        for (const ref of refs) {
            if (ref) {
                if (typeof ref === 'function') ref(node);
                if ('current' in ref) ref.current = node;
            }
        }
    };
};

export const sortBy = <T>(array: T[], sortingSelector: (value: T) => string | number) => {
    return array.sort((a, b) => {
        if (sortingSelector(a) < sortingSelector(b)) {
            return -1;
        }

        if (sortingSelector(a) > sortingSelector(b)) {
            return 1;
        }

        return 0;
    });
}; 

export const trimAccents = (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const getTabLink = (tabType?: HeaderTabType): string => {
    switch (tabType) {
        case HeaderTabType.CostCalculation:
            return '/cost-calculation';
        case HeaderTabType.CostsPerYear:
            return '/cost-years';
        case HeaderTabType.CostYearsCountries:
            return '/cost-years-countries';
        case HeaderTabType.General:
            return '';
        case HeaderTabType.History:
            return '/history';
        case HeaderTabType.Orders:
            return '/orders';
        case HeaderTabType.VolumeOverview:
            return '/volume-overview';
        case HeaderTabType.Volumes:
            return '/volumes';
        case HeaderTabType.ProjectAttachments:
            return '/attachments';
        default:
            return '';
    }
};

export const getScrollToElement = (ref: RefObject<HTMLDivElement>) => ref.current ? window.scrollY + ref.current.getBoundingClientRect().top - HEADER_HEIGHT : -1;

export const clearIncompleteDatesFromProject = <T extends Project | undefined>(project: T): T => project && {
    ...project,
    closedStatusDate: clearIncompleteDate(project.closedStatusDate),
    created: clearIncompleteDate(project.created),
    decision: clearIncompleteDate(project.decision),
    discussStatusDate: clearIncompleteDate(project.discussStatusDate),
    endOfContract: clearIncompleteDate(project.endOfContract),
    endOfWarrantyDate: clearIncompleteDate(project.endOfWarrantyDate),
    offer: clearIncompleteDate(project.offer),
    orderDate: clearIncompleteDate(project.orderDate),
    sop: clearIncompleteDate(project.sop)
};

export const clearIncompleteDatesFromReportRequest = (reportRequest: ReportRequest): ReportRequest => ({
    ...reportRequest,
    creationDateFrom: clearIncompleteDate(reportRequest.creationDateFrom),
    creationDateTo: clearIncompleteDate(reportRequest.creationDateTo),
    decisionDateFrom: clearIncompleteDate(reportRequest.decisionDateFrom),
    decisionDateTo: clearIncompleteDate(reportRequest.decisionDateTo)
});

export const openLinkInNewTab = (url: string) => window.open(url, '_blank', 'noopener, noreferrer');

export const toggleListElement = <T, >(list: T[], element: T) => 
    list.includes(element) ? list.filter(x => x !== element) : [...list, element];

export const getAllEnumValues = <T>(enumObj: T) => {
    const enumValues: Array<T[keyof T]> = [];
    
    for (const enumKey in enumObj) {
        // eslint-disable-next-line no-extra-parens
        const enumValue = (enumObj as Record<string, unknown>)[enumKey];
        
        if (typeof enumValue === 'number')
        {
            enumValues.push(enumValue as T[keyof T]);
        }
    }
    
    return enumValues;
};

export const addPrefixToKey = (key: string, projectId?: string) => `${projectId ?? 'new_project'}-${key}`;

export const getUniqueValues = <T>(array: T[], key: keyof T) => array.filter((x, i, self) => i === self.findIndex(y => y[key] === x[key]));

export const getCostCalculations = (costCalculations: CostCalculation[], services: CostsPerYearService[] | CountryCostsPerYearService[], availableValues: AvailableValues) => 
    [...services].filter((x: CostsPerYearService | CountryCostsPerYearService) => !costCalculations?.map(y => y.serviceCategoryId).includes(x.serviceCategoryId))
        .map<CostCalculation>(x => ({ 
            ...EMPTY_COST_CALCULATION, 
            serviceCategoryId: x.serviceCategoryId, 
            serviceCategoryNameId: availableValues.serviceCategories.find(y => y.id === x.serviceCategoryId)?.serviceCategoryNameId, 
            serviceCategory: availableValues.serviceCategories.find(y => y.id === x.serviceCategoryId) ?? EMPTY_SERVICE_CATEGORY 
        }));

export const getServiceName = (service: CostCalculation | undefined, availableValues: AvailableValues) =>
    service
        ? `${service.serviceCategory.index.toString().padStart(2, '0')}. ${availableValues.serviceCategoryNames.find(y => y.id === service.serviceCategoryNameId)?.en ?? (service.customName == null
            ? ''
            : service.customName.length ? service.customName : 'Deleted service')}`
        : '';

export const isDateValid = (date: string | undefined) =>
    !date
    || (date.includes('Y') && date.includes('M') && date.includes('D'))
    || (!date.includes('Y') && !date.includes('M') && !date.includes('D'));

const clearIncompleteDate = (date: string | undefined): string | undefined => !date || date.includes('Y') || date.includes('M') || date.includes('D') ? undefined : date;

export const convertMegabytesToBytes = (megaBytes: number) => megaBytes * Math.pow(1024, 2);

export const getFormattedDateTime = (dateString: string) => {
    const getYear = (value: string) => value.slice(0, 4);

    const getMonth = (value: string) => value.slice(5, 7);

    const getDay = (value: string) => value.slice(8, 10);

    const getHour = (value: string) => value.slice(11, 13);

    const getMinutes = (value: string) => value.slice(14, 16);

    const getSeconds = (value: string) => value.slice(17, 19);

    return `${getYear(dateString)}/${getMonth(dateString)}/${getDay(dateString)} ${getHour(dateString)}:${getMinutes(dateString)}:${getSeconds(dateString)}`;
};
