import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { convertNumberToLocal, convertStringToLocal } from '../../../services';
import { TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { setActiveTabType } from '../../../store';
import { useForceUpdate, useTranslate } from '../../../hooks/common';
import { useHook } from '../../../hooks';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Grid from '../../common/Grid';
import SpacingContainer from '../../common/SpacingContainer';
import TextInput from '../../common/TextInput';
import CostCalculationStickyTable from './CostCalculationStickyTable';
import CostCalculationTable from './CostCalculationTable';
import { Column } from '../../common/Table';

const CostCalculationTab = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const mainTabledRef = useRef<HTMLDivElement>(null);
    const forceUpdate = useForceUpdate();
    const { costsCalculated, costsCalculatedOfSupportedVolume, costsSupportedVolume, costsTotalVolume, getFixedDigitTotalCostsCalculated, getRowTotalValue,
        handleChange, isSpecialCostCalculation } = useHook(x => x.costCalculation)();

    const columns: Column[] = [
        { label: translate(TRANSLATIONS.main.services), min: '80px', max: '100px', bold: true },
        { label: '', min: '250px', max: '3fr', bold: true },
        { label: translate(TRANSLATIONS.main.quantity), min: '180px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.price), min: '180px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.total), min: '180px', max: '1fr', bold: true }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.CostCalculation));
        forceUpdate(); // A rerender is necessary after mounting to set the real value of the table reference
    }, []);

    return (
        <div style={{ marginBottom: '50px', backgroundColor: 'inherit' }}>
            <SpacingContainer>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                    <Grid columns={4} columnGap={15} rowGap={15} style={{ marginTop: 40, marginBottom: 40 }}>
                        <TextInput label={translate(TRANSLATIONS.main.totalVolume)} name='totalVolume' value={convertNumberToLocal(costsTotalVolume)} disabled />
                        <TextInput label={translate(TRANSLATIONS.main.supportedVolume)} name='supportedVolume' value={convertNumberToLocal(costsSupportedVolume)} disabled />
                        <TextInput label={translate(TRANSLATIONS.main.costsCalculated)} name='costsCalculated' value={convertStringToLocal(costsCalculated.toFixed(Math.min(3, costsCalculated.toString().split('.')[1]?.length ?? 0)))} disabled />
                        <TextInput label={translate(TRANSLATIONS.main.costsCalculatedOfSupportedVolume)} name='costsCalculatedOfSupportedVolume' value={`${costsCalculatedOfSupportedVolume} %`} disabled />
                    </Grid>
                </Flex>
                <ScrollSync>
                    <>
                        <CostCalculationTable
                            columns={columns}
                            getRowTotalValue={getRowTotalValue}
                            handleChange={handleChange}
                            isSpecialCostCalculation={isSpecialCostCalculation}
                            mainTableRef={mainTabledRef}
                        />
                        <CostCalculationStickyTable
                            columns={columns}
                            getFixedDigitTotalCostsCalculated={getFixedDigitTotalCostsCalculated}
                            mainTableRef={mainTabledRef} />
                    </>
                </ScrollSync>
            </SpacingContainer>
        </div>
    );
};

export default CostCalculationTab;
