import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { convertNumberToLocal, sum } from '../../../services';
import { useHook } from '../../Hooks';
import { CostsPerYear, Volume, VolumeOverview } from '../../../models';
import { EMPTY_VOLUME, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../common';
import { setProject } from '../../../store';
import { Column, Row } from '../../../components/common/Table';

export const UseVolume = () => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { getUniqueKey } = useHook(x => x.uniqueKey)();

    const columns: Column[] = useMemo(() => [
        { key: 'costs', label: '', min: '130px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.totalVolume), min: '160px', max: '2fr', bold: true },
        { label: `${project?.country?.name ?? 'N/A'} Volume`, min: '160px', max: '2fr', bold: true },
        { label: translate(TRANSLATIONS.main.softwareVolume), min: '160px', max: '2fr', bold: true },
        { label: translate(TRANSLATIONS.main.epowertrainVolume), min: '160px', max: '2fr', bold: true },
        { key: 'deleteIcon', label: '', min: '80px', max: '80px' }
    ], [project]);

    const addNewVolume = () => {
        project &&
            dispatch(setProject({
                ...project,
                volumes: [
                    ...project.volumes ?? [],
                    {
                        ...EMPTY_VOLUME,
                        projectId: project?.id.length ? project?.id : undefined,
                        key: getUniqueKey()
                    }
                ]
            }));
    };

    const removeVolume = (index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                volumes: [
                    ...project.volumes?.filter((x, i) => index !== i) ?? []
                ]
            }));
    };

    const handleChange = (value: Partial<Volume>, index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                volumes: [
                    ...project.volumes?.map((x, i) => index === i ? { ...x, ...value } : x) ?? []
                ]
            }));
    };

    const getTotalCosts = (costsPerYears: CostsPerYear[]) =>
        costsPerYears?.reduce((currentSum, x) =>
            x.services.length > 0
                ? currentSum + x.services.reduce((serviceSum, service) =>
                    (service.plannedCost ?? 0) > 0
                        ? serviceSum + (service.plannedCost ?? 0)
                        : serviceSum
                    , 0)
                : currentSum
            , 0);

    const getUnassignedCalculation = (volumes: Volume[] = [], volumeOverviews?: VolumeOverview[]): Row[] => {
        const volumeOverviewTotalSales = sum(volumeOverviews?.filter(x => x.totalQuantity) ?? [], 'totalQuantity', 'price');
        const volumeOverviewCountrySales = sum(volumeOverviews?.filter(x => x.countryQuantity
            && x.machineSubCategory?.machineType?.name !== 'Software Sales'
            && x.machineSubCategory?.machineType?.name !== 'Software Engineering'
            && x.machineSubCategory?.machineType?.name !== 'e-Powertrain Sales'
        ) ?? [], 'countryQuantity', 'price');
        const volumeOverviewSoftwareSales = sum(volumeOverviews?.filter(x => x.totalQuantity
            && x.machineSubCategory?.machineType?.name === 'Software Sales'
            || x.machineSubCategory?.machineType?.name === 'Software Engineering'
        ) ?? [], 'totalQuantity', 'price');
        const totalSales = sum(volumes, 'totalSales');
        const countrySales = sum(volumes, 'countrySales');
        const softwareSales = sum(volumes, 'softwareSales');
        const ePowerTrainSales = sum(volumes, 'ePowertrainSales');

        return [
            {
                key: 'unassigned',
                cells: [
                    { content: translate(TRANSLATIONS.main.unassigned) },
                    { content: convertNumberToLocal(volumeOverviewTotalSales - totalSales) },
                    { content: convertNumberToLocal(volumeOverviewCountrySales - countrySales) },
                    { content: convertNumberToLocal(volumeOverviewSoftwareSales - softwareSales) },
                    { content: '' },
                    { content: '' }
                ]
            },
            {
                key: 'bottomVolume',
                cells: [
                    { content: translate(TRANSLATIONS.main.volume) },
                    { content: convertNumberToLocal(volumeOverviewTotalSales) },
                    { content: convertNumberToLocal(volumeOverviewCountrySales) },
                    { content: convertNumberToLocal(volumeOverviewSoftwareSales) },
                    { content: convertNumberToLocal(ePowerTrainSales) },
                    { content: '' }
                ]
            }
        ];
    };

    return {
        addNewVolume,
        columns,
        removeVolume,
        handleChange,
        getTotalCosts,
        getUnassignedCalculation
    };
};
