import React from 'react';
import { convertNumberToLocal } from '../../../services';
import { CostCalculation, CostsPerYearService, History } from '../../../models';
import { EMPTY_COST_PER_YEAR_SERVICE, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { ServicesWithFiscalYear } from './HistoryTab';
import Table, { Cell, Column, Row } from '../../common/Table';

export interface CostsByYearsTableProps {
    currentlyShownServices: (CostCalculation | undefined)[];
    currentHistory: History | undefined;
    costsTotal: number;
    getServiceColumns: () => Column[];
    getPlannedOrActualCost: (service: CostsPerYearService, fiscalYearId: number) => number;
    handleServiceClick: (index: number) => void;
}

const CostsByYearsTable = ({ costsTotal, currentHistory, currentlyShownServices, getServiceColumns, getPlannedOrActualCost, handleServiceClick }: CostsByYearsTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const translate = useTranslate();

    const servicesWithFiscalYears = currentHistory?.costsPerYears?.map<ServicesWithFiscalYear>(x => {
        return {
            services: x.services,
            fiscalYearId: x.fiscalYearId
        };
    }) ?? [];

    const getCostsPerYearServiceSumCells = (servicesWithFiscalYear: ServicesWithFiscalYear[]): Cell[] => [
        ...currentlyShownServices.map<Cell>(x => ({
            content: convertNumberToLocal(
                servicesWithFiscalYear.flatMap(y => (y.services.filter(z => z.serviceCategoryId === x?.serviceCategoryId) ?? EMPTY_COST_PER_YEAR_SERVICE).map(z => getPlannedOrActualCost(z, y.fiscalYearId)))
                    .reduce((sum, val) => sum += val, 0)),
            inputStyle: false
        }))
    ];

    const getCostsByYearsTableColumns = (): Column[] => [
        { label: '', min: '100px', max: '3fr', notClickable: true },
        { label: translate(TRANSLATIONS.main.total), min: '100px', max: '3fr', notClickable: true },
        ...getServiceColumns()
    ];

    const getCostsPerYearServiceCells = (servicesWithFiscalYear: ServicesWithFiscalYear): Cell[] => [
        ...currentlyShownServices.map<Cell>(x => ({
            content: convertNumberToLocal(
                getPlannedOrActualCost(servicesWithFiscalYear.services.find(y => y.serviceCategoryId === x?.serviceCategoryId) ?? EMPTY_COST_PER_YEAR_SERVICE, servicesWithFiscalYear.fiscalYearId)),
            inputStyle: false
        }))
    ];

    const getCostsByYearRows = (): Row[] => [
        ...(currentHistory?.costsPerYears ?? []).map<Row>((x, index) => {
            const fiscalYear = availableValues.fiscalYears.find(y => y.id === x.fiscalYearId)?.name;
            const costsPerYearSum = x.services.map(y => getPlannedOrActualCost(y, x.fiscalYearId)).reduce((serviceSum, val) => serviceSum = serviceSum + val, 0);

            return {
                key: `costsPerYear${index}`,
                cells: [
                    {
                        content: fiscalYear ?? ''
                    },
                    {
                        content: convertNumberToLocal(costsPerYearSum ?? 0)
                    },
                    ...getCostsPerYearServiceCells({ services: x.services, fiscalYearId: x.fiscalYearId })
                ]
            };
        })
    ];

    return (
        <Table
            onHeaderClick={handleServiceClick}
            noHeaderLineBreak={true}
            columns={getCostsByYearsTableColumns()}
            rows={[
                {
                    key: 'costsByYearsSum',
                    cells: [
                        {
                            content: translate(TRANSLATIONS.main.total)
                        },
                        {
                            content: convertNumberToLocal(costsTotal)
                        },
                        ...getCostsPerYearServiceSumCells(servicesWithFiscalYears)
                    ]
                },
                ...getCostsByYearRows()
            ]}
        />
    );
};

export default CostsByYearsTable;
