import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { usePageSize } from '../../hooks/common';

export interface ResponsiveStyle {
    rule: 'min' | 'max';
    style: CSSProperties;
    treshold: Treshold;
}

interface ResponsiveElementProps {
    responsiveStyles: ResponsiveStyle[];
    children?: ReactNode;
    style?: CSSProperties;
}

type Treshold = number | 'big' | 'medium' | 'small' | 'verySmall';

const ResponsiveElement = ({ children, responsiveStyles, style }: ResponsiveElementProps) => {
    const size = usePageSize();
    const [responsiveStyle, setResponsiveStyle] = useState<CSSProperties>({});

    const getTresholdNumberValue = (treshold: Treshold): number => {
        switch (treshold) {
            case 'big':
                return 992;
            case 'medium':
                return 768;
            case 'small':
                return 600;
            case 'verySmall':
                return 480;
            default:
                return treshold;
        }
    };

    useEffect(() => {
        setResponsiveStyle([
                style ?? {},
                ...responsiveStyles
                    .map(x => ({ ...x, treshold: getTresholdNumberValue(x.treshold) }))
                    .map(x => (x.rule === 'min' ? x.treshold <= size.width : x.treshold >= size.width) ? x.style : {})
            ].reduce((x, y) => ({ ...x, ...y }))
        );
    }, [size]);

    return (
        <div style={responsiveStyle}>
            {children}
        </div>
    );
};

export default ResponsiveElement;
