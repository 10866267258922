import { convertStringToLocal } from './Utilities';

export type AllowablePageSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const convertToDecimalString = (stringValue: string, percentage?: 'percentage' | 'multiplier' ) => {
    const decimalLength = stringValue.split('.')[1]?.length;
    const lastCharacter = stringValue[stringValue.length - 1];
    const decimalString = percentage 
        ? percentage == 'percentage' 
            ? `${(Number(stringValue) * 100).toFixed(decimalLength >= 2 ? decimalLength - 2 : decimalLength)}${lastCharacter === '0' ? '0' : ''}`
            : `${(Number(stringValue) / 100).toFixed(stringValue.includes('.') ? decimalLength + 2 : 2)}${lastCharacter === '0' ? '0' : ''}`
        : Number(stringValue).toFixed(decimalLength);

    return decimalString;
};

export const convertNumberToDecimalString = (value: number, decimalLength: number, locale?: Intl.UnicodeBCP47LocaleIdentifier) => {
    const fixedDecimal = value.toFixed(value.toString().split('.')[1] ? decimalLength : 0);
    return locale ? convertStringToLocal(fixedDecimal) : fixedDecimal;
};
export const sum = <T, K extends keyof T>(summand: T[], propertyValue: K, propertyQuantity?: K) =>
    summand.reduce((sum, x) =>
        (propertyQuantity
            ? x[propertyQuantity] ?? 0  
            : x[propertyValue] ?? 0
        )
            ? sum + (Number(x[propertyValue]) ?? 0) * (propertyQuantity ? Number(x[propertyQuantity]) : 1)
            : sum
    , 0);
    
export const sumArray = (array: number[][]) => array[0].map((_, i) => array.reduce((sum, x) => sum + x[i], 0));

export const getTotalPageNumber = <T>(list: T[], pageSize: AllowablePageSizes) =>
    Math.floor((list.length ?? pageSize) / pageSize) + ((list.length ? list.length : pageSize) % pageSize > 0 ? 1 : 0);
