export enum HeaderTabType {
    Administration,
    CostCalculation,
    CostsPerYear,
    CostYearsCountries,
    General,
    History,
    Home,
    Orders,
    Reports,
    Start,
    VolumeOverview,
    Volumes,
    ProjectAttachments
}
