import { ProjectStatus } from '../../../enums';
import { useAppSelector } from '../../common';
import { useHook } from '../../Hooks';

export const useProjectClosed = (): boolean => {
    const initialStatus = useAppSelector(state => state.project.initialStatus);
    const project = useAppSelector(state => state.project.project);
    const { closingFiscalYearRestriction } = useHook(x => x.fiscalYears)();
    
    return closingFiscalYearRestriction() || project?.isLocked || initialStatus === ProjectStatus.Closed || initialStatus === ProjectStatus.Lost || initialStatus === ProjectStatus.Cancelled;
};
