import React, { RefObject } from 'react';
import { convertNumberToLocal, sum } from '../../../services';
import { EMPTY_COST_PER_YEAR_SERVICE, MAX_INTEGER, NUMBER_FORMAT_WITH_SEPARATOR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { CostCalculation, CostsPerYearService, Country, CountryCostsPerYear, CountryCostsPerYearService, CountryCostsPerYearWithOriginalIndex, DocumentGenerationData, FiscalYear, OrderRequest } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useProjectClosed } from '../../../hooks/application';
import CostYearsCountriesDropdownButton from './CostYearsCountriesDropdownButton';
import NumberInput from '../../common/NumberInput';
import Select from '../../common/Select';
import Table, { Cell, Column, Row } from '../../common/Table';
import TextInput from '../../common/TextInput';

export interface CostYearsCountriesTableProps {
    countryCostsInFiscalYear: CountryCostsPerYearWithOriginalIndex[];
    currentServiceStartIndex: number;
    fiscalYearClosed: boolean;
    fiscalYearSelected: FiscalYear;
    getSelectableCountries: () => Country[];
    getServiceColumns: (serviceList: CostCalculation[], currentServiceStartIndex: number, serviceDisplayLimit: number, serviceArray: CostCalculation[]) => Column[];
    handleChange: (value: Partial<CountryCostsPerYear>, index: number) => void;
    handleServiceClick: (index: number) => void;
    handleServiceCostChange: (value: Partial<CountryCostsPerYearService>, textValue: string, serviceCategoryId: number, inputCostsPerYearIndex: number) => void;
    mainTableRef: RefObject<HTMLDivElement>;
    serviceArray: CostCalculation[];
    serviceDisplayLimit: number;
    serviceListCurrent: CostCalculation[];
    setKuebData: (kuebData: DocumentGenerationData) => void;
    setOrderRequest: (orderRequest: OrderRequest) => void;
    setShowKuebModal: (value: boolean) => void;
    setShowOrderRequestModal: (value: boolean) => void;
}

const CostYearsCountriesTable = ({
    countryCostsInFiscalYear,
    currentServiceStartIndex,
    fiscalYearClosed,
    fiscalYearSelected,
    getSelectableCountries,
    getServiceColumns,
    handleChange,
    handleServiceClick,
    handleServiceCostChange,
    mainTableRef,
    serviceArray,
    serviceDisplayLimit,
    serviceListCurrent,
    setKuebData,
    setOrderRequest,
    setShowKuebModal,
    setShowOrderRequestModal
}: CostYearsCountriesTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const translate = useTranslate();
    const closed = useProjectClosed();

    const getServiceActualCostsForRow = (services: CostsPerYearService[]): Cell[] => {
        return serviceListCurrent.map(x => services.find(y => y.serviceCategoryId == x.serviceCategoryId) ?? EMPTY_COST_PER_YEAR_SERVICE)
            .map<Cell>(x => ({ content: convertNumberToLocal(x.actualCost ?? 0) }));
    };

    const getServicePlannedCostsForRow = (services: CountryCostsPerYearService[], costsPerYearIndex: number): Cell[] =>
        serviceListCurrent.map<Cell>((x, index) => ({
            content:
                <NumberInput
                    label='Cost'
                    key={`${x.serviceCategoryId} ${index} servicePlannedCost`}
                    name={`${index} servicePlannedCost`}
                    value={services.find(y => y.serviceCategoryId == x.serviceCategoryId)?.plannedCost?.toString() ?? '0'}
                    onChange={y => x.serviceCategoryId && handleServiceCostChange({ plannedCost: Number(y) }, y, x.serviceCategoryId, costsPerYearIndex)}
                    disabled={closed || fiscalYearClosed}
                    decimal
                    negative
                    maxValue={MAX_INTEGER}
                    numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                    numberOfDigits={2}
                    tooltipId={`cost_${x.serviceCategoryId?.toString()}`}
                />,
            inputStyle: true
        }));

    return <Table
        colored={i => i % 4 === 0 || i % 4 === 1}
        thickBottomLine={i => i % 2 === 0}
        tableRef={mainTableRef}
        scrollId={SCROLL_ID.COST_YEARS_COUNTRIES}
        noScrollbar
        onHeaderClick={handleServiceClick}
        noHeaderLineBreak={true}
        columns={[
            { key: 'country', label: '', min: '130px', max: '1fr', bold: true, notClickable: true },
            { label: translate(TRANSLATIONS.main.costType), min: '120px', max: '2fr', bold: true, notClickable: true },
            { label: translate(TRANSLATIONS.main.totalCosts), min: '120px', max: '2fr', bold: true, notClickable: true },
            ...getServiceColumns(serviceListCurrent, currentServiceStartIndex, serviceDisplayLimit, serviceArray),
            { key: 'deleteIcon', label: '', min: '80px', max: '80px', notClickable: true }]}
        rows={[
            ...(countryCostsInFiscalYear ?? []).map<Row[]>((x, index) => [
                {
                    key: `${x.countryCostsPerYear.id ?? x.countryCostsPerYear.key ?? index}_Plan`,
                    cells: [
                        {
                            content:
                                <Select
                                    key={`${index} country`}
                                    label={translate(TRANSLATIONS.main.country)}
                                    value={availableValues.unfilteredCountries.find(y => y.id == x.countryCostsPerYear.countryId)}
                                    values={getSelectableCountries()}
                                    mapToString={y => y?.name ?? ''}
                                    onSelect={y => handleChange({ countryId: y?.id }, x.originalIndex)}
                                    disabled={closed || fiscalYearClosed}
                                    required
                                    error={!x.countryCostsPerYear.countryId}
                                />,
                            inputStyle: true
                        },
                        { content: translate(TRANSLATIONS.main.assigned) },
                        { content: convertNumberToLocal(sum(x.countryCostsPerYear.countryCostsPerYearServices, 'plannedCost')) },
                        ...getServicePlannedCostsForRow(x.countryCostsPerYear.countryCostsPerYearServices, x.originalIndex),
                        {
                            content: <CostYearsCountriesDropdownButton
                                countryCostYearWithIndex={x}
                                index={index}
                                fiscalYearClosed={fiscalYearClosed}
                                fiscalYearSelected={fiscalYearSelected}
                                setOrderRequest={setOrderRequest}
                                setKuebData={setKuebData}
                                setShowKuebModal={setShowKuebModal}
                                setShowOrderRequestModal={setShowOrderRequestModal}
                            />,
                            inputStyle: true
                        }

                    ]
                },
                {
                    key: `${index} Actual`,
                    cells: [
                        {
                            content:
                                <TextInput
                                    label={translate(TRANSLATIONS.main.patacNo)}
                                    name='patacNumber'
                                    onChange={y => handleChange({ patacNumber: y }, x.originalIndex)}
                                    value={x.countryCostsPerYear.patacNumber ?? []}
                                    disabled={closed || fiscalYearClosed}
                                />,
                            inputStyle: true
                        },
                        { content: translate(TRANSLATIONS.main.actual) },
                        { content: convertNumberToLocal(sum(x.countryCostsPerYear.countryCostsPerYearServices, 'actualCost')) },
                        ...getServiceActualCostsForRow(x.countryCostsPerYear.countryCostsPerYearServices)
                    ]
                }
            ]).flat(),
            { key: 'divider', divider: true, cells: [] }
        ]}
    />;
};

export default CostYearsCountriesTable;
