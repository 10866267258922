import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getOrderType, getSortingIndex, isReverse } from '../../services';
import { HeaderTabType } from '../../enums';
import { LOCKED_PROJECT_ORDERING_NAMES, REMINDED_PROJECT_ORDERING_NAMES, TRANSLATIONS } from '../../constants';
import { setActiveTabType } from '../../store';
import { useTranslate } from '../../hooks/common';
import { useHook } from '../../hooks';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Pagination from '../common/Pagination';
import SpacingContainer from '../common/SpacingContainer';
import Table from '../common/Table';
import Tooltip from '../common/Tooltip';
import ProjectSearch from './Project/ProjectSearch';

const Start = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translate = useTranslate();
    const {
        lockedProjects, lockedProjectsLoading, onLockedProjectsOrdering, onLockedProjectsPaging, onRemindedProjectsOrdering, onRemindedProjectsPaging, remindedProjects,
        remindedProjectsLoading, showLockedProjects, showRemindedProjects
    } = useHook(x => x.lockedAndRemindedProjects)();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Start));
    }, []);

    const handleProjectClick = (id: string) => navigate(`/project/${id}`);

    const marginStyle = { marginTop: 40, marginBottom: 40 };

    const renderTooltipWrapper = (text: string, index: number) => <Tooltip key={text} id={`${Math.floor(Math.random() * 10000)}_${index}`} delayShow={750} text={text}>{text}</Tooltip>;

    return (
            <ContentContainer>
            <SpacingContainer>
                <ProjectSearch onProjectClick={handleProjectClick} />
                    {showLockedProjects && (
                        <>
                        <h1>{translate(TRANSLATIONS.main.lockedProjects)}</h1>
                            <Table
                                columns={[
                                    { label: translate(TRANSLATIONS.main.projectName), min: '250px', max: '8fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.responsible), min: '180px', max: '4fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.sapCustomer), min: '180px', max: '4fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.sapCountry), min: '150px', max: '4fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.projectLocation), min: '150px', max: '4fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.sapNo), min: '110px', max: '3fr', bold: true },
                                    { label: translate(TRANSLATIONS.main.lockedSince), min: '110px', max: '3fr', bold: true }
                                ]}
                                rows={lockedProjects?.items.map((x, i) => ({
                                    key: x.id,
                                    cells: [
                                        { content: renderTooltipWrapper(x.projectName, i) },
                                        { content: renderTooltipWrapper(x.responsibleName, i) },
                                        { content: renderTooltipWrapper(x.customerName, i) },
                                        { content: renderTooltipWrapper(x.countryName, i) },
                                        { content: renderTooltipWrapper(x.city, i) },
                                        { content: renderTooltipWrapper(x.sapNr, i) },
                                        { content: renderTooltipWrapper(x.lockedFrom?.toString() ?? '', i) }
                                    ],
                                    onClick: () => handleProjectClick(x.id)
                                })) ?? []}
                            loading={lockedProjectsLoading}
                            indexSortedBy={getSortingIndex(lockedProjects, LOCKED_PROJECT_ORDERING_NAMES)}
                            reverse={isReverse(lockedProjects)}
                            onHeaderClick={i => onLockedProjectsOrdering(LOCKED_PROJECT_ORDERING_NAMES[i], getOrderType(lockedProjects, LOCKED_PROJECT_ORDERING_NAMES[i]))}
                                hoverable
                            />
                            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={marginStyle}>
                                <Pagination activePage={(lockedProjects?.pageNumber ?? 1) - 1} count={lockedProjects?.totalPages ?? 0} disabled={lockedProjectsLoading}
                                        onChange={onLockedProjectsPaging} />
                            </Flex>
                        </>
                    )}
                    {showRemindedProjects && (
                        <>
                        <h1>{translate(TRANSLATIONS.main.projectReminders)}</h1>
                            <Table
                                columns={[
                                { label: translate(TRANSLATIONS.main.projectName), min: '250px', max: '8fr', bold: true },
                                { label: translate(TRANSLATIONS.main.reminder), min: '880px', max: '3fr', bold: true }
                                ]}
                                rows={remindedProjects?.items.map((x, i) => ({
                                    key: x.id,
                                    cells: [
                                        { content: renderTooltipWrapper(x.projectName, i) },
                                        { content: renderTooltipWrapper(x.reminder ?? '', i) }
                                    ],
                                    onClick: () => handleProjectClick(x.id)
                                })) ?? []}
                            loading={remindedProjectsLoading}
                            indexSortedBy={getSortingIndex(remindedProjects, REMINDED_PROJECT_ORDERING_NAMES)}
                            reverse={isReverse(remindedProjects)}
                            onHeaderClick={i => onRemindedProjectsOrdering(REMINDED_PROJECT_ORDERING_NAMES[i], getOrderType(remindedProjects, REMINDED_PROJECT_ORDERING_NAMES[i]))}
                                hoverable
                            />
                            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={marginStyle}>
                                <Pagination activePage={(remindedProjects?.pageNumber ?? 1) - 1} count={remindedProjects?.totalPages ?? 0} onChange={onRemindedProjectsPaging} disabled={remindedProjectsLoading} />
                            </Flex>
                        </>
                    )}
                </SpacingContainer>
            </ContentContainer>
    );
};

export default Start;
