import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarType } from '../../components/common/Snackbar';
import { TRANSLATIONS } from '../../constants';
import { Api, apiCall } from '../../services';
import { addSnackbar } from '../../store';
import { useTranslate } from '../common';

export const useKafkaData = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const [isKafkaImportLoading, setIsKafkaImportLoading] = useState(false);

    const handleImportKafkaData = () => {
        setIsKafkaImportLoading(true);

        return apiCall(
            Api.importKafkaData(),
            async x => {
                setIsKafkaImportLoading(false);
                dispatch(addSnackbar({ text: `${translate(TRANSLATIONS.snackbarMessages.kafkaDataImported)} ${x.data.importedEntriesCount}`, type: SnackbarType.Success }));
            },
            async () => {
                setIsKafkaImportLoading(false);
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.kafkaDataImportFailed), type: SnackbarType.Error }));
            }
        );
    };

    return { isKafkaImportLoading, handleImportKafkaData };
};
