import { useDispatch } from 'react-redux';
import { Api, apiCall, fileDownload } from '../../../services';
import { TRANSLATIONS } from '../../../constants';
import { useTranslate } from '../../common';
import { addSnackbar, setProjectAttachments, setProjectLoading } from '../../../store';
import { SnackbarType } from '../../../components/common/Snackbar';

export const useProjectAttachment = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    const handleProjectAttachmentsGet = async (id: string) => {
        return apiCall(
            Api.getProjectAttachments(id),
            async x => {
                const projectAttachments = x.data;
                dispatch(setProjectAttachments(projectAttachments));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadProjectAttachments), type: SnackbarType.Error }));
            }
        );
    };

    const handleDeleteProjectAttachments = async (ids: string[], projectId: string) => {
        let success = false;

        dispatch(setProjectLoading(true));

        await apiCall(
            Api.deleteProjectAttachments(ids, projectId),
            async () => {
                success = true;
                await handleProjectAttachmentsGet(projectId);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotDeleteProjectAttachments), type: SnackbarType.Error }));
            }
        );

        dispatch(setProjectLoading(false));

        return success;
    };

    const handleSaveProjectAttachment = async (fileName: string, projectId: string, file: File) => {
        let success = false;
        const data = new FormData();
        data.append('File', file);
        data.append('FileName', fileName);
        data.append('ProjectId', projectId);

        dispatch(setProjectLoading(false));

        await apiCall(
            Api.saveProjectAttachment(data),
            async () => {
                success = true;
                await handleProjectAttachmentsGet(projectId);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotSaveProjectAttachment), type: SnackbarType.Error }));
            }
        );

        dispatch(setProjectLoading(false));

        return success;
    };

    const handleDownloadAttachment = async (fileName: string, projectId: string) => {
        await fileDownload(
            Api.downloadAttachment(fileName, projectId),
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotDownloadProjectAttachment), type: SnackbarType.Success }));
            },
            fileName
        );
    };

    return {
        onProjectAttachmentsGet: handleProjectAttachmentsGet,
        onDeleteProjectAttachments: handleDeleteProjectAttachments,
        onSaveProjectAttachment: handleSaveProjectAttachment,
        onDownloadAttachment: handleDownloadAttachment
    };
};
