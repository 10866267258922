import react from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../models';

export interface UserState {
    roles: Role[];
    authCallFinished: boolean;
}

const initialState: UserState = {
    roles: [],
    authCallFinished: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthCallFinished: (state: UserState, action: PayloadAction<boolean>) => {
            state.authCallFinished = action.payload;
        },
        setUserRoles: (state: UserState, action: PayloadAction<Role[]>) => {
            state.roles = action.payload;
        }
    }
});

export const { setAuthCallFinished, setUserRoles } = userSlice.actions;
export const userReducer = userSlice.reducer;
