import React from 'react';
import { RefObject } from 'react';
import { Volume } from '../../../models';
import { MAX_INTEGER, NUMBER_FORMAT_WITH_SEPARATOR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useProjectClosed } from '../../../hooks/application';
import Button, { ButtonType } from '../../common/Button';
import Icon, { IconType } from '../../common/Icon';
import NumberInput from '../../common/NumberInput';
import Select from '../../common/Select';
import Table, { Column, Row } from '../../common/Table';

export interface VolumeTableProps {
    columns: Column[];
    handleChange: (value: Partial<Volume>, index: number) => void;
    mainTableRef: RefObject<HTMLDivElement>;
    removeVolume: (index: number) => void;
}

const VolumeTable = ({ columns, handleChange, mainTableRef, removeVolume }: VolumeTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const closed = useProjectClosed();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    return (
        <Table
            tableRef={mainTableRef}
            scrollId={SCROLL_ID.VOLUME}
            noScrollbar
            columns={columns}
            rows={[
                ...(project?.volumes ?? []).map<Row>((x, index) => {
                    const fiscalYear = availableValues.fiscalYears.find(y => y.id === x.fiscalYearId);
                    const fiscalYears = availableValues.fiscalYears.filter(y => !y.closed && !project?.volumes?.map(z => z.fiscalYearId).includes(y.id));
                    const isDisabled = closed || fiscalYear?.closed;

                    return {
                        key: x.id ?? x.key ?? index,
                        cells: [
                            {
                                content: <Select
                                    label={translate(TRANSLATIONS.main.fy)}
                                    key={`${index} fiscalYear`}
                                    value={fiscalYear}
                                    values={fiscalYears}
                                    mapToString={y => y?.name ?? ''}
                                    onSelect={y => handleChange({ fiscalYearId: y?.id }, index)}
                                    disabled={isDisabled}
                                    required
                                    error={!x.fiscalYearId}
                                />,
                                inputStyle: true
                            },
                            {
                                content: <NumberInput
                                    label={translate(TRANSLATIONS.main.totalVolume)}
                                    key={`${index} totalSales`}
                                    name='totalSales'
                                    value={x.totalSales?.toString() ?? ''}
                                    onChange={y => handleChange({ totalSales: Number(y) }, index)}
                                    disabled={isDisabled}
                                    maxValue={MAX_INTEGER}
                                    numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                    tooltipId={`total_sales_${x.id?.toString()}`}
                                />,
                                inputStyle: true
                            },
                            {
                                content: <NumberInput
                                    label={`${project?.country?.name} ${translate(TRANSLATIONS.main.volume)}`}
                                    key={`${index} counrySales`}
                                    name='countrySales'
                                    value={x.countrySales?.toString() ?? ''}
                                    onChange={y => handleChange({ countrySales: Number(y) }, index)}
                                    maxValue={MAX_INTEGER}
                                    numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                    tooltipId={`country_sales_${x.id?.toString()}`}
                                    disabled={isDisabled}
                                />,
                                inputStyle: true
                            },
                            {
                                content: <NumberInput
                                    label={translate(TRANSLATIONS.main.softwareVolume)}
                                    key={`${index} softwareSales`}
                                    name='softwareSales'
                                    value={x.softwareSales?.toString() ?? ''}
                                    onChange={y => handleChange({ softwareSales: Number(y) }, index)}
                                    maxValue={MAX_INTEGER}
                                    numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                    tooltipId={`software_sales_${x.id?.toString()}`}
                                    disabled={isDisabled}
                                />,
                                inputStyle: true
                            },
                            {
                                content: <NumberInput
                                    label={translate(TRANSLATIONS.main.epowertrainVolume)}
                                    key={`${index} ePowertrainSales`}
                                    name='ePowertrainSales'
                                    value={x.ePowertrainSales?.toString() ?? ''}
                                    onChange={y => handleChange({ ePowertrainSales: Number(y) }, index)}
                                    maxValue={MAX_INTEGER}
                                    numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                    tooltipId={`e_powertrain_sales_${x.id?.toString()}`}
                                    disabled={isDisabled}
                                />,
                                inputStyle: true
                            },
                            {
                                content: <Button
                                    key={`${index} remove`}
                                    type={ButtonType.Tertiary}
                                    onClick={() => removeVolume(index)}
                                    disabled={isDisabled}
                                >
                                    <Icon type={IconType.Trash} />
                                </Button>,
                                inputStyle: true
                            }
                        ]
                    };
                }),
                { key: 'divider', divider: true, cells: [] }
            ]}
        />
    );
};

export default VolumeTable;
