import { Tree } from '../models';
import { ProjectStatus } from '../enums';

export const PROJECT_STATUSES: Tree<ProjectStatus> = {
    value: ProjectStatus.Intern,
    children: [{
        value: ProjectStatus.Info,
        children: [{
            value: ProjectStatus.Discuss,
            children: [{
                value: ProjectStatus.Order,
                children: [{
                    value: ProjectStatus.Production,
                    children: [{
                        value: ProjectStatus.Closed,
                        children: []
                    }]
                }]
            }, {
                value: ProjectStatus.Lost,
                children: []
            }, {
            value: ProjectStatus.Cancelled,
                children: []
            }]
        }]
    }]
};
