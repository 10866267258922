import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
    children: ReactNode;
    id: string;
    text: string;
    delayShow?: number;
    hidden?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left'
    hide?: boolean;
}

const Tooltip = ({ children, delayShow, hidden, id, place, text, hide }: TooltipProps) => !hide
    ? <div className='tooltip' data-tooltip-id={id} data-tooltip-html={text}>
        {children}
        <ReactTooltip id={id} place={place} delayShow={delayShow ?? 0} delayHide={200} style={{ backgroundColor: '#000028' }} hidden={hidden} />
    </div>
    : <>{children}</>;

export default Tooltip;
