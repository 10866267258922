import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TRANSLATIONS } from '../../../../constants';
import { HeaderTabType } from '../../../../enums';
import { useTranslate } from '../../../../hooks/common';
import { setActiveTabType } from '../../../../store';
import ContentContainer from '../../../common/ContentContainer';

const Unauthorized = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Home));
    }, []);

    return (
        <ContentContainer>
            <h1>{translate(TRANSLATIONS.helperPages.unauthorizedMessage)}</h1>
        </ContentContainer>
    );
};

export default Unauthorized;
