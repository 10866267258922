import { useDispatch } from 'react-redux';
import { useAppSelector } from '../common';
import { incrementKeyCounter } from '../../store';

export const useUniqueKey = () => {
    const dispatch = useDispatch();
    const uniqueIndex = useAppSelector(state => state.keyCounter.counter);

    const getUniqueKey = (): string => {
        const uniqueKey = `uniqueKey-${uniqueIndex}`;
        dispatch(incrementKeyCounter());
        
        return uniqueKey;
    };

    return {
        getUniqueKey
    };
    
};
