import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { CUSTOMER_COUNTRY, CUSTOMER_NAME, EMPTY_CUSTOMER, TRANSLATIONS } from '../../../constants';
import { Company } from '../../../models';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { setProjectHeaderDisabled } from '../../../store';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Modal from '../../common/Modal';
import Search from '../../common/Search';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';
import Title from '../../common/Title';

interface newCustomerModalProps {
    customer: Company;
    handleCustomerChange: (customer: Partial<Company>) => void;
    renderSaveTooltip: (id: string, children: ReactElement, missingMandatoryFields: string[]) => JSX.Element;
    setCustomer: (customer: Company) => void;
    showNewCustomerModal: boolean;
    setShowNewCustomerModal: (showModal: boolean) => void;
}

const NewCustomerModal = ({ customer, handleCustomerChange, renderSaveTooltip, showNewCustomerModal, setCustomer, setShowNewCustomerModal }: newCustomerModalProps) => {
    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const translate = useTranslate();
    const { onCompanySave } = useHook(x => x.projectSave)();

    const emptyCustomerMandatoryFields = [
        ...customer && customer.name.length && customer.name !== '-' ? [] : [CUSTOMER_NAME],
        ...customer && customer.countryId != null ? [] : [CUSTOMER_COUNTRY]
    ];

    return (
        <Modal open={showNewCustomerModal} movable>
            <Title>{translate(TRANSLATIONS.main.createNewCustomer)}</Title>
            <div className='general'>
                <div className='form-group'>
                    <div className='length-12'>
                        <TextInput label={translate(TRANSLATIONS.main.customerName)} name='name' value={customer.name} required error={!customer.name} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.street)} name='street' value={customer.street} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.ifaNo)} name='ifaNo' value={customer.ifaNo} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.postalCode)} name='postcode' value={customer.postcode} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <Search
                            label={translate(TRANSLATIONS.main.city)}
                            searchValue={customer?.city ?? ''}
                            mapToString={x => x}
                            results={availableValues.customerCities.filter(x => x?.toLowerCase().includes(customer.city.toLowerCase()) ?? x)}
                            onSelect={x => handleCustomerChange({ city: x ?? '' })}
                            onChange={x => handleCustomerChange({ city: x ?? '' })}
                        />
                    </div>
                    <div className='length-6'>
                        <Select
                            label={translate(TRANSLATIONS.main.country)}
                            value={customer?.country}
                            values={availableValues.countries}
                            required
                            mapToString={x => x?.name ?? ''}
                            onSelect={x => handleCustomerChange({ country: x, countryId: x?.id })}
                            error={!customer?.country}
                            onReset={() => handleCustomerChange({ country: undefined, countryId: undefined })}
                        />
                    </div>
                    <div className='length-6'>
                        <Search
                            label={translate(TRANSLATIONS.main.group)}
                            searchValue={customer?.group ?? ''}
                            mapToString={x => x}
                            results={availableValues.customerGroups.filter(x => x?.toLowerCase().includes(customer?.group ?? ''.toLowerCase()) ?? x)}
                            onSelect={x => handleCustomerChange({ group: x ?? '' })}
                            onChange={x => handleCustomerChange({ group: x ?? '' })}
                        />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.poBox)} name='mailbox' value={customer.mailbox} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.poBoxPostalCode)} name='mailboxPostcode' value={customer.mailboxPostcode} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                    <div className='length-12'>
                        <TextInput label={translate(TRANSLATIONS.main.remark)} name='comment' value={customer.comment ?? ''} onChange={(x, y) => handleCustomerChange({ [y]: x })} />
                    </div>
                </div>
            </div>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                <Button
                    type={ButtonType.Tertiary}
                    onClick={() => {
                        setShowNewCustomerModal(false);
                        dispatch(setProjectHeaderDisabled(false));
                        setCustomer(EMPTY_CUSTOMER);
                    }}
                >
                    {translate(TRANSLATIONS.main.cancel)}
                </Button>
                {renderSaveTooltip(
                    'saveCustomerTooltip',
                    <Button
                        type={ButtonType.Primary}
                        onClick={() => {
                            setShowNewCustomerModal(false);
                            onCompanySave(customer);
                            dispatch(setProjectHeaderDisabled(false));
                            setCustomer(EMPTY_CUSTOMER);
                        }}
                        disabled={Boolean(emptyCustomerMandatoryFields.length)}
                    >
                        {translate(TRANSLATIONS.main.saveCustomer)}
                    </Button>,
                    emptyCustomerMandatoryFields
                )}
            </Flex>
        </Modal>
    );
};

export default NewCustomerModal;
