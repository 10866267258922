import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ADMIN, COMMERCIAL, USER } from './ts/constants/UserRoles';
import Layout from './ts/components/layout/Layout';
import Home from './ts/components/application/helperPages/Home';
import ErrorPage from './ts/components/application/helperPages/Error';
import Unauthorized from './ts/components/application/helperPages/authentication/Unauthorized';
import Start from './ts/components/application/Start';
import GeneralTab from './ts/components/application/General/GeneralTab';
import VolumeOverviewTab from './ts/components/application/VolumeOverview/VolumeOverviewTab';
import CostCalculationTab from './ts/components/application/CostCalculation/CostCalculationTab';
import CostYearsTab from './ts/components/application/CostYears/CostYearsTab';
import VolumeTab from './ts/components/application/Volume/VolumeTab';
import OrdersTab from './ts/components/application/Orders/OrdersTab';
import CostYearsCountries from './ts/components/application/CostYearsCountries/CostYearsCountriesTab';
import History from './ts/components/application/History/HistoryTab';
import Project from './ts/components/application/Project/Project';
import RequireAuth from './RequireAuth';
import StartSwitch from './StartSwitch';
import ReportsTab from './ts/components/application/Reports/ReportsTab';
import Login from './ts/components/application/helperPages/Login';
import Administration from './ts/components/application/Administration';
import Authenticate from './Authenticate';
import ProjectAttachmentsTab from './ts/components/application/ProjectAttachments/ProjectAttachmentsTab';

const routes = (
    <Routes>
        <Route path='/*' element={<Layout><Authenticate ><Outlet /></Authenticate></Layout>}>
            <Route path='project/:id' element={<RequireAuth roles={[ADMIN, COMMERCIAL, USER]}><Project /></RequireAuth>}>
                <Route index element={<GeneralTab />} />
                <Route path='volume-overview' element={<VolumeOverviewTab />} />
                <Route path='volumes' element={<VolumeTab />} />
                <Route path='cost-calculation' element={<CostCalculationTab />} />
                <Route path='cost-years' element={<CostYearsTab />} />
                <Route path='cost-years-countries' element={<CostYearsCountries />} />
                <Route path='history' element={<History />} />
                <Route path='orders' element={<OrdersTab />} />
                <Route path='attachments' element={<ProjectAttachmentsTab />} />
            </Route>
            <Route path='unauthorized' element={<Unauthorized />} />
            <Route path='error' element={<ErrorPage />} />
            <Route path='*' element={<StartSwitch start={<RequireAuth roles={[ADMIN, COMMERCIAL, USER]}><Start /></RequireAuth>}><Home /></StartSwitch>} />
            <Route path='reports' element={<RequireAuth roles={[ADMIN, COMMERCIAL, USER]}><ReportsTab /></RequireAuth>} />
            <Route path='administration' element={<RequireAuth roles={[ADMIN]}><Administration /></RequireAuth>} />
            <Route path='login' element={<Login />} />
        </Route>
    </Routes>
);

export default routes;
