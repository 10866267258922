import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { convertNumberToLocal, getScrollToElement } from '../../../services';
import { SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { useHook } from '../../../hooks';
import { useProjectClosed } from '../../../hooks/application';
import { useAppSelector, useForceUpdate, useScrolledPast, useTranslate } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';
import VolumeTable from './VolumeTable';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Sticky from '../../common/Sticky';
import Table from '../../common/Table';
import TextInput from '../../common/TextInput';

const VolumeTab = () => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const closed = useProjectClosed();
    const mainTabledRef = useRef<HTMLDivElement>(null);
    const scrolledPastHeader = useScrolledPast(getScrollToElement(mainTabledRef));
    const forceUpdate = useForceUpdate();
    const { addNewVolume, columns, getTotalCosts, getUnassignedCalculation, handleChange, removeVolume } = useHook(x => x.volume)();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Volumes));
        forceUpdate(); // A rerender is necessary after mounting to set the real value of the table reference
    }, []);

    return (
        <div style={{ marginBottom: 60, backgroundColor: 'inherit' }}>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 20 }}>
                <TextInput
                    label={translate(TRANSLATIONS.main.costsPlan)}
                    key='Costs'
                    name='costs'
                    value={convertNumberToLocal(getTotalCosts(project?.costsPerYears ?? []))}
                    onChange={() => {}}
                    disabled
                />
                <Button type={ButtonType.Primary} onClick={addNewVolume} disabled={closed}>Create new line</Button>
            </Flex>
            <ScrollSync>
                <>
                    <VolumeTable
                        columns={columns}
                        handleChange={handleChange}
                        mainTableRef={mainTabledRef}
                        removeVolume={removeVolume}
                    />
                    <Sticky anchor='bottom'>
                        <Table
                            scrollId={SCROLL_ID.VOLUME}
                            noHeader={!scrolledPastHeader}
                            columns={columns}
                            rows={getUnassignedCalculation(project?.volumes, project?.volumeOverviews)}
                        />
                    </Sticky>
                </>
            </ScrollSync>
        </div>
    );
};

export default VolumeTab;
