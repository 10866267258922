import { ServiceCategory } from '../models';
import { EMPTY_SERVICE_CATEGORY_NAME } from './EmptyServiceCategoryName';

export const EMPTY_SERVICE_CATEGORY: ServiceCategory = {
    id: 0,
    index: 0,
    isNameEditable: false,
    quantity: 0,
    quantityUnit: 3,
    serviceCategoryName: EMPTY_SERVICE_CATEGORY_NAME,
    translationId: ''
};
