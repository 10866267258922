import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { hooks } from './ts/hooks';
import { store } from './ts/store/store';
import routes from './routes';
import { HookProvider } from './ts/providers';
import 'rc-slider/assets/index.css';
import './css/styles.css';
import MsalWrapper from './ts/components/application/MsalWrapper';

const container = document.getElementById('root') ?? new HTMLElement;
const root = createRoot(container);

if (window.location.pathname.indexOf('undefined') !== -1) {
    window.location.pathname = window.location.pathname.replace('undefined', '');
}

root.render(
    <MsalWrapper >
        <Provider store={store}>
            <HookProvider hooks={hooks}>
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            </HookProvider>
        </Provider>
    </MsalWrapper>
);
