import React from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarType } from '../../components/common/Snackbar';
import { TRANSLATIONS, USER } from '../../constants';
import { FiscalYear } from '../../models';
import { Api, apiCall } from '../../services';
import { addSnackbar, setCurrentFiscalYear, setFiscalYears } from '../../store';
import { useAppSelector, useTranslate } from '../common';

export const useFiscalYears = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const userRoles = useAppSelector(state => state.user.roles);
    const currentFiscalYear = useAppSelector(state => state.fiscalYears.currentFiscalYear);
    const fiscalYears = useAppSelector(state => state.fiscalYears.fiscalYears);

    const closingFiscalYearRestriction = () => {
        let restrictUserRights = false;
        if (currentFiscalYear != null ) {
            restrictUserRights = currentFiscalYear.closing && userRoles.some(x => x.name == USER);
        }

        return restrictUserRights;
    };

    const handleCurrentFiscalYearGet = () => {
        return apiCall(
            Api.getCurrentFiscalYear(),
            async x => {
                dispatch(setCurrentFiscalYear(x.data));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGetCurrentFiscalYear), type: SnackbarType.Error }));
            }
        );
    };

    const handleFiscalYearsGet = () => {
        return apiCall(
            Api.getFiscalYears(),
            async x => {
                dispatch(setFiscalYears(x.data));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGetFiscalYears), type: SnackbarType.Error }));
            }
        );
    };

    const handleFiscalYearSave = (fiscalYear: FiscalYear) => {
        return apiCall(
            Api.saveFiscalYear(fiscalYear),
            async x => {
                fiscalYears && fiscalYears.length
                    ? dispatch(setFiscalYears([...fiscalYears.map(y => y.id === x.data.id ? x.data : y)]))
                    : handleFiscalYearsGet();
                handleCurrentFiscalYearGet();
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.fiscalYearSaved), type: SnackbarType.Success }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotSaveFiscalYear), type: SnackbarType.Error }));
            }
        );
    };

    return {
        closingFiscalYearRestriction,
        handleCurrentFiscalYearGet,
        handleFiscalYearsGet,
        handleFiscalYearSave
    };
};
