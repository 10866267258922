import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrderType, getSortingIndex, isReverse } from '../../../services';
import { SEARCHED_PROJECT_ORDERING_NAMES, TRANSLATIONS } from '../../../constants';
import { ProjectStatus } from '../../../enums';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Grid from '../../common/Grid';
import Multiselect from '../../common/Multiselect';
import Pagination from '../../common/Pagination';
import ResponsiveElement from '../../common/ResponsiveElement';
import Table from '../../common/Table';
import TextInput from '../../common/TextInput';
import Tooltip from '../../common/Tooltip';

interface ProjectSearchProps {
    onProjectClick: (id: string) => void;
    hideNewButton?: boolean;
}

const ProjectSearch = ({ hideNewButton, onProjectClick }: ProjectSearchProps) => {
    const navigate = useNavigate();
    const filterValues = useAppSelector(state => state.project.filterValues);
    const translate = useTranslate();
    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const timeout = 500;
    const randomMultiplier = 10000;
    const { searchedProjects, searchedProjectsLoading, onFilterChange, onSearch, onSearchedProjectsOrdering, onSearchedProjectsPaging, onStatusChange } = useHook(x => x.projectSearch)();

    const handleFilterChange = (value: string, name: string) => {
        onFilterChange({ [name]: value });
        timeoutRef.current && clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
            onSearch({ ...filterValues, [name]: value });
        }, timeout);
    };

    const handleSelect = (status: ProjectStatus) => {
        onStatusChange(filterValues.statuses.includes(status) ? filterValues.statuses.filter(x => x !== status) : [...filterValues.statuses, status]);
    };

    const handleNewProjectClick = () => navigate('/project/new');

    const renderTooltipWrapper = (text: string, index: number) => <Tooltip key={text} id={`${Math.floor(Math.random() * randomMultiplier)}_${index}`} delayShow={750} text={text}>{text}</Tooltip>;

    return (
        <>
            <div style={{ marginTop: 40, marginBottom: 40 }}>
                <Grid columns={4} columnsMedium={2} columnsVerySmall={1} columnGap={15} rowGap={15}>
                    <Multiselect
                        label={translate(TRANSLATIONS.main.projectStatus)} values={Object.values(ProjectStatus).filter(x => typeof x === 'number') as ProjectStatus[]}
                        selected={x => filterValues.statuses.includes(x)} mapToString={x => ProjectStatus[x]} onSelect={handleSelect} onReset={() => onStatusChange([])}
                    />
                    <ResponsiveElement responsiveStyles={[{ rule: 'max', style: { display: 'none' }, treshold: 'medium' }]} />
                    <ResponsiveElement responsiveStyles={[{ rule: 'max', style: { display: 'none' }, treshold: 'medium' }]} />
                    <ResponsiveElement style={{ display: 'grid' }} responsiveStyles={[{ rule: 'max', style: { gridRow: 1 }, treshold: 'verySmall' }]}>
                        {!hideNewButton &&
                            <Button type={ButtonType.Primary} onClick={handleNewProjectClick}>New Project</Button>
                        }
                    </ResponsiveElement>
                    <TextInput label={translate(TRANSLATIONS.main.project)} name='projectName' value={filterValues.projectName} onChange={handleFilterChange} />
                    <TextInput label={translate(TRANSLATIONS.main.responsible)} name='responsibleName' value={filterValues.responsibleName} onChange={handleFilterChange} />
                    <TextInput label={translate(TRANSLATIONS.main.customer)} name='customerName' value={filterValues.customerName} onChange={handleFilterChange} />
                    <TextInput label={translate(TRANSLATIONS.main.sapNo)} name='sapNr' value={filterValues.sapNr} onChange={handleFilterChange} />
                </Grid>
            </div>
            <Table
                columns={[
                    { label: translate(TRANSLATIONS.main.projectName), min: '250px', max: '8fr', bold: true },
                    { label: translate(TRANSLATIONS.main.responsible), min: '180px', max: '4fr', bold: true },
                    { label: translate(TRANSLATIONS.main.sapCustomer), min: '180px', max: '4fr', bold: true },
                    { label: translate(TRANSLATIONS.main.sapCountry), min: '150px', max: '4fr', bold: true },
                    { label: translate(TRANSLATIONS.main.projectLocation), min: '150px', max: '4fr', bold: true },
                    { label: translate(TRANSLATIONS.main.sapNo), min: '110px', max: '3fr', bold: true },
                    { label: translate(TRANSLATIONS.main.status), min: '110px', max: '3fr', bold: true }
                ]}
                rows={searchedProjects?.items.map((x, i) => ({
                    key: x.id,
                    cells: [
                        { content: renderTooltipWrapper(x.projectName, i) },
                        { content: renderTooltipWrapper(x.responsibleName, i) },
                        { content: renderTooltipWrapper(x.customerName, i) },
                        { content: renderTooltipWrapper(x.countryName, i) },
                        { content: renderTooltipWrapper(x.city, i) },
                        { content: renderTooltipWrapper(x.sapNr, i) },
                        { content: renderTooltipWrapper(ProjectStatus[x.projectStatus], i) }
                    ],
                    onClick: () => onProjectClick(x.id)
                })) ?? []}
                loading={searchedProjectsLoading}
                indexSortedBy={getSortingIndex(searchedProjects, SEARCHED_PROJECT_ORDERING_NAMES)}
                reverse={isReverse(searchedProjects)}
                onHeaderClick={i => onSearchedProjectsOrdering(SEARCHED_PROJECT_ORDERING_NAMES[i], getOrderType(searchedProjects, SEARCHED_PROJECT_ORDERING_NAMES[i]))}
                hoverable
            />
            <Flex direction={FlexDirection.Row} justification={FlexJustification.Center} style={{ marginTop: 40, marginBottom: 40 }}>
                <Pagination activePage={(searchedProjects?.pageNumber ?? 1) - 1} count={searchedProjects?.totalPages ?? 0} disabled={searchedProjectsLoading}
                    onChange={onSearchedProjectsPaging} />
            </Flex>
        </>
    );
};

export default ProjectSearch;
