import React, { CSSProperties, ReactNode } from 'react';

interface TitleProps {
    children?: ReactNode;
    style?: CSSProperties;
}

const Title = ({ children, style }: TitleProps) => <div style={{ fontSize: 26, fontWeight: 700, ...style }}>{children}</div>;

export default Title;
