import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CREATION_DATE_FROM, CREATION_DATE_TO, DECISION_DATE_FROM, DECISION_DATE_TO, EMPTY_DATE, REPORTING_LAYOUT, TRANSLATIONS } from '../../../constants';
import { Continent, HeaderTabType, ProjectCategory, ProjectStatusType } from '../../../enums';
import { useHook } from '../../../hooks';
import { Layout, LayoutColumn, LayoutSettingsTemplate, ReportRequest } from '../../../models';
import { getAllEnumValues, isDateValid, toggleListElement } from '../../../services/Utilities';
import Button, { ButtonType } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import ContentContainer from '../../common/ContentContainer';
import DateInput from '../../common/DateInput';
import Flex, { FlexDirection, FlexJustification, FlexWrap } from '../../common/Flex';
import Grid from '../../common/Grid';
import Modal from '../../common/Modal';
import MutableList, { List, ListItem, ListLocation } from '../../common/MutableList';
import Loader from '../../common/Loader';
import Multiselect from '../../common/Multiselect';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';
import Title from '../../common/Title';
import Tooltip from '../../common/Tooltip';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';

interface ColumnList {
    columnIds: number[];
    id: string;
    name: string;
    onChange: (ids: number[]) => void;
}

const ReportsTab = () => {
    const dispatch = useDispatch();
    const modalLoading = useAppSelector(state => state.report.reportModalLoading);
    const pageLoading = useAppSelector(state => state.report.reportPageLoading);
    const translate = useTranslate();
    const { availableValues, onAvailableValuesLoad } = useHook(x => x.availableValues)();
    const { layouts, onLayoutsLoad, onLayoutSettingsTemplateCreate, onLayoutSettingsTemplateDelete, onLayoutSettingsTemplateUpdate } = useHook(x => x.layouts)();
    const { onGenerateReport } = useHook(x => x.documentGeneration)();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [layoutSettingsTemplateId, setLayoutSettingsTemplateId] = useState<number | undefined>(undefined);
    const [layoutSettingsTemplateName, setLayoutSettingsTemplateName] = useState('');
    const [layoutSettingsTemplateShared, setLayoutSettingsTemplateShared] = useState(false);
    const [saveAsNew, setSaveAsNew] = useState(false);
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [request, setRequest] = useState<ReportRequest>({});
    const [unselectedColumnIds, setUnselectedColumnIds] = useState<number[]>([]);
    type ObjectKey = keyof typeof TRANSLATIONS.layout;

    const selectedLayout = useMemo(() => {
        return layouts.find(x => x.id === request.layout?.id);
    }, [layouts, request.layout?.id]);

    const selectedLayoutSettingsTemplate = useMemo(() => {
        return selectedLayout?.settingsTemplates.find(x => x.id === layoutSettingsTemplateId);
    }, [layoutSettingsTemplateId, selectedLayout?.settingsTemplates]);

    const reportingMandatoryFields = [
        ...request && selectedLayout ? [] : [REPORTING_LAYOUT]
    ];
    const invalidFieldValues = [
        ...request && isDateValid(request.creationDateFrom) ? [] : [CREATION_DATE_FROM],
        ...request && isDateValid(request.creationDateTo) ? [] : [CREATION_DATE_TO],
        ...request && isDateValid(request.decisionDateFrom) ? [] : [DECISION_DATE_FROM],
        ...request && isDateValid(request.decisionDateTo) ? [] : [DECISION_DATE_TO]
    ];
    const datesToBeCleared = [
        'creationDateFrom',
        'creationDateTo',
        'decisionDateFrom',
        'decisionDateTo'
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.Reports));
        onAvailableValuesLoad();
        onLayoutsLoad();
    }, []);

    const handleRequestChange = useCallback((value: Partial<ReportRequest>) => {
        setRequest(x => ({ ...x, ...value }));
    }, []);

    const handleRequestClear = () => {
        setRequest({});
        setLayoutSettingsTemplateId(undefined);
    };

    const handleGenerateReport = () => {
        onGenerateReport(getClearedRequest(request));
    };

    const getClearedRequest = (reportRequest: ReportRequest) => {
        const cleanedRequest = { ...reportRequest };

        for (const date in datesToBeCleared) {
            if (cleanedRequest[date as keyof ReportRequest] === EMPTY_DATE) {
                delete cleanedRequest[date as keyof ReportRequest];
            }
        }

        return cleanedRequest;
    };

    const handleLayoutSelect = useCallback((layout: Layout | undefined) => {
        handleRequestChange({ layout, columnIds: layout?.columns.map(x => x.id) ?? [] });
        setLayoutSettingsTemplateId(undefined);
        setUnselectedColumnIds([]);
    }, [handleRequestChange]);

    const handleLayoutReset = useCallback(() => {
        handleRequestChange({ layout: undefined, columnIds: [] });
        setLayoutSettingsTemplateId(undefined);
        setUnselectedColumnIds([]);
    }, [handleRequestChange]);

    const handleLayoutSettingsTemplateSelect = useCallback((layoutSettingsTemplate: LayoutSettingsTemplate | undefined) => {
        handleRequestChange({ columnIds: layoutSettingsTemplate?.columnIds ?? [] });
        setLayoutSettingsTemplateId(layoutSettingsTemplate?.id);
        setUnselectedColumnIds(selectedLayout?.columns.map(x => x.id).filter(x => !layoutSettingsTemplate?.columnIds.includes(x)) ?? []);
    }, [handleRequestChange, selectedLayout?.columns]);

    const handleLayoutSettingsTemplateReset = useCallback(() => {
        handleRequestChange({ columnIds: selectedLayout?.columns.map(x => x.id) ?? [] });
        setLayoutSettingsTemplateId(undefined);
        setUnselectedColumnIds([]);
    }, [handleRequestChange, selectedLayout?.columns, setUnselectedColumnIds]);

    const columnLists = useMemo<ColumnList[]>(() => [
        {
            id: 'inTheReport',
            name: translate(TRANSLATIONS.main.inTheReport),
            columnIds: request.columnIds ?? [],
            onChange: (ids: number[]) => handleRequestChange({ columnIds: ids })
        },
        {
            id: 'notInTheReport',
            name: translate(TRANSLATIONS.main.notInTheReport),
            columnIds: unselectedColumnIds,
            onChange: (ids: number[]) => setUnselectedColumnIds(ids)
        }
    ], [handleRequestChange, request.columnIds, translate, unselectedColumnIds]);

    const mapToColumnSelectionList = useCallback((columnList: ColumnList): List => ({
        id: columnList.id,
        items: columnList.columnIds
            .map(x => selectedLayout?.columns.find(y => y.id === x))
            .filter<LayoutColumn>((x): x is LayoutColumn => Boolean(x))
            .map<ListItem>(x => ({ content: x.name, id: x.id.toString(), isDragDisabled: x.isMandatory })),
        name: columnList.name
    }), [selectedLayout?.columns]);

    const columnSelectionLists = useMemo(() => columnLists.map(mapToColumnSelectionList), [columnLists, mapToColumnSelectionList]);

    const handleColumnSelectionListChange = useCallback((id: string, source: ListLocation, destination: ListLocation) => {
        const getModifiedColumnIds = (listId: string, columnIds: number[]) => {
            const clearedColumnIds = source.id === listId
                ? columnIds.filter((_, i) => i !== source.index)
                : columnIds;

            const appendedColumnIds = destination.id === listId
                ? [...clearedColumnIds.slice(0, destination.index), Number(id), ...clearedColumnIds.slice(destination.index, clearedColumnIds.length + 1)]
                : clearedColumnIds;

            return appendedColumnIds;
        };

        columnLists.filter(x => [source.id, destination.id].includes(x.id)).forEach(x => x.onChange(getModifiedColumnIds(x.id, x.columnIds)));
    }, [columnLists]);

    const layoutSettingsTemplateChanged = useMemo(() => {
        const layoutSettingsTemplateUnselectedColumnIds = selectedLayout?.columns.map(x => x.id).filter(x => !selectedLayoutSettingsTemplate?.columnIds.includes(x));

        return selectedLayoutSettingsTemplate?.columnIds.some((x, i) => x !== (request.columnIds ?? [])[i])
            || layoutSettingsTemplateUnselectedColumnIds?.some(x => !unselectedColumnIds.includes(x));
    }, [selectedLayoutSettingsTemplate?.columnIds, request.columnIds, selectedLayout, unselectedColumnIds]);

    const deleteButtonDisabled = useMemo(() => !selectedLayoutSettingsTemplate || !selectedLayoutSettingsTemplate.isEditable, [selectedLayoutSettingsTemplate]);

    const saveButtonDisabled = useMemo(() => {
        return !selectedLayoutSettingsTemplate || !selectedLayoutSettingsTemplate.isEditable || !layoutSettingsTemplateChanged;
    }, [layoutSettingsTemplateChanged, selectedLayoutSettingsTemplate]);

    const saveAsNewButtonDisabled = useMemo(() => !selectedLayout, [selectedLayout]);

    const generateReportButtonDisabled = useMemo(() => {
        return !selectedLayout || Boolean(reportingMandatoryFields.length) || Boolean(invalidFieldValues.length);
    }, [invalidFieldValues.length, reportingMandatoryFields.length, selectedLayout]);

    const handleDeleteButtonClick = useCallback(() => {
        setDeleteModalOpen(true);
    }, []);

    const handleSaveButtonClick = useCallback(() => {
        setSaveAsNew(false);
        setSaveModalOpen(true);
        setLayoutSettingsTemplateName(selectedLayoutSettingsTemplate?.name ?? '');
        setLayoutSettingsTemplateShared(selectedLayoutSettingsTemplate?.shared ?? false);
    }, [selectedLayoutSettingsTemplate?.name, selectedLayoutSettingsTemplate?.shared]);

    const handleSaveAsNewButtonClick = useCallback(() => {
        setSaveAsNew(true);
        setSaveModalOpen(true);
        setLayoutSettingsTemplateName('');
        setLayoutSettingsTemplateShared(false);
    }, []);

    const handleDeleteModalClose = useCallback(() => {
        setDeleteModalOpen(false);
    }, []);

    const handleSaveModalClose = useCallback(() => {
        setSaveModalOpen(false);
    }, []);

    const handleSaveModalCancel = useCallback(() => {
        setSaveModalOpen(false);
    }, []);

    const handleSettingsTemplateDelete = useCallback(async () => {
        const success = await (selectedLayoutSettingsTemplate && onLayoutSettingsTemplateDelete(selectedLayoutSettingsTemplate.id));

        if (success) {
            handleLayoutSettingsTemplateReset();
            setDeleteModalOpen(false);
        }
    }, [handleLayoutSettingsTemplateReset, onLayoutSettingsTemplateDelete, selectedLayoutSettingsTemplate]);

    const handleSettingsTemplateSave = useCallback(async () => {
        const layoutSettingsTemplateCommon: Pick<LayoutSettingsTemplate, 'columnIds' | 'name' | 'shared'> = {
            columnIds: request.columnIds ?? [],
            name: layoutSettingsTemplateName,
            shared: layoutSettingsTemplateShared
        };

        const success = await (saveAsNew
            ? selectedLayout && onLayoutSettingsTemplateCreate({ ...layoutSettingsTemplateCommon, layoutId: selectedLayout.id })
            : selectedLayoutSettingsTemplate && onLayoutSettingsTemplateUpdate({ ...layoutSettingsTemplateCommon, id: selectedLayoutSettingsTemplate.id })
        );

        success && setSaveModalOpen(false);
    }, [selectedLayoutSettingsTemplate, layoutSettingsTemplateName, layoutSettingsTemplateShared, onLayoutSettingsTemplateCreate, onLayoutSettingsTemplateUpdate, request.columnIds, saveAsNew, selectedLayout]);

    const isLayoutSettingsTemplateNameUnique = useCallback((name: string) => {
        const layoutSettingsTemplateNames = layouts.flatMap(x => x.settingsTemplates).filter(x => saveAsNew || x.id !== selectedLayoutSettingsTemplate?.id).map(y => y.name);

        return !layoutSettingsTemplateNames.includes(name);
    }, [selectedLayoutSettingsTemplate?.id, layouts, saveAsNew]);

    const renderGenerateReportTooltip = (id: string, children: ReactElement, missingMandatoryFields: string[] = [], invalidFieldValues: string[] = []) => {
        type ObjectKey = keyof typeof TRANSLATIONS.main;
        const mandatoryFields = missingMandatoryFields.length ? `${translate(TRANSLATIONS.main.missingMandatoryFields)}: ${missingMandatoryFields.map(x => {
            const key = x as ObjectKey;

            return `<br />${translate(TRANSLATIONS.main[key])}`;
        }).join('').concat('<br />')}` : '';
        const invalidFileds = invalidFieldValues.length ? `${translate(TRANSLATIONS.main.invalidValueForFields)}: ${invalidFieldValues.map(x => {
            const key = x as ObjectKey;

            return `<br />${translate(TRANSLATIONS.main[key])}`;
        }).join('')}` : '';
        const text = `<div style = 'text-align: center' > ${mandatoryFields}${invalidFileds}</div>`;

        return <Tooltip key={text} id={id} delayShow={450} text={text} place='bottom' hide={!missingMandatoryFields.length && !invalidFieldValues.length} >{children}</Tooltip>;
    };

    const renderDeleteModal = () => (
        <Modal open={deleteModalOpen} onClose={handleDeleteModalClose} movable>
            <Loader loading={modalLoading} margin={10}>
                <Title style={{ marginBottom: 30 }}>{translate(TRANSLATIONS.main.deleteLayoutSettingsTemplateMessage)}</Title>
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Primary} onClick={handleSettingsTemplateDelete}>
                        {translate(TRANSLATIONS.main.yes)}
                    </Button>
                    <Button type={ButtonType.Secondary} onClick={handleDeleteModalClose}>
                        {translate(TRANSLATIONS.main.no)}
                    </Button>
                </Flex>
            </Loader>
        </Modal>
    );

    const renderSaveModal = () => (
        <Modal open={saveModalOpen} onClose={handleSaveModalClose} movable>
            <Loader loading={modalLoading} margin={10}>
                <Title style={{ marginBottom: 30 }}>Save settings template</Title>
                <Checkbox checked={layoutSettingsTemplateShared} label='Share settings template' onChange={setLayoutSettingsTemplateShared} />
                <TextInput
                    label='Settings template name'
                    name='settingsTemplateName'
                    value={layoutSettingsTemplateName}
                    onChange={setLayoutSettingsTemplateName}
                    error={!isLayoutSettingsTemplateNameUnique(layoutSettingsTemplateName)}
                    getMessage={x => !isLayoutSettingsTemplateNameUnique(x) ? 'Settings template name already exists' : ''}
                    style={{ marginTop: 20 }} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                    <Button type={ButtonType.Tertiary} onClick={handleSaveModalCancel}>
                        {translate(TRANSLATIONS.main.cancel)}
                    </Button>
                    <Button type={ButtonType.Primary} disabled={!layoutSettingsTemplateName || !isLayoutSettingsTemplateNameUnique(layoutSettingsTemplateName)} onClick={handleSettingsTemplateSave}>
                        {translate(TRANSLATIONS.main.saveSettings)}
                    </Button>
                </Flex>
            </Loader>
        </Modal>
    );

    return (
        <div>
            <ContentContainer style={{ paddingBottom: 40 }}>
                <Loader loading={pageLoading} margin={10}>
                    <Grid columns={4} columnGap={20} rowGap={40} style={{ marginTop: 40, marginBottom: 30 }}>
                        <Select
                            label='Reporting layout'
                            value={selectedLayout}
                            values={layouts}
                            mapToString={x => x ? translate(TRANSLATIONS.layout[x.name as ObjectKey]) : ''}
                            onSelect={handleLayoutSelect}
                            onReset={handleLayoutReset}
                            disabled={!layouts.length}
                            required
                        />
                        <Select
                            label='Layout settings template'
                            value={selectedLayoutSettingsTemplate}
                            values={selectedLayout?.settingsTemplates ?? []}
                            mapToString={x => x ? `${x.name} - ${x.ownerName}` : ''}
                            onSelect={handleLayoutSettingsTemplateSelect}
                            onReset={handleLayoutSettingsTemplateReset}
                            disabled={!selectedLayout?.settingsTemplates.length}
                        />
                        <div />
                        <div />

                        <div />
                        <MutableList lists={columnSelectionLists} onChange={handleColumnSelectionListChange} />
                        <div />

                        <Multiselect
                            label='Customer'
                            selected={x => (request.customers ?? []).includes(x)}
                            values={availableValues.customers}
                            mapToString={x => x.name}
                            mapToKey={x => x?.id?.toString() ?? ''}
                            onSelect={x => handleRequestChange({ customers: toggleListElement(request.customers ?? [], x ?? '') })}
                            onReset={() => handleRequestChange({ customers: undefined })}
                        />
                        <Multiselect
                            label='Responsible'
                            selected={x => (request.responsibles ?? []).includes(x)}
                            values={availableValues.contacts.filter(x => x.contactType === 1)}
                            mapToString={x => x.name}
                            mapToKey={x => `${x?.id}` ?? ''}
                            onSelect={x => handleRequestChange({ responsibles: toggleListElement(request.responsibles ?? [], x ?? '') })}
                            onReset={() => handleRequestChange({ responsibles: undefined })}
                        />
                        <Multiselect
                            label='Project Status'
                            selected={x => (request.projectStatuses ?? []).includes(x as ProjectStatusType)}
                            values={getAllEnumValues(ProjectStatusType)}
                            mapToString={x => x != null ? ProjectStatusType[x as ProjectStatusType] : ''}
                            onSelect={x => handleRequestChange({ projectStatuses: toggleListElement(request.projectStatuses ?? [], x ?? '') as ProjectStatusType[] })}
                            onReset={() => handleRequestChange({ projectStatuses: undefined })}
                        />
                        <Multiselect
                            label='Class'
                            selected={x => (request.classes ?? []).includes(x as ProjectCategory)}
                            values={getAllEnumValues(ProjectCategory)}
                            mapToString={x => x != null ? ProjectCategory[x as ProjectCategory] : ''}
                            onSelect={x => handleRequestChange({ classes: toggleListElement(request.classes ?? [], x ?? '') as ProjectCategory[] })}
                            onReset={() => handleRequestChange({ classes: undefined })}
                        />

                        <Select
                            label='SAP No.'
                            value={request.hasSapNo}
                            values={[true, false]}
                            mapToString={x => x != null ? x ? 'With SAP No.' : 'Without SAP No.' : ''}
                            filled={x => x != null}
                            onSelect={x => handleRequestChange({ hasSapNo: x })}
                            onReset={() => handleRequestChange({ hasSapNo: undefined })}
                        />
                        <Multiselect
                            label='Continent'
                            selected={x => (request.continents ?? []).includes(x as Continent)}
                            values={getAllEnumValues(Continent)}
                            mapToString={x => x != null ? Continent[x as Continent] : ''}
                            onSelect={x => handleRequestChange({ continents: toggleListElement(request.continents ?? [], x ?? '') as Continent[] })}
                            onReset={() => handleRequestChange({ continents: undefined })}
                        />
                        <Multiselect
                            label='Country'
                            selected={x => (request.countries ?? []).includes(x)}
                            values={availableValues.unfilteredCountries}
                            mapToString={x => x.name}
                            onSelect={x => handleRequestChange({ countries: toggleListElement(request.countries ?? [], x ?? '') })}
                            onReset={() => handleRequestChange({ countries: undefined })}
                        />
                        <Multiselect
                            label='Corp. group'
                            selected={x => (request.corporationGroup ?? []).includes(x)}
                            values={availableValues.corporationGroups}
                            mapToString={x => x.name}
                            onSelect={x => handleRequestChange({ corporationGroup: toggleListElement(request.corporationGroup ?? [], x ?? '') })}
                            onReset={() => handleRequestChange({ corporationGroup: undefined })}
                        />

                        <DateInput label='Creation Date from' value={request.creationDateFrom ?? EMPTY_DATE} error={!isDateValid(request.creationDateFrom)} onChange={x => handleRequestChange({ creationDateFrom: x })} />
                        <DateInput label='Creation Date to' value={request.creationDateTo ?? EMPTY_DATE} error={!isDateValid(request.creationDateTo)} onChange={x => handleRequestChange({ creationDateTo: x })} />
                        <DateInput label='Decision Date from' value={request.decisionDateFrom ?? EMPTY_DATE} error={!isDateValid(request.decisionDateFrom)} onChange={x => handleRequestChange({ decisionDateFrom: x })} />
                        <DateInput label='Decision Date to' value={request.decisionDateTo ?? EMPTY_DATE} error={!isDateValid(request.decisionDateTo)} onChange={x => handleRequestChange({ decisionDateTo: x })} />
                    </Grid>
                    <Flex justification={FlexJustification.FlexEnd} direction={FlexDirection.Row} wrap={FlexWrap.Wrap} gap={10}>
                        <Button type={ButtonType.Secondary} disabled={deleteButtonDisabled} onClick={handleDeleteButtonClick}>
                            Delete settings template
                        </Button>
                        <Button type={ButtonType.Secondary} disabled={saveButtonDisabled} onClick={handleSaveButtonClick}>
                            Save settings template
                        </Button>
                        <Button type={ButtonType.Secondary} disabled={saveAsNewButtonDisabled} onClick={handleSaveAsNewButtonClick}>
                            Save settings template as new
                        </Button>
                        <Button type={ButtonType.Primary} onClick={() => handleRequestClear()}>
                            Clear Filters
                        </Button>
                        {renderGenerateReportTooltip(
                            'generateReportTooltip',
                            <Button type={ButtonType.Primary} disabled={generateReportButtonDisabled} onClick={() => handleGenerateReport()}>
                                Generate Report
                            </Button>,
                            reportingMandatoryFields,
                            invalidFieldValues
                        )}
                    </Flex>
                </Loader>
            </ContentContainer>
            {renderDeleteModal()}
            {renderSaveModal()}
        </div>
    );
};

export default ReportsTab;
