import { HeaderTabType } from '../enums';
import { Tab } from '../components/layout/HeaderTabs';

export const TABS: Tab[] = [
    { title: 'Home', to: '/', type: HeaderTabType.Home }
];

export const PROTECTED_TABS: Tab[] = [
    { title: 'Start', to: '/', type: HeaderTabType.Start },
    { title: 'Reports', to: '/reports', type: HeaderTabType.Reports }
];

export const ADMIN_TABS: Tab[] = [
    { title: 'Administration', to: '/administration', type: HeaderTabType.Administration }
];

export const PROJECT_TABS: Tab[] = [
    { title: 'General', to: '/project/{id}', type: HeaderTabType.General },
    { title: 'Volume Overview', to: '/project/{id}/volume-overview', type: HeaderTabType.VolumeOverview },
    { title: 'Volumes', to: '/project/{id}/volumes', type: HeaderTabType.Volumes },
    { title: 'Cost Calculation', to: '/project/{id}/cost-calculation', type: HeaderTabType.CostCalculation },
    { title: 'Cost Years', to: '/project/{id}/cost-years', type: HeaderTabType.CostsPerYear },
    { title: 'Cost Years Countries', to: '/project/{id}/cost-years-countries', type: HeaderTabType.CostYearsCountries },
    { title: 'History', to: '/project/{id}/history', type: HeaderTabType.History },
    { title: 'Orders', to: '/project/{id}/orders', type: HeaderTabType.Orders },
    { title: 'Attachments', to: '/project/{id}/attachments', type: HeaderTabType.ProjectAttachments }
];
