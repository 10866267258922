import { BlobResponse } from '../models';
import { getAccessToken } from './Utilities';

export class ApiBase {
    protected static get = async (url: string) => {
        const response = await fetch(url, ApiBase.getFetchOptions('GET', undefined, undefined));

        return ApiBase.processResponse(response);
    };

    protected static getFile = async (url: string, contentType?: string): Promise<BlobResponse> => {
        const response = await fetch(url, ApiBase.getFetchOptions('GET', undefined, undefined));
        const processedResponse = await ApiBase.processResponse(response, true);
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // Regex for getting the filename from content-disposition, the necessary result will be in the first group capture.
        const matches = (response.headers.get('Content-Disposition') ?? '').match(filenameRegex);
        const fileName = matches != null && matches[1] 
            ? matches[1].replace(/['"]/g, '')
            : ''; 
        
        return { fileName, blob: processedResponse.slice(0, processedResponse.size, contentType) };
    };

    protected static post = async (url: string, body?: any, serializeBody = true, contentType = 'application/json') => {
        return ApiBase.request('POST', url, body, serializeBody, contentType);
    };

    protected static put = async (url: string, body?: any, serializeBody = true, contentType = 'application/json') => {
        return ApiBase.request('PUT', url, body, serializeBody, contentType);
    };

    protected static delete = async (url: string, body?: any, serializeBody = true, contentType = 'application/json') => {
        return ApiBase.request('DELETE', url, body, serializeBody, contentType);
    };

    protected static request = async (method: string, url: string, body?: any, serializeBody = true, contentType = 'application/json') => {
        const response = await fetch(url, ApiBase.getFetchOptions(method, contentType, body != undefined && serializeBody ? JSON.stringify(body) : body));

        return ApiBase.processResponse(response);
    };

    private static getFetchOptions = (method: string, contentType = 'application/json', body?: FormData | string | null): RequestInit => {
        const headers = new Headers();
        const accessToken = getAccessToken();
        
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${accessToken}`);

        if (contentType && contentType != 'none') {
            headers.append('Content-Type', contentType);
        }

        return { body, credentials: 'same-origin', headers, method };
    };

    private static processResponse = (response: Response, isBlob = false) => {
        if (!response.ok && response.status !== 403 && response.status !== 601) {
            throw response;
        }

        return isBlob ? response.blob() : response.json();
    };
}
