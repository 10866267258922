import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { trimAccents } from '../../../services';
import { EMPTY_CONTACT, EMPTY_CUSTOMER, EMPTY_DATE } from '../../../constants';
import { Company, Contact, Project } from '../../../models';
import { useProjectClosed } from '../Project/ProjectClosed';
import { useAppSelector } from '../../common';
import { setProject } from '../../../store';

export const useGeneral = () => {
    const dispatch = useDispatch();
    const { accounts } = useMsal();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const closed = useProjectClosed();
    const [contact, setContact] = useState(EMPTY_CONTACT);
    const [customer, setCustomer] = useState(EMPTY_CUSTOMER);
    const [showCreateNewContactModal, setShowCreateNewContactModal] = useState(false);
    const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);

    useEffect(() => {
        const loggedInResponsible = getLoggedInResponsible();
        project
            && project.hqCommercialResponsible?.id == null
            && loggedInResponsible
            && dispatch(setProject({ ...project, hqTechnicalResponsible: loggedInResponsible ?? EMPTY_CONTACT }));
    }, [accounts[0]]);

    const handleChange = (value: Partial<Project>) => project && dispatch(setProject({ ...project, ...value }));

    const handleCustomerChange = (value: Partial<Company>) => {
        customer && setCustomer({ ...customer, ...value });
        handleChange({ companyId: value.id });
    };

    const handleContactChange = (value: Partial<Contact>) => contact && setContact({ ...contact, ...value });

    const getDateValue = (dateString: string | undefined) => dateString ?? (closed ? '' : EMPTY_DATE);

    const getLoggedInResponsible = () => {
        return availableValues.contacts.find(x =>
                x.contactType === 1
                && accounts[0]
                && accounts[0]?.idTokenClaims?.family_name
                && trimAccents(x.lastName) === trimAccents(accounts[0]?.idTokenClaims?.family_name as string)
                && accounts[0]?.idTokenClaims?.given_name
                && trimAccents(x.firstName) === trimAccents(accounts[0]?.idTokenClaims?.given_name as string)
        );
    };

    return {
        closed,
        contact,
        setContact,
        customer,
        setCustomer,
        handleChange,
        handleCustomerChange,
        handleContactChange,
        getDateValue,
        showCreateNewContactModal,
        setShowCreateNewContactModal,
        showNewCustomerModal,
        setShowNewCustomerModal
    };
};
