import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Api, apiCall } from './ts/services';
import { setAuthCallFinished, setUserRoles } from './ts/store';

interface AuthorizeProps {
    children: ReactNode;
    msalTokenSet: boolean;
}

const Authorize = ({ children, msalTokenSet }: AuthorizeProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [authorized, setAuthorized] = useState(false);
    
    const handleAuthorization = useCallback(async () => await apiCall(
        Api.authorize(),
        async x => {
            dispatch(setUserRoles(x.data.roles));
            setAuthorized(true);
            dispatch(setAuthCallFinished(true));
        },
        async x => {
            if (x && x.hasError && x.messages.some(y => y === 'Forbidden')) {
                setAuthorized(false);
                navigate('/unauthorized');
            }
        }
    ), [dispatch, navigate]);

    useEffect(() => {
        if (isAuthenticated && !authorized && msalTokenSet && inProgress === 'none') {
            handleAuthorization();
        }
    }, [isAuthenticated, authorized, handleAuthorization, dispatch, accounts, inProgress, msalTokenSet]);


    return <>{children}</>;
};

export default Authorize;
