import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/common';
import { useProjectLoad } from '../../../hooks/application';
import ContentContainer from '../../common/ContentContainer';
import Loader from '../../common/Loader';

const Project = () => {
    const projectLoading = useAppSelector(state => state.project.projectLoading);
    useProjectLoad();

    return (
        <ContentContainer>
            <Loader loading={projectLoading} margin={10}>
                <Outlet />
            </Loader>
        </ContentContainer>
    );
};

export default Project;
