import { useDispatch } from 'react-redux';
import { EMPTY_COST_CALCULATION, EMPTY_SERVICE_CATEGORY } from '../../../constants';
import { CostCalculation } from '../../../models';
import { convertStringToLocal, convertToDecimalString, sum } from '../../../services';
import { useAppSelector } from '../../common';
import { setProject } from '../../../store';

export const useCostCalculation = () => {
    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);

    const costsTotalVolume = sum(project?.volumes ?? [], 'totalSales');
    const costsCalculated = sum(project?.costsCalculations ?? [], 'cost', 'quantity')
        + (project?.costsCalculations?.find(x => x.serviceCategory.index == 6)?.additionalValue ?? 0) * (project?.costsCalculations?.find(x => x.serviceCategory.index == 6)?.quantity ?? 0) * costsTotalVolume;
    const costsSupportedVolume = costsTotalVolume - sum(project?.volumes ?? [], 'softwareSales');
    const costsCalculatedOfSupportedVolume = convertStringToLocal((costsSupportedVolume ? costsCalculated / costsSupportedVolume * 100 : 0).toFixed(2));

    const isSpecialCostCalculation = (costCalculation: CostCalculation) => Boolean(costCalculation.serviceCategory.index === 6);

    const getFixedDigitTotalCostsCalculated = () => costsCalculated.toFixed(Math.min(3, costsCalculated.toString().split('.')[1]?.length ?? 0));

    const getRowTotalValue = (costCalculation: CostCalculation) => isSpecialCostCalculation(costCalculation)
        ? ((costCalculation.additionalValue ?? 0) * costsTotalVolume * costCalculation.quantity).toFixed(convertToDecimalString(costCalculation.additionalValue?.toString() ?? '0', 'percentage').split('.')[1]?.length ?? 0)
        : ((costCalculation.quantity ?? 0) * (costCalculation.cost ?? 0)).toFixed(convertToDecimalString(costCalculation.cost?.toString() ?? '0').split('.')[1]?.length ? 2 : 0);

    const handleChange = (value: Partial<CostCalculation>, serviceCategoryId: number) => {
        const serviceCategory = availableValues.serviceCategories.find(x => x.id == serviceCategoryId) ?? EMPTY_SERVICE_CATEGORY;
        const updatedCostCalculations = (project?.costsCalculations?.findIndex(x => x.serviceCategoryId == serviceCategoryId) ?? -1) >= 0
            ? [...project?.costsCalculations?.map((x, i) => project.costsCalculations?.findIndex(x => x.serviceCategoryId == serviceCategoryId) === i
                ? { ...x, ...value }
                : x
            ) ?? []]
            : [
                ...project?.costsCalculations ?? [],
                {
                    ...EMPTY_COST_CALCULATION,
                    serviceCategory: serviceCategory,
                    serviceCategoryName: serviceCategory.serviceCategoryName,
                    serviceCategoryNameId: serviceCategory.serviceCategoryNameId ?? 0,
                    serviceCategoryId: serviceCategoryId,
                    cost: serviceCategory.price ?? 0,
                    projectId: project?.id.length ? project?.id : undefined,
                    ...value
                }];

        project &&
            dispatch(setProject({
                ...project,
                costsCalculations: updatedCostCalculations
            }));
    };

    return {
        costsTotalVolume,
        costsCalculated,
        costsSupportedVolume,
        costsCalculatedOfSupportedVolume,
        getFixedDigitTotalCostsCalculated,
        getRowTotalValue,
        handleChange,
        isSpecialCostCalculation
    };
};
