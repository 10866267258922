import React, { CSSProperties, ReactNode } from 'react';

interface GridProps {
    children: ReactNode;
    columns: number;
    columnsBig?: number;
    columnsMedium?: number;
    columnsSmall?: number;
    columnsVerySmall?: number;
    columnGap?: number;
    rowGap?: number;
    style?: CSSProperties;
}

const Grid = ({ children, columns, columnsBig, columnsMedium, columnsSmall, columnsVerySmall, columnGap, rowGap, style }: GridProps) => {
    const getMedia = (maxWidth: number, columns: number | undefined) => columns ? `grid-columns-${maxWidth}-${columns}` : '';

    return (
        <div className={`grid-container grid-columns-${columns} ${getMedia(992, columnsBig)} ${getMedia(768, columnsMedium)} ${getMedia(600, columnsSmall)} ${getMedia(480, columnsVerySmall)}`}
            style={{ ...style, columnGap: columnGap ?? 0, rowGap: rowGap ?? 0 }}>
            {children}
        </div>
    );
};

export default Grid;
