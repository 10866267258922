import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { CONTACT_DEPARTMENT, CONTACT_FIRST_NAME, CONTACT_LAST_NAME, CONTACT_TYPE, EMPTY_CONTACT, TRANSLATIONS } from '../../../constants';
import { ContactType } from '../../../enums';
import { Contact } from '../../../models';
import { useHook } from '../../../hooks';
import { useTranslate } from '../../../hooks/common';
import { setProjectHeaderDisabled } from '../../../store';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Modal from '../../common/Modal';
import Search from '../../common/Search';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';
import Title from '../../common/Title';

export interface NewContactModalProps {
    contact: Contact;
    handleContactChange: (contact: Partial<Contact>) => void; 
    renderSaveTooltip: (id: string, children: ReactElement, missingMandatoryFields: string[]) => JSX.Element;
    setContact: (contact: Contact) => void;
    showCreateNewContactModal: boolean;
    setShowCreateNewContactModal: (showModal: boolean) => void;
}

const NewContactModal = ({ contact, setContact, handleContactChange, renderSaveTooltip, showCreateNewContactModal, setShowCreateNewContactModal }: NewContactModalProps) => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { onContactSave } = useHook(x => x.projectSave)();
    const dummySalutations = ['Dr.', 'Mr.', 'Mrs.', 'Ms'];

    const emptyContactMandatoryFields = [
        ...contact && contact.firstName.length ? [] : [CONTACT_FIRST_NAME],
        ...contact && contact.lastName.length ? [] : [CONTACT_LAST_NAME],
        ...contact && contact.department.length ? [] : [CONTACT_DEPARTMENT],
        ...contact && contact.contactType != null ? [] : [CONTACT_TYPE]
    ];

    return (
        <Modal open={showCreateNewContactModal} movable>
            <Title>{translate(TRANSLATIONS.main.createNewContactPerson)}</Title>
            <div className='general'>
                <div className='form-group'>
                    <div className='length-6'>
                        <Search
                            label={translate(TRANSLATIONS.main.salutation)}
                            searchValue={contact.salutation ?? ''}
                            mapToString={x => x}
                            results={dummySalutations.filter(x => x?.toLowerCase().includes(contact.salutation ?? ''.toLowerCase()) ?? x)}
                            onSelect={x => handleContactChange({ salutation: x })}
                            onChange={x => handleContactChange({ salutation: x })}
                        />
                    </div>
                    <div className='length-6'></div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.firstName)} name='firstName' required value={contact.firstName} error={!contact.firstName} onChange={(x, y) => handleContactChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.lastName)} name='lastName' required value={contact.lastName} error={!contact.lastName} onChange={(x, y) => handleContactChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <TextInput label={translate(TRANSLATIONS.main.department)} name='department' required value={contact.department} error={!contact.department} onChange={(x, y) => handleContactChange({ [y]: x })} />
                    </div>
                    <div className='length-6'>
                        <Select
                            label={translate(TRANSLATIONS.main.contactType)}
                            value={contact.contactType != null ? contact.contactType : ContactType.HeadquarterCommercial}
                            values={Object.values(ContactType).filter(x => typeof x === 'number') as ContactType[]}
                            mapToString={x => ContactType[x]}
                            onSelect={x => handleContactChange({ contactType: x })}
                            required
                            filled={x => x != null}
                        />
                    </div>
                </div>
            </div>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10} style={{ marginTop: 30 }}>
                <Button
                    type={ButtonType.Tertiary}
                    onClick={() => {
                        setShowCreateNewContactModal(false);
                        dispatch(setProjectHeaderDisabled(false));
                        setContact(EMPTY_CONTACT);
                    }}
                >
                    {translate(TRANSLATIONS.main.cancel)}
                </Button>
                {renderSaveTooltip(
                    'saveContactTooltip',
                    <Button
                        type={ButtonType.Primary}
                        onClick={() => {
                            setShowCreateNewContactModal(false);
                            onContactSave(contact);
                            dispatch(setProjectHeaderDisabled(false));
                            setContact(EMPTY_CONTACT);
                        }}
                        disabled={Boolean(emptyContactMandatoryFields.length)}
                    >
                        {translate(TRANSLATIONS.main.saveContact)}
                    </Button>,
                    emptyContactMandatoryFields
                )}
            </Flex>
        </Modal>
    );
};

export default NewContactModal;
