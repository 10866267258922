import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCostCalculations, getServiceName, getUniqueValues, sortBy } from '../../../services';
import { DEFAULT_SELECTED_FISCAL_YEAR, EMPTY_COUNTRY_COST_PER_YEAR, EMPTY_COUNTRY_COST_PER_YEAR_SERVICE, EMPTY_PROJECT } from '../../../constants';
import { FiscalYear, Project, CountryCostsPerYearWithOriginalIndex, CountryCostsPerYear, CountryCostsPerYearService, CostCalculation } from '../../../models';
import { useHook } from '../../Hooks';
import { useAppSelector } from '../../common';
import { setProject, setSelectedDropdownValues } from '../../../store';
import { Column } from '../../../components/common/Table';
import { IconType } from '../../../components/common/Icon';

export const useCostYearsCountries = () => {
    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const selectedDropdownValues = useAppSelector(state => state.project.selectedDropdownValues);

    const [currentServiceStartIndex, setCurrentServiceStartIndex] = useState(0);

    const fiscalYears = availableValues.fiscalYears.filter(x => !x.closed || project?.countryCostsPerYears?.map(y => y.fiscalYearId).includes(x.id));
    const fiscalYearInitial = availableValues.fiscalYears.find(x => !x.closed) ?? DEFAULT_SELECTED_FISCAL_YEAR;
    const [fiscalYearSelected, setFiscalYearSelected] = useState(selectedDropdownValues?.costYearsCountriesFiscalYear ?? fiscalYearInitial);
    const fiscalYearClosed = fiscalYearSelected?.closed ?? false;

    useEffect(() => {
        const fiscalYearToShow = selectedDropdownValues.costYearsCountriesFiscalYear;

        if (!fiscalYearToShow) {
            dispatch(setSelectedDropdownValues({ costYearsCountriesFiscalYear: fiscalYearInitial }));
        }

        setFiscalYearSelected(fiscalYearToShow ?? fiscalYearInitial);
        setCountryCostsInFiscalYear(getCountryCostsInFiscalYear(fiscalYearToShow ?? fiscalYearInitial));
    }, [project]);

    const getCountryCostsInFiscalYear = (fiscalYear: FiscalYear, updatedProject?: Project) => ((updatedProject ?? (project ?? EMPTY_PROJECT)).countryCostsPerYears ?? [])
        .map<CountryCostsPerYearWithOriginalIndex>((x, index) => ({ originalIndex: index, countryCostsPerYear: x }))
        .filter(x => x.countryCostsPerYear.fiscalYearId === (fiscalYear?.id ?? 0));
    const getSelectableCountries = () => availableValues.countries
        .filter(x => !project?.countryCostsPerYears?.filter(y => y.fiscalYearId == fiscalYearSelected.id).map(y => y.countryId).includes(x.id));
    const [countryCostsInFiscalYear, setCountryCostsInFiscalYear] = useState<CountryCostsPerYearWithOriginalIndex[]>(getCountryCostsInFiscalYear(fiscalYearSelected, project));
    const { getUniqueKey } = useHook(x => x.uniqueKey)();

    const costCalculations = project?.costsCalculations?.filter(x => (x.cost ?? 0) > 0 || x.quantity > 0);
    const costYearsServices = project?.costsPerYears?.flatMap(x => x.services.filter(y => (y.actualCost ?? 0) || (y.plannedCost ?? 0)));
    const countryCostYearServices = countryCostsInFiscalYear.flatMap(x => x.countryCostsPerYear.countryCostsPerYearServices.filter(y => (y.actualCost ?? 0) > 0 || (y.plannedCost ?? 0) > 0));
    const allServiceTypes = getCostCalculations(costCalculations ?? [], [...costYearsServices ?? [], ...countryCostYearServices ?? []], availableValues);

    const serviceArray = sortBy([...costCalculations ?? [], ...getUniqueValues(allServiceTypes ?? [], 'serviceCategoryId') ?? []], x => x.serviceCategory.index) ?? [];
    const serviceDisplayLimit = 4;
    const currentlyShownServices = serviceArray.slice(currentServiceStartIndex, currentServiceStartIndex + serviceDisplayLimit);

    const addCountryCostPerYear = () => {
        const newProject = project
            ? {
                ...project,
                countryCostsPerYears: [
                    ...project?.countryCostsPerYears ?? [],
                    {
                        ...EMPTY_COUNTRY_COST_PER_YEAR,
                        projectId: project?.id.length ? project?.id : undefined,
                        fiscalYearId: fiscalYearSelected.id,
                        key: getUniqueKey()
                    }]
            }
            : EMPTY_PROJECT;

        dispatch(setProject(newProject));
        setCountryCostsInFiscalYear(getCountryCostsInFiscalYear(fiscalYearSelected, newProject));
    };

    const changeFiscalYear = (fiscalYear: FiscalYear) => {
        dispatch(setSelectedDropdownValues({ costYearsCountriesFiscalYear: fiscalYear }));
        setFiscalYearSelected(fiscalYear);
        setCountryCostsInFiscalYear(getCountryCostsInFiscalYear(fiscalYear));
    };

    const getServiceColumns = (serviceList: CostCalculation[], currentServiceStartIndex: number, serviceDisplayLimit: number, serviceArray: CostCalculation[]) => serviceList
        .map<Column>((x, i) =>
            i !== 0 && i !== serviceList.length - 1
                ? {
                    key: i,
                    label: getServiceName(x, availableValues),
                    min: '90px',
                    max: '3fr',
                    bold: true,
                    tooltip: getServiceName(x, availableValues),
                    notClickable: true
                }
                : i == 0
                    ? {
                        key: i,
                        label: getServiceName(x, availableValues),
                        min: '90px',
                        max: '3fr',
                        bold: true,
                        button: { icon: IconType.ArrowheadLeft, position: 'left' },
                        tooltip: getServiceName(x, availableValues),
                        notClickable: currentServiceStartIndex < 1
                    }
                    : {

                        key: i,
                        label: getServiceName(x, availableValues),
                        min: '90px',
                        max: '3fr',
                        bold: true,
                        button: { icon: IconType.ArrowheadRight, position: 'right' },
                        tooltip: getServiceName(x, availableValues),
                        notClickable: currentServiceStartIndex + serviceDisplayLimit >= serviceArray.length
                    }
        );

    const handleChange = (value: Partial<CountryCostsPerYear>, index: number) => {
        const newProject = project
            ? {
                ...project,
                countryCostsPerYears: [
                    ...project.countryCostsPerYears?.map((x, i) => index === i ? { ...x, ...value } : x) ?? []
                ]
            }
            : EMPTY_PROJECT;

        dispatch(setProject(newProject));
        setCountryCostsInFiscalYear(getCountryCostsInFiscalYear(fiscalYearSelected, newProject));
    };

    const handleServiceClick = (index: number) => {

        index === 3 && currentServiceStartIndex > 0 && setCurrentServiceStartIndex(currentServiceStartIndex - 1);
        index === 3 + serviceDisplayLimit - 1 && currentServiceStartIndex < serviceArray.length - serviceDisplayLimit && setCurrentServiceStartIndex(currentServiceStartIndex + 1);
    };

    const handleServiceCostChange = (value: Partial<CountryCostsPerYearService>, textValue: string, serviceCategoryId: number, inputCostsPerYearIndex: number) => {
        if (!isNaN(Number(textValue))) {
            const currentCountryCostsPerYearServices = project?.countryCostsPerYears?.find((x, i) => i == inputCostsPerYearIndex)?.countryCostsPerYearServices ??
                [{ ...EMPTY_COUNTRY_COST_PER_YEAR_SERVICE, serviceCategoryId: serviceCategoryId }];
            const newCountryCostPerYearServices = currentCountryCostsPerYearServices.find(x => serviceCategoryId == x.serviceCategoryId)
                ? currentCountryCostsPerYearServices.map(x => serviceCategoryId === x.serviceCategoryId ? { ...x, ...value } : x)
                : [...currentCountryCostsPerYearServices, { ...EMPTY_COUNTRY_COST_PER_YEAR_SERVICE, serviceCategoryId: serviceCategoryId, ...value }];

            handleChange({ countryCostsPerYearServices: newCountryCostPerYearServices }, inputCostsPerYearIndex);
        }
    };

    return {
        addCountryCostPerYear,
        changeFiscalYear,
        countryCostsInFiscalYear,
        currentlyShownServices,
        currentServiceStartIndex,
        fiscalYearClosed,
        fiscalYearInitial,
        fiscalYears,
        fiscalYearSelected,
        getCountryCostsInFiscalYear,
        getSelectableCountries,
        getServiceColumns,
        handleChange,
        handleServiceClick,
        handleServiceCostChange,
        serviceArray,
        serviceDisplayLimit,
        setCountryCostsInFiscalYear,
        setFiscalYearSelected
    };
};
