import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { getScrollToElement } from '../../../services';
import { SCROLL_ID, DEFAULT_SELECTED_MACHINE_TYPE, TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { useHook } from '../../../hooks';
import { useProjectClosed } from '../../../hooks/application';
import { useAppSelector, useScrolledPast, useTranslate } from '../../../hooks/common';
import { setActiveTabType, setSelectedDropdownValues } from '../../../store';
import VolumeOverviewTechnicalInfo from './VolumeOverviewTechnicalInfo';
import VolumeOverviewTable from './VolumeOverviewTable';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Select from '../../common/Select';
import Sticky from '../../common/Sticky';
import Table from '../../common/Table';

const VolumeOverviewTab = () => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
   
    const machineTypeList = useAppSelector(state => 
    [
        DEFAULT_SELECTED_MACHINE_TYPE,
        ...state.project.availableValues.machineTypes
    ]);

    const sortedMachineTypes = machineTypeList.sort((x, y) => x.order < y.order ? -1 : 1);
    const closed = useProjectClosed();
    const mainTabledRef = useRef<HTMLDivElement>(null);
    const scrolledPastHeader = useScrolledPast(getScrollToElement(mainTabledRef));

    const { addNewVolumeOverview, columns, getBottomRows, handleChange, handleTechnicalInfoChange, machineType, removeVolumeOverview } = useHook(x => x.volumeOverview)();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.VolumeOverview));
    }, []);  

    return (
        <>
            <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 40 }}>
                <Select label={translate(TRANSLATIONS.main.machineGroup)} value={machineType} values={sortedMachineTypes} mapToString={x => x?.name ?? ''} onSelect={x => dispatch(setSelectedDropdownValues({ volumeOverviewMachineType: x }))} />
                <Button type={ButtonType.Primary} onClick={addNewVolumeOverview} disabled={closed}>Create new line</Button>
            </Flex>
            <ScrollSync>
                <>
                    <VolumeOverviewTable
                        columns={columns}
                        handleChange={handleChange}
                        machineType={machineType}
                        mainTableRef={mainTabledRef}
                        removeVolumeOverview={removeVolumeOverview}
                    />
                    <Sticky anchor='bottom'>
                        <Table
                            scrollId={SCROLL_ID.VOLUME_OVERVIEW}
                            noHeader={!scrolledPastHeader}
                            columns={columns}
                            rows={getBottomRows(project?.volumeOverviews)}
                        />
                    </Sticky>
                </>
            </ScrollSync>
            <VolumeOverviewTechnicalInfo handleTechnicalInfoChange={handleTechnicalInfoChange} />
        </>
    );
};

export default VolumeOverviewTab;
