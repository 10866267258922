import React from 'react';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { EMPTY_CUSTOMER, TRANSLATIONS } from '../../../constants';
import { GeneralInputsProps } from './BaseInputs';
import Search from '../../common/Search';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';

const CustomerAndCountryInputs = ({ closed, handleChange }: GeneralInputsProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    return (
        <div className='form-group'>
            <div className='length-6'>
                <Select
                    label={translate(TRANSLATIONS.main.customer)}
                    value={project?.customer}
                    values={availableValues.customers}
                    mapToString={x => x ? [x.name, x.city, x.street, x.mailbox].filter(y => y).join(' ') : ''}
                    mapToKey={x => x?.id?.toString() ?? ''}
                    required
                    disabled={closed}
                    error={!project?.customer?.name || project?.customer?.name === '-'}
                    filled={x => Boolean(x?.name && x?.name !== '-')}
                    onSelect={x => handleChange({ customer: x, companyId: x?.id })}
                    onReset={() => handleChange({ customer: EMPTY_CUSTOMER })}
                />
            </div>
            <div className='length-3'>
                <TextInput label={translate(TRANSLATIONS.main.customerCountry)} name='name' value={project?.customer?.country?.name ?? ''} disabled onChange={(x, y) => handleChange({ [y]: x })} />
            </div>
            <div className='length-3'>
                <TextInput label={translate(TRANSLATIONS.main.customerCity)} name='city' value={project?.customer?.city ?? ''} disabled onChange={(x, y) => handleChange({ [y]: x })} />
            </div>

            <div className='length-3'>
                <Select
                    label={translate(TRANSLATIONS.main.projectCountry)}
                    value={project?.country}
                    values={availableValues.countries}
                    mapToString={x => x?.name ?? ''}
                    required
                    disabled={closed}
                    error={!project?.country}
                    onSelect={x => handleChange({ country: x, countryId: x?.id })}
                    onReset={() => handleChange({ country: undefined, countryId: undefined })}
                />
            </div>
            <div className='length-3'>
                <Search
                    label={translate(TRANSLATIONS.main.projectLocation)}
                    searchValue={project?.location ?? ''}
                    mapToString={x => x}
                    disabled={closed}
                    error={!project?.location}
                    results={availableValues.cities.filter(x => x?.toLowerCase().includes(project?.location ?? ''.toLowerCase()) ?? x)}
                    onSelect={x => handleChange({ location: x })}
                    onChange={x => handleChange({ location: x })}
                />
            </div>
            <div className='length-3'>
                <Select
                    label={translate(TRANSLATIONS.main.countryDecisionMaker)}
                    value={project?.countryDecisionMaker}
                    values={availableValues.countries}
                    mapToString={x => x?.name ?? ''}
                    disabled={closed}
                    error={!project?.countryDecisionMaker}
                    onSelect={x => handleChange({ countryDecisionMaker: x, countryDecisionMakerId: x?.id })}
                    onReset={() => handleChange({ countryDecisionMaker: undefined, countryDecisionMakerId: undefined })}
                />
            </div>
            <div className='length-3'>
                <TextInput label={translate(TRANSLATIONS.main.competitor)} name='competitor' value={project?.competitor ?? ''} disabled={closed} error={!project?.competitor} onChange={(x, y) => handleChange({ [y]: x })} />
            </div>
        </div>
    );
};

export default CustomerAndCountryInputs;
