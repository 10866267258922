export const CLASS = 'class';
export const CONTACT_DEPARTMENT = 'department';
export const CONTACT_FIRST_NAME = 'firstName';
export const CONTACT_LAST_NAME = 'lastName';
export const CONTACT_TYPE = 'contactType';
export const COST_YEARS_COUNTRY = 'country';
export const COST_YEARS_FY = 'fy';
export const CORPORATION_GROUP = 'corporationGroup';
export const CUSTOMER = 'customer';
export const CUSTOMER_NAME = 'customerName';
export const CUSTOMER_COUNTRY = 'country';
export const ORDER_REQUEST_CONTACT = 'orderRequestContact';
export const ORDER_REQUEST_COUNTRY = 'orderRequestCountry';
export const ORDER_REQUEST_DESCRIPTION = 'orderRequestDescription';
export const ORDER_REQUEST_MAINGROUP = 'orderRequestMainGroup';
export const ORDER_REQUEST_SAP = 'orderRequestSap';
export const ORDER_REQUEST_VOLUME = 'orderRequestVolume';
export const PROJECT_NAME = 'projectName';
export const TECHNICAL_RESPONSIBLE = 'responsibleHeadquarterTechnical';
export const PROJECT_COUNTRY = 'projectCountry';
export const REPORTING_LAYOUT = 'reportingLayout';
export const CREATION_DATE_FROM = 'creationDateFrom';
export const CREATION_DATE_TO = 'creationDateTo';
export const DECISION_DATE_FROM = 'decisionDateFrom';
export const DECISION_DATE_TO = 'decisionDateTo';
export const VOLUMES_FY = 'fy';
export const VOLUME_OVERVIEW_MACHINE_TYPE = 'machineType';
