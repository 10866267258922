import { History } from '../models';

export const EMPTY_HISTORY: History = {
    id: 0,
    projectId: '',
    comment: '',
    ppaNumber: '',
    volumes: [],
    costsCalculations: [],
    costsPerYears: [],
    originalId: 0,
    pegaStartDate: ''
};
