import { useDispatch } from 'react-redux';
import { openLinkInNewTab } from '../../../services';
import { EMPTY_PROJECT, PEGA_LINK, TRANSLATIONS } from '../../../constants';
import { useHook } from '../../Hooks';
import { addSnackbar, setProject, setProjectHeaderDisabled } from '../../../store';
import { CountryCostsPerYearService, DocumentGenerationData, OrderRequest } from '../../../models';
import { SnackbarType } from '../../../components/common/Snackbar';
import { useAppSelector, useTranslate } from '../../common';

export const useCostYearsCountriesDropdown = () => {
    const dispatch = useDispatch();
    const orderRequestData = useAppSelector(state => state.project.orderRequestData);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { handleChange } = useHook(x => x.costYearsCountries)();
   
    const checkMissingDataForDocumentGeneration = (index: number) => {
        let dataMissing = false;
        const countryCostsPerYear = project?.countryCostsPerYears?.find((x, i) => i == index);

        if (!countryCostsPerYear?.countryId) {
            dataMissing = true;
            dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.missingCountry), type: SnackbarType.Error }));
        }

        if (!countryCostsPerYear?.fiscalYearId) {
            dataMissing = true;
            dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.missingFiscalYear), type: SnackbarType.Error }));
        }

        if (!project?.id) {
            dataMissing = true;
            dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.createProjectFirst), type: SnackbarType.Error }));
        }

        if (!countryCostsPerYear?.countryCostsPerYearServices.length) {
            dataMissing = true;
            dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.noCostsInRow), type: SnackbarType.Error }));
        }

        return dataMissing;
    };

    const onOrderOverviewClick = (countryId: number, fiscalYearId: number, index: number, onGenerateOrderOverview: (orderOverview: DocumentGenerationData) => Promise<void>) => {
        !checkMissingDataForDocumentGeneration(index)
            && project?.id
            && onGenerateOrderOverview({ projectId: project.id, countryId, fiscalYearId });
    };

    const onOrderRequestClick = (countryId: number, fiscalYearId: number, index: number, setShowOrderRequestModal: (value: boolean) => void, setOrderRequest: (orderRequest: OrderRequest) => void) => {

        if (!checkMissingDataForDocumentGeneration(index) && project?.id) {
            setShowOrderRequestModal(true);
            dispatch(setProjectHeaderDisabled(true));
            setOrderRequest({ ...orderRequestData, projectId: project.id, countryId, fiscalYearId });
        }
    };

    const onCreateSaLightClick = (countryId: number, fiscalYearId: number, index: number, onGenerateSaLight: (orderOverview: DocumentGenerationData) => Promise<void>) => {
        !checkMissingDataForDocumentGeneration(index)
            && project?.id
            && onGenerateSaLight({ projectId: project.id, countryId, fiscalYearId });
    };

    const onGenerateKuebClick = (
        countryId: number,
        fiscalYearId: number,
        index: number,
        setShowKuebModal: (value: boolean) => void,
        setKuebData: (kuebData: DocumentGenerationData) => void,
        onGenerateKueb: (orderOverview: DocumentGenerationData
    ) => Promise<void>) => {
        const countryCostsPerYear = project?.countryCostsPerYears?.find((x, i) => i == index);

        if (checkMissingDataForDocumentGeneration(index)) {
            return;
        }

        openLinkInNewTab(PEGA_LINK);

        if ((countryCostsPerYear?.countryCostsPerYearServices?.length ?? 0) > 6
            && !checkMissingDataForDocumentGeneration(index)) {
            setShowKuebModal(true);
            dispatch(setProjectHeaderDisabled(true));
            project?.id && setKuebData({ projectId: project?.id, countryId: countryId ?? 0, fiscalYearId: fiscalYearId ?? 0 });
        } else {
            !checkMissingDataForDocumentGeneration(index) && project?.id
                && onGenerateKueb({ projectId: project.id, countryId: countryId ?? 0, fiscalYearId: fiscalYearId ?? 0 });
        }

    };

    const onCreatePoaClick = (countryId: number, fiscalYearId: number, index: number, onGeneratePoa: (orderOverview: DocumentGenerationData) => Promise<void>) => {
        if (checkMissingDataForDocumentGeneration(index)) {
            return;
        }

        openLinkInNewTab(PEGA_LINK);
        project?.id && onGeneratePoa({ projectId: project.id, countryId, fiscalYearId });
    };

    const onUseValuesFromCostYearsClick = (index: number, fiscalYearId: number) => {
        const countryCostPerYearServices = project?.costsPerYears?.find(x => x.fiscalYearId == fiscalYearId)?.services.map<CountryCostsPerYearService>(x => ({
            serviceCategoryId: x.serviceCategoryId,
            plannedCost: x.plannedCost ?? 0,
            actualCost: 0
        }));

        handleChange({ countryCostsPerYearServices: countryCostPerYearServices }, index);
    };

    const removeCountryCostPerYear = (index: number) => {
        const newProject = project
            ? {
                ...project,
                countryCostsPerYears: [
                    ...project.countryCostsPerYears?.filter((x, i) => index !== i) ?? []
                ]
            }
            : EMPTY_PROJECT;

        dispatch(setProject(newProject));
    };

    return {
        onCreatePoaClick,
        onCreateSaLightClick,
        onGenerateKuebClick,
        onOrderOverviewClick,
        onOrderRequestClick,
        onUseValuesFromCostYearsClick,
        removeCountryCostPerYear
    };
};
