import { configureHooks } from '../providers';
import { useAvailableValues, useCostCalculation, useCostYears, useCostYearsCountries, useDocumentGeneration, useProjectSave, useLockedAndRemindedProjects, useProjectSearch, useProjectLoad, useLayouts, useUniqueKey, useUnloadAlert, useCostYearsCountriesModals, useCostYearsCountriesDropdown, useGeneral, useHistory, UseVolumeOverview, UseVolume, useFiscalYears, useKafkaData, useProjectAttachment } from './application';

export const hooks = configureHooks({
    availableValues: { hook: useAvailableValues },
    costCalculation: { hook: useCostCalculation },
    costYears: { hook: useCostYears },
    costYearsCountries: { hook: useCostYearsCountries },
    costYearsCountriesDropdown: { hook: useCostYearsCountriesDropdown },
    costYearsCountriesModals: { hook: useCostYearsCountriesModals },
    documentGeneration: { hook: useDocumentGeneration },
    fiscalYears: { hook: useFiscalYears },
    kafkaData: { hook: useKafkaData },
    general: { hook: useGeneral },
    history: { hook: useHistory },
    layouts: { hook: useLayouts },
    lockedAndRemindedProjects: { hook: useLockedAndRemindedProjects },
    projectAttachment: { hook: useProjectAttachment },
    projectLoad: { hook: useProjectLoad },
    projectSave: { hook: useProjectSave },
    projectSearch: { hook: useProjectSearch },
    uniqueKey: { hook: useUniqueKey },
    unloadAlert: { hook: useUnloadAlert, global: true },
    volume: { hook: UseVolume },
    volumeOverview: { hook: UseVolumeOverview }
});

export const useHook = hooks.useHook;
