import React from 'react';
import { convertNumberToLocal, sum } from '../../../services';
import { CostCalculation, History } from '../../../models';
import Table, { Cell, Column } from '../../common/Table';
import { useTranslate } from '../../../hooks/common';
import { TRANSLATIONS } from '../../../constants';

export interface TotalCostsTableProps {
    currentlyShownServices: (CostCalculation | undefined)[];
    currentHistory: History | undefined;
    costsTotalVolume: number;
    getServiceColumns: () => Column[];
    handleServiceClick: (index: number) => void;
}

const TotalCostsTable = ({ currentlyShownServices, currentHistory, costsTotalVolume, getServiceColumns, handleServiceClick }: TotalCostsTableProps) => {
    const translate = useTranslate();
    const specialCategoryIndex = 6;

    const getTotalCostsTableColumns = (): Column[] => [
        { label: translate(TRANSLATIONS.main.costType), min: '100px', max: '3fr', notClickable: true },
        { label: translate(TRANSLATIONS.main.total), min: '100px', max: '3fr', notClickable: true },
        ...getServiceColumns()
    ];

    const getTotalCostsPerServiceCells = (costsCalculations: CostCalculation[], key: keyof CostCalculation, secondKey?: keyof CostCalculation): Cell[] => [
        ...currentlyShownServices.map<Cell>(x => ({
            content: secondKey
                ? convertNumberToLocal(sum(costsCalculations.filter(y => y.serviceCategoryId === x?.serviceCategoryId), key, secondKey))
                : convertNumberToLocal(sum(costsCalculations.filter(y => y.serviceCategoryId === x?.serviceCategoryId), key)),
            inputStyle: false
        }))
    ];

    const getTotalCostsPerServicePriceCells = (costsCalculations: CostCalculation[]) => [
        ...currentlyShownServices.map<Cell>(x => ({
            content: x?.serviceCategory.index === specialCategoryIndex
                ? convertNumberToLocal((costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.additionalValue ?? 0) * costsTotalVolume)
                : convertNumberToLocal(costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.cost ?? 0),
            inputStyle: false
        }))
    ];

    const getTotalCostCells = (costsCalculations: CostCalculation[]) => [
        ...currentlyShownServices.map<Cell>(x => ({
            content: x?.serviceCategory.index === specialCategoryIndex
                ? convertNumberToLocal((costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.additionalValue ?? 0) * (costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.quantity ?? 0) * costsTotalVolume)
                : convertNumberToLocal((costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.cost ?? 0) * (costsCalculations.find(y => y.serviceCategoryId === x?.serviceCategoryId)?.quantity ?? 0)),
            inputStyle: false
        }))
    ];

    const getTotalCostsServiceSumCell = (costsCalculations: CostCalculation[], key: keyof CostCalculation, secondKey?: keyof CostCalculation): Cell => ({
        content: secondKey ? convertNumberToLocal(sum(costsCalculations, key, secondKey)) : convertNumberToLocal(sum(costsCalculations, key)),
        inputStyle: false
    });

    const servicePriceCells = getTotalCostsPerServicePriceCells(currentHistory?.costsCalculations ?? []);
    const servicePriceSumCell: Cell = {
        content: convertNumberToLocal((currentHistory?.costsCalculations ?? []).reduce((currentSum, x) =>
            x.serviceCategory.index === specialCategoryIndex
                ? currentSum + (x.additionalValue ?? 0) * costsTotalVolume
                : currentSum + (x.cost ?? 0)
            , 0)),
        inputStyle: false
    };
    const totalCostCells = getTotalCostCells(currentHistory?.costsCalculations ?? []);
    const totalCostSumCell: Cell = {
        content: convertNumberToLocal((currentHistory?.costsCalculations ?? []).reduce((currentSum, x) =>
            x.serviceCategory.index === specialCategoryIndex
                ? currentSum + (x.additionalValue ?? 0) * costsTotalVolume * (x.quantity ?? 0)
                : currentSum + (x.cost ?? 0) * (x.quantity ?? 0)
            , 0))
    };
    const totalQuantityCells = getTotalCostsPerServiceCells(currentHistory?.costsCalculations ?? [], 'quantity');
    const totalQuantitySumCell = getTotalCostsServiceSumCell(currentHistory?.costsCalculations ?? [], 'quantity');

    return (
        <Table
            onHeaderClick={handleServiceClick}
            noHeaderLineBreak={true}
            columns={getTotalCostsTableColumns()}
            rows={[
                {
                    key: 'totalQuantity',
                    cells: [
                        {
                            content: translate(TRANSLATIONS.main.totalQuantity)
                        },
                        totalQuantitySumCell,
                        ...totalQuantityCells
                    ]
                },
                {
                    key: 'totalPrice',
                    cells: [
                        {
                            content: translate(TRANSLATIONS.main.totalPrice)
                        },
                        servicePriceSumCell,
                        ...servicePriceCells
                    ]
                },
                {
                    key: 'totalCosts',
                    cells: [
                        {
                            content: translate(TRANSLATIONS.main.totalCosts)
                        },
                        totalCostSumCell,
                        ...totalCostCells
                    ]
                }]}
        />
    );
};

export default TotalCostsTable;
