import React, { useState } from 'react';
import { getServiceName, sortBy, sum } from '../../../services';
import { CostCalculation, CostsPerYearService, FiscalYear } from '../../../models';
import { EMPTY_COST_CALCULATION, EMPTY_SERVICE_CATEGORY } from '../../../constants';
import { useAppSelector } from '../../common';
import { IconType } from '../../../components/common/Icon';
import { Column } from '../../../components/common/Table';

export const useHistory = () => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const selectedDropdownValues = useAppSelector(state => state.project.selectedDropdownValues);
    const currentHistory = selectedDropdownValues?.historyDateOfAuthorizedFolder ?? project?.histories?.at(-1);
    const currentHistoryFiscalYear = availableValues.fiscalYears.find(x => currentHistory && x.beginDate <= currentHistory?.pegaStartDate && x.endDate >= currentHistory?.pegaStartDate);

    const serviceArray = sortBy([
        ...project?.histories?.flatMap(x => x.costsCalculations) ?? [],
        ...(currentHistory?.costsPerYears?.flatMap(y => y.services) ?? [])
            .map<CostCalculation>(y => {
                const serviceCategory = availableValues.serviceCategories.find(z => z.id === y.serviceCategoryId) ?? EMPTY_SERVICE_CATEGORY;
                return {
                    ...EMPTY_COST_CALCULATION,
                    serviceCategoryId: y.serviceCategoryId,
                    serviceCategory: serviceCategory,
                    serviceCategoryName: serviceCategory.serviceCategoryName,
                    serviceCategoryNameId: serviceCategory.serviceCategoryNameId
                };
            })],
        x => x?.serviceCategory.index ?? 0).filter((value, index, self) => self.findIndex(v => v?.serviceCategoryId === value?.serviceCategoryId) === index) ?? [];

    const serviceDisplayLimit = 4;
    const serviceStartIndex = 2;
    const [currentServiceStartIndex, setCurrentServiceStartIndex] = useState(0);
    const currentlyShownServices = serviceArray.slice(currentServiceStartIndex, currentServiceStartIndex + serviceDisplayLimit) ?? [];

    const costsTotalVolume = sum(currentHistory?.volumes ?? [], 'totalSales');

    const checkIfPreviousFiscalYear = (fiscalYearToCheck: FiscalYear | undefined) =>
        fiscalYearToCheck && fiscalYearToCheck.endDate && currentHistoryFiscalYear && currentHistoryFiscalYear.beginDate && fiscalYearToCheck.endDate < currentHistoryFiscalYear.beginDate;

    const getPlannedOrActualCost = (service: CostsPerYearService, fiscalYearId: number) => checkIfPreviousFiscalYear(availableValues.fiscalYears.find(x => x.id === fiscalYearId))
        ? service.actualCost ?? 0
        : service.plannedCost ?? 0;

    const costsTotal = currentHistory?.costsPerYears?.reduce((currentSum, x) =>
        x.services.length
            ? currentSum + x.services.reduce((serviceSum, service) =>
                serviceSum + getPlannedOrActualCost(service, x.fiscalYearId)
                , 0)
            : currentSum
        , 0) ?? 0;

    const handleServiceClick = (index: number) => {
        index === serviceStartIndex && currentServiceStartIndex > 0 && setCurrentServiceStartIndex(currentServiceStartIndex - 1);
        index === serviceStartIndex + serviceDisplayLimit - 1 && currentServiceStartIndex < serviceArray.length - serviceDisplayLimit && setCurrentServiceStartIndex(currentServiceStartIndex + 1);
    };

    const getServiceColumns = (): Column[] => currentlyShownServices.map<Column>((x, i) => (
        {
            key: i,
            label: getServiceName(x, availableValues),
            min: '90px',
            max: '3fr',
            bold: true,
            button: i !== 0 && i !== currentlyShownServices.length - 1
                ? undefined
                : i === 0
                    ? { icon: IconType.ArrowheadLeft, position: 'left' }
                    : { icon: IconType.ArrowheadRight, position: 'right' },
            tooltip: getServiceName(x, availableValues),
            notClickable: i !== 0 && i !== currentlyShownServices.length - 1
                ? true
                : i === 0
                    ? currentServiceStartIndex < 1
                    : currentServiceStartIndex + serviceDisplayLimit >= serviceArray.length
        })
    );    
    return {
        currentHistory,
        costsTotal,
        costsTotalVolume,
        currentlyShownServices,
        getPlannedOrActualCost,
        getServiceColumns,
        handleServiceClick
    };
};
