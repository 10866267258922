import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Api, apiCall, getAccessToken } from '../../../services';
import { ProjectListItem, FilterValues, OrderedPaginatedList } from '../../../models';
import { ProjectStatus } from '../../../enums';
import { useAppSelector, useMounted, useTranslate } from '../../common';
import { TRANSLATIONS } from '../../../constants';
import { addSnackbar, setFilterValues, setProjectSearchIds, setProjectSearchPageAndOrdering } from '../../../store';
import { SnackbarType } from '../../../components/common/Snackbar';

export const useProjectSearch = () => {
    const dispatch = useDispatch();
    const filterValues = useAppSelector(state => state.project.filterValues);
    const pageAndOrdering = useAppSelector(state => state.project.projectSearchPageAndOrdering);
    const translate = useTranslate();
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const mounted = useMounted();
    const [projects, setProjects] = useState<OrderedPaginatedList<ProjectListItem> | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated && accounts[0] && getAccessToken().length) {
            makeSearchApiCall(filterValues, pageAndOrdering?.page, pageAndOrdering?.orderBy, pageAndOrdering?.orderType);
        }
    }, [isAuthenticated, accounts[0]]);

    useEffect(() => {
        projects && dispatch(setProjectSearchPageAndOrdering({ page: projects.pageNumber, orderBy: projects.orderBy, orderType: projects.orderType }));
    }, [projects]);

    const makeSearchApiCall = async (newFilterValues: FilterValues, page = 1, orderBy?: string, orderType?: string) => {
        mounted.current && setLoading(true);

        await apiCall(
            Api.getSearchedProjects(newFilterValues, page, orderBy, orderType),
            async x => {
                if (mounted.current) {
                    setProjects(x.data);
                    dispatch(setProjectSearchIds(x.data.ids));
                }
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadProjects), type: SnackbarType.Error }));
            }
        );

        mounted.current && setLoading(false);
    };

    const handlePaging = (page: number) => {
        makeSearchApiCall(filterValues, page + 1, projects?.orderBy, projects?.orderType);
    };

    const handleOrdering = (orderBy: string, orderType: string) => {
        makeSearchApiCall(filterValues, 1, orderBy, orderType);
    };

    const handleSearch = (filterValues: FilterValues) => {
        makeSearchApiCall(filterValues, 1, projects?.orderBy, projects?.orderType);
    };

    const handleFilterChange = (newFilterValues: Partial<FilterValues>) => {
        dispatch(setFilterValues({ ...filterValues, ...newFilterValues }));
    };

    const handleStatusChange = (statuses: ProjectStatus[]) => {
        const newFilterValues = { ...filterValues, statuses };

        dispatch(setFilterValues(newFilterValues));
        makeSearchApiCall(newFilterValues, 1, projects?.orderBy, projects?.orderType);
    };

    return {
        searchedProjects: projects,
        searchedProjectsLoading: loading,
        onFilterChange: handleFilterChange,
        onSearch: handleSearch,
        onSearchedProjectsOrdering: handleOrdering,
        onSearchedProjectsPaging: handlePaging,
        onStatusChange: handleStatusChange
    };
};
