import { useEffect } from 'react';
import { useAppSelector } from '../../common';

export const useUnloadAlert = () => {
    const project = useAppSelector(state => state.project.project);
    const projectChanged = useAppSelector(state => state.project.projectChanged);

    useEffect(() => {
        if (project && projectChanged) {
            window.onbeforeunload = () => '';
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [project, projectChanged]);
};
