import React from 'react';
import { isDateValid } from '../../../services';
import { Project } from '../../../models';
import { ProjectCategory } from '../../../enums';
import { EMPTY_HISTORY, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Checkbox from '../../common/Checkbox';
import DateInput from '../../common/DateInput';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Select from '../../common/Select';
import TextInput from '../../common/TextInput';

export interface GeneralInputsProps {
    handleChange: (value: Partial<Project>) => void;
    closed: boolean;
}

export interface GeneralInputsWithDateProps extends GeneralInputsProps {
    getDateValue: (dateString: string | undefined) => string;
}

const BaseInputs = ({ closed, handleChange, getDateValue }: GeneralInputsWithDateProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    return (
        <div className='form-group'>
            <div className='length-3'>
                <TextInput label={translate(TRANSLATIONS.main.sapNo)} name='sapNr' value={project?.sapNr ?? ''} disabled={closed} onChange={(x, y) => handleChange({ [y]: x })} />
            </div>
            <div className='length-3'>
                <TextInput label={translate(TRANSLATIONS.main.approvalNo)} name='PPANo' value={project?.histories?.reduce((a, b) => a.pegaStartDate > b.pegaStartDate ? a : b, EMPTY_HISTORY)?.ppaNumber ?? ''} disabled onChange={(x, y) => handleChange({ [y]: x })} />
            </div>
            <div className='length-3'>
                <DateInput label={translate(TRANSLATIONS.main.endOfContract)} value={getDateValue(project?.endOfContract)} disabled={closed} error={!isDateValid(project?.endOfContract)} onChange={x => handleChange({ endOfContract: x })} />
            </div>
            <div className='length-3'>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <Checkbox label={translate(TRANSLATIONS.main.endUserAgreementSigned)} checked={project?.endUserAgreementSigned ?? false} disabled={closed} onChange={x => handleChange({ endUserAgreementSigned: x })} />
                </Flex>
            </div>
            <div className='length-6'>
                <TextInput label={translate(TRANSLATIONS.main.projectName)} name='name' value={project?.name ?? ''} required disabled={closed} error={!project?.name} onChange={(x, y) => handleChange({ [y]: x })} />
            </div>
            <div className='length-3'>
                <Select
                    label={translate(TRANSLATIONS.main.corpGroup)}
                    value={project?.corporationGroup}
                    values={availableValues.corporationGroups}
                    mapToString={x => x?.name ?? ''}
                    required
                    disabled={closed}
                    error={!project?.corporationGroup}
                    onSelect={x => handleChange({ corporationGroup: x })}
                    onReset={() => handleChange({ corporationGroup: undefined })}
                />
            </div>
            <div className='length-3'>
                <Select
                    label={translate(TRANSLATIONS.main.class)}
                    value={project?.projectCategory}
                    values={Object.values(ProjectCategory).filter(x => typeof x === 'number') as ProjectCategory[]}
                    mapToString={x => x != null ? ProjectCategory[x] : ''}
                    required
                    disabled={closed}
                    error={project?.projectCategory == null}
                    onSelect={x => handleChange({ projectCategory: x })}
                    onReset={() => handleChange({ projectCategory: undefined })}
                    filled={x => x != null}
                />
            </div>
        </div>
    );
};

export default BaseInputs;
