import React from 'react';
import { TRANSLATIONS } from '../../../constants';
import { TechnicalInfo } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useProjectClosed } from '../../../hooks/application';
import Grid from '../../common/Grid';
import Search from '../../common/Search';

export interface VolumeOverviewTechnicalInfoProps {
    handleTechnicalInfoChange: (value: Partial<TechnicalInfo>) => void;
}

const VolumeOverviewTechnicalInfo = ({ handleTechnicalInfoChange }: VolumeOverviewTechnicalInfoProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const closed = useProjectClosed();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    return (
        <Grid columns={4} columnGap={30} rowGap={30} style={{ marginTop: 50, marginBottom: 60 }}>
            <Search
                label={translate(TRANSLATIONS.main.visualization)}
                searchValue={project?.technicalInfo?.visualization ?? ''}
                mapToString={x => x}
                results={availableValues.technicalInfoVisualizations.filter(x => (x ?? '').toLowerCase().includes(project?.technicalInfo?.visualization?.toLowerCase() ?? '') ?? x)}
                onSelect={x => handleTechnicalInfoChange({ visualization: x } ?? '')}
                onChange={x => handleTechnicalInfoChange({ visualization: x } ?? '')}
                disabled={closed}
            />
            <Search
                label={translate(TRANSLATIONS.main.plc)}
                searchValue={project?.technicalInfo?.plc ?? ''}
                mapToString={x => x}
                results={availableValues.technicalInfoPlcs.filter(x => (x ?? '').toLowerCase().includes(project?.technicalInfo?.plc?.toLowerCase() ?? '') ?? x)}
                onSelect={x => handleTechnicalInfoChange({ plc: x } ?? '')}
                onChange={x => handleTechnicalInfoChange({ plc: x } ?? '')}
                disabled={closed}
            />
            <Search
                label={translate(TRANSLATIONS.main.drives)}
                searchValue={project?.technicalInfo?.drives ?? ''}
                mapToString={x => x}
                results={availableValues.technicalInfoDrives.filter(x => (x ?? '').toLowerCase().includes(project?.technicalInfo?.drives?.toLowerCase() ?? '') ?? x)}
                onSelect={x => handleTechnicalInfoChange({ drives: x } ?? '')}
                onChange={x => handleTechnicalInfoChange({ drives: x } ?? '')}
                disabled={closed}
            />
            <Search
                label={translate(TRANSLATIONS.main.nc)}
                searchValue={project?.technicalInfo?.nc ?? ''}
                mapToString={x => x}
                results={availableValues.technicalInfoNcs.filter(x => (x ?? '').toLowerCase().includes(project?.technicalInfo?.nc?.toLowerCase() ?? '') ?? x)}
                onSelect={x => handleTechnicalInfoChange({ nc: x } ?? '')}
                onChange={x => handleTechnicalInfoChange({ nc: x } ?? '')}
                disabled={closed}
            />
            <Search
                label={translate(TRANSLATIONS.main.baseOperatingSystem)}
                searchValue={project?.technicalInfo?.baseOperatingSystem ?? ''}
                mapToString={x => x}
                results={availableValues.technicalInfoOperatingSystems.filter(x => (x ?? '').toLowerCase().includes(project?.technicalInfo?.baseOperatingSystem?.toLowerCase() ?? '') ?? x)}
                onSelect={x => handleTechnicalInfoChange({ baseOperatingSystem: x } ?? '')}
                onChange={x => handleTechnicalInfoChange({ baseOperatingSystem: x } ?? '')}
                disabled={closed}
            />
        </Grid>
    );
};

export default VolumeOverviewTechnicalInfo;
