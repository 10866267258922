import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import hash from 'object-hash';
import { Api, apiCall, clearIncompleteDatesFromProject, getTabLink, sortBy } from '../../../services';
import { ContactType } from '../../../enums';
import { Project, History, Company, Contact, DataResult } from '../../../models';
import { EMPTY_AVAILABLE_VALUES, EMPTY_PROJECT, FISCAL_YEAR_CLOSING_ERROR_MESSAGE, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../common';
import { addSnackbar, setAvailableValues, setInitialHash, setInitialStatus, setProject, setProjectLoading } from '../../../store';
import { SnackbarType } from '../../../components/common/Snackbar';
import { useHook } from '../../Hooks';

export const useProjectSave = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeTabType = useAppSelector(state => state.layout.activeTabType);
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { handleCurrentFiscalYearGet } = useHook(x => x.fiscalYears)();

    const handleForbiddenDueToFiscalYearClosing = (result: DataResult<Project | History | Company | Contact>) => {
        if (result && result.hasError && result.messages.some(y => y === FISCAL_YEAR_CLOSING_ERROR_MESSAGE)) {
            handleCurrentFiscalYearGet();
        }
    };

    const handleAvailableValuesGet = async () => {
        await apiCall(
            Api.getAvailableValues(),
            async x => {
                dispatch(setAvailableValues(x.data));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadAvailableValues), type: SnackbarType.Error }));
            }
        );
    };

    const handleProjectApprove = async (approveData: Partial<History>): Promise<boolean> => {
        let success = false;
        
        await apiCall(
            Api.approveProject(approveData),
            async x => {
                const projectToUpdate: Project = { ...project ?? EMPTY_PROJECT, histories: [...project?.histories ?? [], x.data] };

                dispatch(setProject(projectToUpdate));
                dispatch(setInitialHash(hash(clearIncompleteDatesFromProject(projectToUpdate))));
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.projectApprovedSuccessfully), type: SnackbarType.Success }));
                success = true;
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x.messages.join(' '), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                } else {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.failedToApproveTheProject), type: SnackbarType.Error }));
                }
            }
        );

        return success;
    };

    const handleProjectCreate = async (setIdInUrl = false) => {
        let success = false;

        dispatch(setProjectLoading(true));

        await apiCall(
            Api.saveProject(clearIncompleteDatesFromProject(project) ?? EMPTY_PROJECT),
            async x => {
                success = true;

                dispatch(setProject(x.data));
                dispatch(setInitialHash(hash(clearIncompleteDatesFromProject(x.data))));
                dispatch(setInitialStatus(x.data.status));
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.projectSavedSuccessfully), type: SnackbarType.Success }));

                setIdInUrl && navigate(`project/${x.data.id}${getTabLink(activeTabType)}`, { replace: true });

                await handleAvailableValuesGet();
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x.messages.join(' '), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                } else {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.failedToSaveTheProject), type: SnackbarType.Error }));
                }
            }
        );

        dispatch(setProjectLoading(false));

        return success;
    };

    const handleProjectSaveInBackground = async (project: Project) => {
        let success = false;

        dispatch(setProjectLoading(true));

        const clearedProject = clearIncompleteDatesFromProject(project);

        await apiCall(
            project.id ? Api.updateProject(clearedProject) : Api.saveProject(clearedProject),
            async () => {
                success = true;

                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.projectSavedSuccessfully), type: SnackbarType.Success }));
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x.messages.join(' '), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                } else {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.failedToSaveTheProject), type: SnackbarType.Error }));
                }
            }
        );

        dispatch(setProjectLoading(false));

        return success;
    };

    const handleProjectUpdate = async (projectToSave?: Project) => {
        let success = false;

        dispatch(setProjectLoading(true));

        await apiCall(
            Api.updateProject(clearIncompleteDatesFromProject(projectToSave ?? project) ?? EMPTY_PROJECT),
            async x => {
                success = true;

                dispatch(setProject(x.data));
                dispatch(setInitialHash(hash(clearIncompleteDatesFromProject(x.data))));
                dispatch(setInitialStatus(x.data.status));
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.projectSavedSuccessfully), type: SnackbarType.Success }));

                await handleAvailableValuesGet();
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x.messages.join(' '), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                } else {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.failedToSaveTheProject), type: SnackbarType.Error }));
                }
            }
        );

        dispatch(setProjectLoading(false));

        return success;
    };

    const handleCompanySave = async (company: Company) => {
        await apiCall(
            Api.saveCompany(company),
            async x => {
                dispatch(setAvailableValues({
                     ...availableValues ?? EMPTY_AVAILABLE_VALUES, 
                    customers: sortBy([...availableValues.customers, x.data], y => y.name)  
                }));
                dispatch(setProject({ ...project ?? EMPTY_PROJECT, customer: x.data, companyId: x.data.id }));
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x?.messages[0] ?? translate(TRANSLATIONS.snackbarMessages.cannotSaveCustomer), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                }
            }
        );
    };

    const handleContactSave = async (contact: Contact) => {

        const getContact = (contact: Contact): Partial<Project> => {
            switch(contact.contactType) {
                case ContactType.HeadquarterCommercial:
                    return { hqCommercialResponsible: contact };
                case ContactType.HeadquarterTechnical:
                    return { hqTechnicalResponsible: contact };
                case ContactType.LocalProjectManagement:
                    return { localTechnicalResponsible: contact };
            }
        };

        await apiCall(
            Api.saveContact(contact),
            async x => {
                const savedContact = getContact(x.data);

                dispatch(setAvailableValues({
                     ...availableValues ?? EMPTY_AVAILABLE_VALUES,
                     contacts: sortBy([...availableValues.contacts, x.data], y => `${y.firstName}, ${y.lastName}`)
                }));
                savedContact && dispatch(setProject({ ...project ?? EMPTY_PROJECT, ...savedContact }));
            },
            async x => {
                if (x) {
                    dispatch(addSnackbar({ text: x?.messages[0] ?? translate(TRANSLATIONS.snackbarMessages.cannotSaveContact), type: SnackbarType.Error }));
                    handleForbiddenDueToFiscalYearClosing(x);
                }
            }
        );
    };

    return {
        onAvailableValuesGet: handleAvailableValuesGet,
        onCompanySave: handleCompanySave,
        onContactSave: handleContactSave,
        onProjectApprove: handleProjectApprove,
        onProjectCreate: handleProjectCreate,
        onProjectSaveInBackground: handleProjectSaveInBackground,
        onProjectUpdate: handleProjectUpdate
    };
};
