import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthenticationInProgress } from './ts/components/application/helperPages/authentication';
import { setFilterValues } from './ts/store';
import { loginApiRequest } from './ts/constants/AuthConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Authorize from './Authorize';
import { setAccessToken } from './ts/services';

interface AuthenticateProps {
    children: ReactNode;
}

const Authenticate = ({ children }: AuthenticateProps) => {
    const dispatch = useDispatch();
    const { accounts, inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [msalToken, setMsalToken] = useState('');
    const authenticationInProgress = inProgress !== 'none';

    const handleAcquireTokenSilent = useCallback(async () => {
        await instance.acquireTokenSilent({ ...loginApiRequest, account: accounts[0] }).then(accessTokenResponse => {
            setAccessToken(accessTokenResponse.idToken);
            setMsalToken(accessTokenResponse.idToken);
        })
        .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(loginApiRequest);
            }
        });
    }, [accounts, instance]);

    useEffect(() => {
        if (isAuthenticated) {
            if (accounts[0]?.idToken && !msalToken.length) {
                setAccessToken(accounts[0]?.idToken);
                setMsalToken(accounts[0]?.idToken);
            } else {
                handleAcquireTokenSilent();
            }

            dispatch(setFilterValues({ responsibleName: `${accounts[0]?.idTokenClaims?.given_name}, ${accounts[0]?.idTokenClaims?.family_name}` }));
        }
        
    }, [accounts, dispatch, handleAcquireTokenSilent, instance, isAuthenticated, msalToken.length]);

    return isAuthenticated && authenticationInProgress ? <AuthenticationInProgress /> : <Authorize msalTokenSet={Boolean(msalToken.length)}>{children}</Authorize>;
};

export default Authenticate;
