import { TechnicalInfo } from '../models';

export const EMPTY_TECHNICAL_INFO: TechnicalInfo = {
    id: 0,
    baseOperatingSystem: '',
    drives: '',
    nc: '',
    plc: '',
    visualization: ''
};
