import React from 'react';
import { convertNumberToLocal, sum } from '../../../services';
import { TRANSLATIONS } from '../../../constants';
import { History } from '../../../models';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import Table, { Column, Row } from '../../common/Table';

export interface VolumeByYearsTableProps {
    currentHistory: History | undefined;
}

const VolumeByYearsTable = ({ currentHistory }: VolumeByYearsTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const translate = useTranslate();
    const project = useAppSelector(state => state.project.project);

    const volumeTotalSales = sum(currentHistory?.volumes ?? [], 'totalSales');
    const volumeCountrySales = sum(currentHistory?.volumes ?? [], 'countrySales');
    const volumeSoftwareSales = sum(currentHistory?.volumes ?? [], 'softwareSales');
    const volumeEPowertrainSales = sum(currentHistory?.volumes ?? [], 'ePowertrainSales');

    const getVolumeTableColumns = (): Column[] => [
        { label: '', min: '80px', max: '1fr', notClickable: true },
        { label: translate(TRANSLATIONS.main.totalSales), min: '100px', max: '1fr', notClickable: true },
        { label: `${project?.country?.name ?? ''} ${translate(TRANSLATIONS.main.sales)}`, min: '100px', max: '1fr', notClickable: true },
        { label: translate(TRANSLATIONS.main.softwareSales), min: '100px', max: '1fr', notClickable: true },
        { label: translate(TRANSLATIONS.main.epowertrainSales), min: '100px', max: '1fr', notClickable: true }
    ];

    const getVolumeByYearRows = (): Row[] => [
        ...(currentHistory?.volumes ?? []).map<Row>((x, index) => {
            const fiscalYear = availableValues.fiscalYears.find(y => y.id === x.fiscalYearId)?.name;

            return {
                key: `volume${index}`,
                cells: [
                    {
                        content: fiscalYear ?? ''
                    },
                    {
                        content: convertNumberToLocal(x.totalSales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(x.countrySales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(x.softwareSales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(x.ePowertrainSales ?? 0)
                    }
                ]
            };
        })
    ];

    return (
        <Table columns={getVolumeTableColumns()} rows={[
            {
                key: 'volumeSum',
                cells: [
                    {
                        content: translate(TRANSLATIONS.main.volume)
                    },
                    {
                        content: convertNumberToLocal(volumeTotalSales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(volumeCountrySales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(volumeSoftwareSales ?? 0)
                    },
                    {
                        content: convertNumberToLocal(volumeEPowertrainSales ?? 0)
                    }
                ]
            },
            ...getVolumeByYearRows()
        ]} />
    );
};

export default VolumeByYearsTable;
