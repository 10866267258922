import { configureStore } from '@reduxjs/toolkit';
import { bannerReducer, fiscalYearReducer, layoutReducer, snackbarReducer, keyCounterReducer, userReducer, reportReducer, projectReducer, translationReducer } from '.';

export const store = configureStore({
    reducer: {
        banners: bannerReducer,
        fiscalYears: fiscalYearReducer,
        layout: layoutReducer,
        snackbars: snackbarReducer,
        keyCounter: keyCounterReducer,
        user: userReducer,
        report: reportReducer,
        project: projectReducer,
        translation: translationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
