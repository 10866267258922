import React, { RefObject } from 'react';
import { convertNumberToLocal } from '../../../services';
import { MachineType, VolumeOverview } from '../../../models';
import { MAX_INTEGER, NUMBER_FORMAT_WITH_SEPARATOR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useProjectClosed } from '../../../hooks/application';
import Button, { ButtonType } from '../../common/Button';
import Icon, { IconType } from '../../common/Icon';
import NumberInput from '../../common/NumberInput';
import Select from '../../common/Select';
import Table, { Column, Row } from '../../common/Table';
import TextInput from '../../common/TextInput';
import Tooltip from '../../common/Tooltip';

export interface VolumeOverviewTableProps {
    columns: Column[];
    handleChange: (value: Partial<VolumeOverview>, index: number) => void;
    machineType: MachineType | undefined;
    mainTableRef: RefObject<HTMLDivElement>;
    removeVolumeOverview: (index: number) => void;
}

const VolumeOverviewTable = ({ columns, handleChange, machineType, mainTableRef, removeVolumeOverview }: VolumeOverviewTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const closed = useProjectClosed();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();

    const renderTooltipWrapper = (text: string) => <Tooltip key={text} id={`${text}`} delayShow={750} text={text}>{text}</Tooltip>;

    const filterMachineType = (value?: MachineType) =>
        value?.name === machineType?.name || machineType?.name === 'All';

    return (
        <Table
            tableRef={mainTableRef}
            scrollId={SCROLL_ID.VOLUME_OVERVIEW}
            noScrollbar
            columns={columns}
            rows={[
                ...(project?.volumeOverviews?.map((volumeOverview, index) => ({ volumeOverview, index })).filter(x => filterMachineType(x.volumeOverview.machineSubCategory?.machineType)) ?? []).map<Row>(x => ({
                    key: x.volumeOverview.id ?? x.volumeOverview.key ?? x.index,
                    cells: [
                        { content: x.volumeOverview.machineSubCategory?.machineType?.shortName ?? '' },
                        {
                            content: <Select
                                label={translate(TRANSLATIONS.main.machineType)}
                                key={`${x.index} machineType`}
                                value={x.volumeOverview.machineSubCategory}
                                values={availableValues.machineSubCategories.filter(y => filterMachineType(y.machineType)).sort((a, b) => a.order < b.order ? -1 : 1)}
                                mapToString={y => y?.name ?? ''}
                                onSelect={y => handleChange({ machineSubCategory: y, machineSubCategoryId: y?.id, price: y?.price }, x.index)}
                                disabled={closed}
                                required
                                error={!x.volumeOverview.machineSubCategoryId}
                            />,
                            inputStyle: true
                        },
                        {
                            content: <TextInput
                                label={translate(TRANSLATIONS.main.oemType)}
                                key={`${x.index} comment`}
                                name='comment'
                                value={x.volumeOverview.comment ?? ''}
                                onChange={y => handleChange({ comment: y.toString() ?? '' }, x.index)}
                                disabled={closed}
                            />,
                            inputStyle: true
                        },
                        {
                            content: <NumberInput
                                label={translate(TRANSLATIONS.main.totalPcs)}
                                key={`${x.index} totalQuantity`}
                                name='totalQuantity'
                                value={x.volumeOverview.totalQuantity?.toString() ?? ''}
                                onChange={y => handleChange({ totalQuantity: Number(y) }, x.index)}
                                disabled={closed}
                                maxValue={MAX_INTEGER}
                                numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                tooltipId={`total_squantity_${x.index?.toString()}`}
                            />,
                            inputStyle: true
                        },
                        {
                            content: <NumberInput
                                label={translate(TRANSLATIONS.main.countryPcs)}
                                key={`${x.index} countryQuantity`}
                                name='countryQuantity'
                                value={x.volumeOverview.countryQuantity?.toString() ?? ''}
                                onChange={y => handleChange({ countryQuantity: Number(y) }, x.index)}
                                disabled={closed}
                                maxValue={MAX_INTEGER}
                                numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                tooltipId={`country_quantity_${x.index?.toString()}`}
                            />,
                            inputStyle: true
                        },
                        {
                            content: <NumberInput
                                label={translate(TRANSLATIONS.main.price)}
                                key={`${x.index}-${x.volumeOverview.machineSubCategoryId} price`}
                                name='price'
                                value={x.volumeOverview.price?.toString() ?? ''}
                                onChange={y => handleChange({ price: Number(y) }, x.index)}
                                disabled={closed}
                                maxValue={MAX_INTEGER}
                                numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                tooltipId={`price_${x.index?.toString()}`}
                            />,
                            inputStyle: true
                        },
                        { content: renderTooltipWrapper(convertNumberToLocal((x.volumeOverview.totalQuantity ?? 0) * (x.volumeOverview.price ?? 0))) },
                        { content: renderTooltipWrapper(convertNumberToLocal((x.volumeOverview.countryQuantity ?? 0) * (x.volumeOverview.price ?? 0))) },
                        {
                            content: <Button
                                key={`${x.index} remove`}
                                type={ButtonType.Tertiary}
                                onClick={() => removeVolumeOverview(x.index)}
                                disabled={closed}
                            >
                                <Icon type={IconType.Trash} />
                            </Button>,
                            inputStyle: true
                        }
                    ]
                })),
                { key: 'divider', divider: true, cells: [] }
            ]}
        />
    );

};

export default VolumeOverviewTable;
