import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FiscalYear } from '../models';

export interface FiscalYearState {
    currentFiscalYear?: FiscalYear;
    fiscalYears?: FiscalYear[];
}

const initialState: FiscalYearState = {
    currentFiscalYear: undefined,
    fiscalYears: []
};

export const fiscalYearSlice = createSlice({
    name: 'fiscalYear',
    initialState,
    reducers: {
        setCurrentFiscalYear: (state: FiscalYearState, action: PayloadAction<FiscalYear>) => {
            state.currentFiscalYear = action.payload;
        },
        setFiscalYears: (state: FiscalYearState, action: PayloadAction<FiscalYear[]>) => {
            state.fiscalYears = action.payload;
        }
    }
});

export const { setCurrentFiscalYear, setFiscalYears } = fiscalYearSlice.actions;
export const fiscalYearReducer = fiscalYearSlice.reducer;
