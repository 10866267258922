import { FiscalYear } from '../models';

export const DEFAULT_SELECTED_FISCAL_YEAR: FiscalYear = {
    id: 0,
    beginDate: '',
    beginYear: 0,
    closed: false,
    closing: false,
    endDate: '',
    name: 'All',
    order: 0
};
