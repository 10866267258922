import react from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportState {
    reportModalLoading: boolean
    reportPageLoading: boolean;
}

const initialState: ReportState = {
    reportModalLoading: false,
    reportPageLoading: false
};

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setReportModalLoading: (state: ReportState, action: PayloadAction<boolean>) => {
            state.reportModalLoading = action.payload;
        },
        setReportPageLoading: (state: ReportState, action: PayloadAction<boolean>) => {
            state.reportPageLoading = action.payload;
        }
    }
});

export const { setReportModalLoading, setReportPageLoading } = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
