import { SilentRequest } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID ?? '',
        authority: process.env.REACT_APP_TENANT_ID ?? '',
        redirectUri: process.env.REACT_APP_REDIRECT_URI ?? '',
        postLogoutRedirectUri: process.env.REACT_APP_BASE_URL ?? '',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
    }
    //,
    //system: {
    //    loggerOptions: {
    //        loggerCallback: (level: any, message: string, containsPii: any) => {
    //            if (containsPii) {
    //                return;
    //            }
    //            switch (level) {
    //                case LogLevel.Error:
    //                    console.error(message);
    //                    return;
    //                case LogLevel.Info:
    //                    console.info(message);
    //                    return;
    //                case LogLevel.Verbose:
    //                    console.debug(message);
    //                    return;
    //                case LogLevel.Warning:
    //                    console.warn(message);
    //                    return;
    //            }
    //        }
    //    }
    //}
};

export const loginApiRequest = {
    scopes: [`openid ${process.env.REACT_APP_SCOPE ?? ''}`]
} as SilentRequest;
