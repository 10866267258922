import React, { RefObject } from 'react';
import { addPrefixToKey, convertStringToLocal } from '../../../services';
import { CostCalculation } from '../../../models';
import { EMPTY_COST_CALCULATION, EMPTY_SERVICE_CATEGORY_NAME, MAX_INTEGER, NUMBER_FORMAT_WITH_SEPARATOR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { QuantityUnit } from '../../../enums';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { useProjectClosed } from '../../../hooks/application';
import NumberInput from '../../common/NumberInput';
import Select from '../../common/Select';
import Table, { Cell, Column, Row } from '../../common/Table';
import TextInput from '../../common/TextInput';

export interface CostCalculationTableProps {
    columns: Column[];
    getRowTotalValue: (costCalculation: CostCalculation) => string;
    handleChange: (value: Partial<CostCalculation>, serviceCategoryId: number) => void;
    isSpecialCostCalculation: (costCalculation: CostCalculation) => boolean;
    mainTableRef: RefObject<HTMLDivElement>;
}

const CostCalculationTable = ({ columns, getRowTotalValue, handleChange, isSpecialCostCalculation, mainTableRef }: CostCalculationTableProps) => {
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const closed = useProjectClosed();

    const getServiceCategoryCells = (costCalculation: CostCalculation, serviceCategoryId: number): Cell[] => [
        {
            content:
                <div className='serviceCategoryField'>
                    <TextInput
                        label={translate(TRANSLATIONS.main.nr)}
                        name='Nr.'
                        key={`${serviceCategoryId} serviceCategory`}
                        value={costCalculation.serviceCategory.index?.toString().padStart(2, '0') ?? '--'}
                        readOnly
                    />
                </div>,
            inputStyle: true
        },
        {
            content:
                !costCalculation.customName && costCalculation.serviceCategory.isNameEditable
                    ? (
                        <div>
                            <Select
                                key={`${serviceCategoryId} serviceCategoryName`}
                                label={translate(TRANSLATIONS.main.serviceCategoryName)}
                                value={costCalculation.serviceCategoryName ?? EMPTY_SERVICE_CATEGORY_NAME}
                                values={availableValues.serviceCategoryNames.filter(x => x.isSelectableForCustomCategory && !project?.costsCalculations?.map(y => y.serviceCategoryNameId).includes(x.id))}
                                mapToString={y => y.en}
                                mapToKey={y => `${y.id} ${y.en}`}
                                onSelect={y => handleChange({ serviceCategoryName: y, serviceCategoryNameId: y.id }, serviceCategoryId)}
                                disabled={closed}
                            />
                        </div>
                    )
                    : <TextInput label={translate(TRANSLATIONS.main.serviceCategoryName)} name='customName' value={availableValues.serviceCategories.find(x => x.index == costCalculation.serviceCategoryNameId)?.serviceCategoryName.en ?? costCalculation.customName ?? ''} readOnly />
            ,
            inputStyle: true
        }
    ];

    return (
        <Table
            tableRef={mainTableRef}
            scrollId={SCROLL_ID.COST_CALCULATION}
            noScrollbar
            columns={columns}
            rows={[
                ...availableValues?.serviceCategories?.map(x => project?.costsCalculations?.find(y => y.serviceCategoryId == x.id) ??
                {
                    ...EMPTY_COST_CALCULATION,
                    serviceCategory: x,
                    serviceCategoryName: x.serviceCategoryName,
                    serviceCategoryNameId: x.serviceCategoryNameId ?? 0,
                    serviceCategoryId: x.id
                }).map<Row>((x, index) => ({
                    key: addPrefixToKey(index.toFixed(), project?.id),
                    cells: [
                        ...getServiceCategoryCells(x, x.serviceCategoryId ?? 0),
                        {
                            content: <NumberInput
                                key={`${index} quantity`}
                                disabled={closed}
                                label={`${translate(TRANSLATIONS.main.quantity)} ${QuantityUnit[x.serviceCategory.quantityUnit]}`}
                                maxValue={MAX_INTEGER}
                                name='quantity'
                                numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                onChange={y => x.serviceCategoryId && handleChange({ quantity: Number(y) }, x.serviceCategoryId)}
                                tooltipId={`quantity_${x.serviceCategoryId?.toString()}`}
                                value={x.quantity?.toString() ?? ''}
                            />,
                            inputStyle: true
                        },
                        {
                            content: <NumberInput
                                label={`${translate(TRANSLATIONS.main.cost)}${isSpecialCostCalculation(x) ? ' (%)' : ''}`}
                                key={`${index} cost`}
                                name={`${isSpecialCostCalculation(x) ? 'additionalValue' : 'cost'}`}
                                value={isSpecialCostCalculation(x) ? x.additionalValue?.toString() ?? '' : x.cost?.toString() ?? x.serviceCategory.price?.toString() ?? ''}
                                onChange={y => x.serviceCategoryId && handleChange(isSpecialCostCalculation(x) ? { additionalValue: Number(y) } : { cost: Number(y) }, x.serviceCategoryId)}
                                disabled={closed}
                                percentageFromMultiplier={isSpecialCostCalculation(x)}
                                decimal
                                negative
                                maxValue={isSpecialCostCalculation(x) ? 100 : MAX_INTEGER}
                                numberFormat={NUMBER_FORMAT_WITH_SEPARATOR}
                                numberOfDigits={x.serviceCategory.index === 6 ? undefined : 2}
                                tooltipId={`cost_${x.serviceCategoryId?.toString()}`}
                            />,
                            inputStyle: true
                        },
                        {
                            content: convertStringToLocal(getRowTotalValue(x))
                        }
                    ]
                })) ?? [],
                { key: 'divider', divider: true, cells: [] }
            ]}
        />
    );
};

export default CostCalculationTable;
