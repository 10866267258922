import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHook } from '../../Hooks';
import { setOrderRequestData, setProjectHeaderDisabled } from '../../../store';
import { OrderRequest, DocumentGenerationData, OrderRequestData } from '../../../models';
import { useAppSelector } from '../../common';

export const useCostYearsCountriesModals = () => {
    const dispatch = useDispatch();
    const { onGenerateOrderRequest, onGenerateKueb } = useHook(x => x.documentGeneration)();
    const orderRequestData = useAppSelector(state => state.project.orderRequestData);
    const project = useAppSelector(state => state.project.project);

    const [showOrderRequestModal, setShowOrderRequestModal] = useState(false);
    const [showKuebModal, setShowKuebModal] = useState(false);
    const [kuebData, setKuebData] = useState<DocumentGenerationData | undefined>(undefined);
    const [orderRequest, setOrderRequest] = useState<OrderRequest | undefined>(undefined);

    const handleOrderRequestFormChange = (value: Partial<OrderRequestData>) => {
        orderRequestData && dispatch(setOrderRequestData({ ...orderRequestData, ...value }));
    };

    const handleOrderRequestModalCancel = () => {
        setShowOrderRequestModal(false);
        dispatch(setProjectHeaderDisabled(false));
    };

    const handleOrderRequestModalCreateDocument = () => {
        project && orderRequestData && orderRequest && onGenerateOrderRequest({ ...orderRequest, ...orderRequestData });
        setShowOrderRequestModal(false);
        dispatch(setProjectHeaderDisabled(false));
    };

    const handleKuebModalCancel = () => {
        setShowKuebModal(false);
        setKuebData(undefined);
        dispatch(setProjectHeaderDisabled(false));
    };

    const handleKuebModalCreateDocument = () => {
        project?.id && onGenerateKueb({ projectId: project.id, countryId: kuebData?.countryId ?? 0, fiscalYearId: kuebData?.fiscalYearId ?? 0 });
        setShowKuebModal(false);
        setKuebData(undefined);
        dispatch(setProjectHeaderDisabled(false));
    };

    return {
        handleOrderRequestFormChange,
        handleKuebModalCancel,
        handleKuebModalCreateDocument,
        handleOrderRequestModalCancel,
        handleOrderRequestModalCreateDocument,
        kuebData,
        orderRequest,
        setKuebData,
        setOrderRequest,
        setShowKuebModal,
        setShowOrderRequestModal,
        showKuebModal,
        showOrderRequestModal
    };
};
