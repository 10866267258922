import { createSlice } from '@reduxjs/toolkit';

export interface KeyCounterState {
    counter: number;
}

const initialState: KeyCounterState = {
    counter: 0
};

export const keyCounterSlice = createSlice({
    name: 'keyCounter',
    initialState,
    reducers: {
        incrementKeyCounter: (state: KeyCounterState) => {
            state.counter = state.counter + 1;
        }
    }
});

export const { incrementKeyCounter } = keyCounterSlice.actions;
export const keyCounterReducer = keyCounterSlice.reducer;
