import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { convertNumberToLocal } from '../../../services';
import { TechnicalInfo, VolumeOverview } from '../../../models';
import { DEFAULT_SELECTED_MACHINE_TYPE, EMPTY_MACHINE_SUBCATEGORY, EMPTY_VOLUME_OVERVIEW, TRANSLATIONS } from '../../../constants';
import { useHook } from '../../Hooks';
import { useAppSelector, useTranslate } from '../../common';
import { setProject, setSelectedDropdownValues } from '../../../store';
import { Column, Row } from '../../../components/common/Table';

export const UseVolumeOverview = () => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const selectedDropdownValues = useAppSelector(state => state.project.selectedDropdownValues);
    const machineType = selectedDropdownValues?.volumeOverviewMachineType;
    const { getUniqueKey } = useHook(x => x.uniqueKey)();

    useEffect(() => {
        if (!selectedDropdownValues.volumeOverviewMachineType) {
            dispatch(setSelectedDropdownValues({ volumeOverviewMachineType: DEFAULT_SELECTED_MACHINE_TYPE }));
        }
    }, [project]);

    const columns: Column[] = [
        { label: '', key: 'shortName', min: '65px', max: '65px' },
        { label: translate(TRANSLATIONS.main.machineType), min: '230px', max: '3fr', bold: true },
        { label: translate(TRANSLATIONS.main.oemType), min: '125px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.totalQuantity), min: '125px', max: '1fr', bold: true },
        { label: `${project?.country?.name ?? 'N/A'} ${translate(TRANSLATIONS.main.quantity)}`, min: '125px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.kpriceStation), min: '125px', max: '1fr', bold: true },
        { label: translate(TRANSLATIONS.main.totalVolume), min: '125px', max: '1fr', bold: true },
        { label: `${project?.country?.name ?? 'N/A'} ${translate(TRANSLATIONS.main.volume)}`, min: '125px', max: '1fr', bold: true },
        { label: '', key: 'deleteButton', min: '80px', max: '80px' }
    ];

    const addNewVolumeOverview = () => {
        project &&
            dispatch(setProject({
                ...project,
                volumeOverviews: [
                    ...project.volumeOverviews ?? [],
                    {
                        ...EMPTY_VOLUME_OVERVIEW,
                        projectId: project?.id.length ? project?.id : undefined,
                        machineSubCategory: {
                            ...EMPTY_MACHINE_SUBCATEGORY,
                            machineType: machineType
                        },
                        key: getUniqueKey()
                    }]
            }));
    };

    const removeVolumeOverview = (index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                volumeOverviews: [
                    ...project.volumeOverviews?.filter((x, i) => index !== i) ?? []
                ]
            }));
    };

    const handleChange = (value: Partial<VolumeOverview>, index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                volumeOverviews: [
                    ...project.volumeOverviews?.map((x, i) => index === i ? { ...x, ...value } : x) ?? []
                ]
            }));
    };

    const handleTechnicalInfoChange = (value: Partial<TechnicalInfo>) =>
        project?.technicalInfo && dispatch(setProject({ ...project, technicalInfo: { ...project.technicalInfo, ...value } }));


    const getBottomRows = (volumeOverviews: VolumeOverview[] = []): Row[] => {
        const totalPrice = volumeOverviews.reduce((sum, x) =>
            sum + ((x.totalQuantity ?? 0) * (x.price ?? 0))
            , 0);

        const countryTotalPrice = volumeOverviews.reduce((sum, x) =>
            sum + ((x.countryQuantity ?? 0) * (x.price ?? 0))
            , 0);

        const machineTypeTotalPrice = volumeOverviews.reduce((sum, x) =>
            x.machineSubCategory?.machineType?.name === machineType?.name
                ? sum + ((x.totalQuantity ?? 0) * (x.price ?? 0))
                : sum
            , 0);

        const countryMachineTypeTotalPrice = volumeOverviews.reduce((sum, x) =>
            x.machineSubCategory?.machineType?.name === machineType?.name
                ? sum + ((x.countryQuantity ?? 0) * (x.price ?? 0))
                : sum
            , 0);

        return [
            {
                key: 'totalVolume',
                cells: [
                    { content: '' },
                    { content: translate(TRANSLATIONS.main.total) },
                    { content: '' },
                    { content: '' },
                    { content: '' },
                    { content: '' },
                    { content: convertNumberToLocal(totalPrice) },
                    { content: convertNumberToLocal(countryTotalPrice) }
                ]
            },
            ...machineType?.name !== 'All'
                ? [{
                    key: 'sum',
                    cells: [
                        { content: '' },
                        { content: `${translate(TRANSLATIONS.main.sum)} ${machineType?.name}` },
                        { content: '' },
                        { content: '' },
                        { content: '' },
                        { content: '' },
                        { content: convertNumberToLocal(machineTypeTotalPrice) },
                        { content: convertNumberToLocal(countryMachineTypeTotalPrice) }
                    ]
                }]
                : []
        ];
    };

    return {
        columns,
        machineType,
        addNewVolumeOverview,
        removeVolumeOverview,
        handleChange,
        handleTechnicalInfoChange,
        getBottomRows
    };
};
