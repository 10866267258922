import { useDispatch } from 'react-redux';
import { Api, clearIncompleteDatesFromReportRequest, fileDownload } from '../../services';
import { DocumentGenerationData, OrderRequest, ReportRequest } from '../../models';
import { TRANSLATIONS } from '../../constants';
import { useHook } from '../Hooks';
import { addSnackbar, setPegaLoading, setProjectLoading, setReportPageLoading } from '../../store';
import { SnackbarType } from '../../components/common/Snackbar';
import { useAppSelector, useTranslate } from '../common';

export const useDocumentGeneration = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const project = useAppSelector(state => state.project.project);
    const projectChanged = useAppSelector(state => state.project.projectChanged);
    const { onProjectUpdate } = useHook(x => x.projectSave)();

    const handleGenerateOrderOverview = async (orderOverview: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateOrderOverview(orderOverview),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateOrderOverview), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGenerateOrderRequest = async (orderRequest: OrderRequest) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateOrderRequest(orderRequest),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateOrderRequest), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGeneratePega = async (reviewDocumentClicked?: boolean, lockProject?: boolean) => {
        let hasError = false;

        dispatch(setPegaLoading(true));

        if (project?.id) {
            await fileDownload(
                Api.generatePega(project?.id),
                async () => {
                    hasError = true;
                    reviewDocumentClicked
                        ? dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.couldNotGenerateDocument), type: SnackbarType.Error }))
                        : dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.couldNotStartThePegaWorkflow), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.pdf`,
                undefined,
                true
            );
        } else {
            hasError = true;
            reviewDocumentClicked
                ? dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.couldNotGenerateDocument), type: SnackbarType.Error }))
                : dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.couldNotStartThePegaWorkflow), type: SnackbarType.Error }));
        }

        if (!hasError) {
            if (lockProject && project) {
                const updateSuccess = await onProjectUpdate({ ...project, isLocked: true, lockedFrom: new Date().toISOString() });
                updateSuccess && 
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.pegaWorkflowStartedSuccessfully), type: SnackbarType.Success }));
            }
        }

        dispatch(setPegaLoading(false));

        return !hasError;
    };

    const handleGenerateSaLight = async (saLightData: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateSaLight(saLightData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateSaLight), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGenerateKueb = async (kuebData: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generateKueb(kuebData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateKueb), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.xlsx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGeneratePoa = async (poaData: DocumentGenerationData) => {
        let saveSuccess = false;

        dispatch(setProjectLoading(true));

        if (projectChanged) {
            saveSuccess = await onProjectUpdate();
        } 

        if (saveSuccess || !projectChanged) {
            await fileDownload(
                Api.generatePoa(poaData),
                async () => {
                    dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGeneratePoa), type: SnackbarType.Error }));
                },
                `${project?.name ?? 'document'}.docx`
            );
        }
        
        dispatch(setProjectLoading(false));
    };

    const handleGenerateReport = async (reportRequest: ReportRequest) => {
        dispatch(setReportPageLoading(true));

        await fileDownload(
            Api.generateReport(clearIncompleteDatesFromReportRequest(reportRequest)),
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotGenerateReport), type: SnackbarType.Error }));
            },
            `${reportRequest.layout?.name}.xlsx`
        );

        dispatch(setReportPageLoading(false));
    };

    return {
        onGenerateOrderOverview: handleGenerateOrderOverview,
        onGenerateOrderRequest: handleGenerateOrderRequest,
        onGeneratePega: handleGeneratePega,
        onGenerateSaLight: handleGenerateSaLight,
        onGenerateKueb: handleGenerateKueb,
        onGeneratePoa: handleGeneratePoa,
        onGenerateReport: handleGenerateReport
    };
};
