import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TRANSLATIONS } from '../../../constants';
import { HeaderTabType } from '../../../enums';
import { CostsPerYearService } from '../../../models';
import { useHook } from '../../../hooks';
import { useTranslate } from '../../../hooks/common';
import { setActiveTabType } from '../../../store';
import HistoryHeader from './HistoryHeader';
import TotalCostsTable from './TotalCostsTable';
import CostsByYearsTable from './CostsByYearsTable';
import VolumeByYearsTable from './VolumeByYearsTable';
import Title from '../../common/Title';

export interface ServicesWithFiscalYear {
    services: CostsPerYearService[],
    fiscalYearId: number
}

const HistoryTab = () => {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { costsTotal, costsTotalVolume, currentHistory, currentlyShownServices, getPlannedOrActualCost, getServiceColumns, handleServiceClick } = useHook(x => x.history)();


    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.History));
    }, []);

    return(
        <div style={{ marginTop: 40, marginBottom: 60 }}>
            <HistoryHeader costsTotalVolume={costsTotalVolume} currentHistory={currentHistory} getPlannedOrActualCost={getPlannedOrActualCost} />
            <Title style={{ marginTop: 20 }}>{translate(TRANSLATIONS.main.totalCosts)}</Title>
            <TotalCostsTable
                costsTotalVolume={costsTotalVolume}
                currentHistory={currentHistory}
                currentlyShownServices={currentlyShownServices}
                getServiceColumns={getServiceColumns}
                handleServiceClick={handleServiceClick}
            />
            <Title style={{ marginTop: 20 }}>{translate(TRANSLATIONS.main.costsByYears)}</Title>
            <CostsByYearsTable
                costsTotal={costsTotal}
                currentHistory={currentHistory}
                currentlyShownServices={currentlyShownServices}
                getPlannedOrActualCost={getPlannedOrActualCost}
                getServiceColumns={getServiceColumns}
                handleServiceClick={handleServiceClick}
            />
            <Title style={{ marginTop: 20 }}>{translate(TRANSLATIONS.main.volumeByYears)}</Title>
            <VolumeByYearsTable currentHistory={currentHistory} />
        </div>
    );
};

export default HistoryTab;
