import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Api, apiCall } from '../../../services';
import { ProjectListItem, OrderedPaginatedList } from '../../../models';
import { LOCKED_PROJECT_ROLES, REMINDED_PROJECT_ROLES, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useMounted, useTranslate } from '../../common';
import { SnackbarType } from '../../../components/common/Snackbar';
import { addSnackbar, setProjectLockedPageAndOrdering, setProjectRemindedPageAndOrdering } from '../../../store';

export const useLockedAndRemindedProjects = () => {
    const dispatch = useDispatch();
    const projectLockedPageAndOrdering = useAppSelector(state => state.project.projectLockedPageAndOrdering);
    const projectRemindedPageAndOrdering = useAppSelector(state => state.project.projectRemindedPageAndOrdering);
    const translate = useTranslate();
    const userRoles = useAppSelector(state => state.user.roles);
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const mounted = useMounted();
    const [lockedProjects, setLockedProjects] = useState<OrderedPaginatedList<ProjectListItem> | null>(null);
    const [lockedProjectsLoading, setLockedProjectsLoading] = useState(false);
    const [remindedProjects, setRemindedProjects] = useState<OrderedPaginatedList<ProjectListItem> | null>(null);
    const [remindedProjectsLoading, setRemindedProjectsLoading] = useState(false);
    const hasRemindedProjects = Boolean(userRoles.length && REMINDED_PROJECT_ROLES.some(x => userRoles.map(y => y.name).includes(x)));
    const hasLockedProjects = Boolean(userRoles.length && LOCKED_PROJECT_ROLES.some(x => userRoles.map(y => y.name).includes(x)));

    useEffect(() => {
        if (isAuthenticated && accounts[0]) {
            hasLockedProjects && makeLockedProjectsApiCall(projectLockedPageAndOrdering?.page, projectLockedPageAndOrdering?.orderBy, projectLockedPageAndOrdering?.orderType);
            hasRemindedProjects && makeRemindedProjectsApiCall(projectRemindedPageAndOrdering?.page, projectRemindedPageAndOrdering?.orderBy, projectRemindedPageAndOrdering?.orderType);
        }
    }, [isAuthenticated, accounts[0]]);

    useEffect(() => {
        lockedProjects && dispatch(setProjectLockedPageAndOrdering({ page: lockedProjects.pageNumber, orderBy: lockedProjects.orderBy, orderType: lockedProjects.orderType }));
    }, [lockedProjects]);

    useEffect(() => {
        remindedProjects && dispatch(setProjectRemindedPageAndOrdering({ page: remindedProjects.pageNumber, orderBy: remindedProjects.orderBy, orderType: remindedProjects.orderType }));
    }, [remindedProjects]);

    const makeLockedProjectsApiCall = async (page = 1, orderBy?: string, orderType?: string) => {
        mounted.current && setLockedProjectsLoading(true);

        await apiCall(
            Api.getLockedProjects(page, orderBy, orderType),
            async x => {
                mounted.current && setLockedProjects(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadProjects), type: SnackbarType.Error }));
            }
        );

        mounted.current && setLockedProjectsLoading(false);
    };

    const makeRemindedProjectsApiCall = async (page = 1, orderBy?: string, orderType?: string) => {
        mounted.current && setRemindedProjectsLoading(true);

        await apiCall(
            Api.getRemindedProjects(page, orderBy, orderType),
            async x => {
                mounted.current && setRemindedProjects(x.data);
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadProjects), type: SnackbarType.Error }));
            }
        );

        mounted.current && setRemindedProjectsLoading(false);
    };

    const handleLockedProjectsPaging = (page: number) => {
        makeLockedProjectsApiCall(page + 1, lockedProjects?.orderBy, lockedProjects?.orderType);
    };

    const handleRemindedProjectsPaging = (page: number) => {
        makeRemindedProjectsApiCall(page + 1, remindedProjects?.orderBy, remindedProjects?.orderType);
    };

    const handleLockedProjectsOrdering = (orderBy: string, orderType: string) => {
        makeLockedProjectsApiCall(1, orderBy, orderType);
    };

    const handleRemindedProjectsOrdering = (orderBy: string, orderType: string) => {
        makeRemindedProjectsApiCall(1, orderBy, orderType);
    };

    return {
        lockedProjects: lockedProjects,
        lockedProjectsLoading: lockedProjectsLoading,
        remindedProjects: remindedProjects,
        remindedProjectsLoading: remindedProjectsLoading,
        showLockedProjects: hasLockedProjects,
        showRemindedProjects: hasRemindedProjects,
        onLockedProjectsPaging: handleLockedProjectsPaging,
        onLockedProjectsOrdering: handleLockedProjectsOrdering,
        onRemindedProjectsPaging: handleRemindedProjectsPaging,
        onRemindedProjectsOrdering: handleRemindedProjectsOrdering
    };
};
