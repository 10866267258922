import { EMPTY_SERVICE_CATEGORY_NAME } from './EmptyServiceCategoryName';
import { CostCalculation } from '../models';
import { EMPTY_SERVICE_CATEGORY } from './EmptyServiceCategory';

export const EMPTY_COST_CALCULATION: CostCalculation = {
    serviceCategory: EMPTY_SERVICE_CATEGORY,
    serviceCategoryName: EMPTY_SERVICE_CATEGORY_NAME,
    customName: '',
    quantity: 0
};
