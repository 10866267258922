import React, { ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';


interface StartSwitchProps {
    children: ReactNode;
    start: JSX.Element;
}

const StartSwitch = ({ children, start }: StartSwitchProps): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();

    return <>{isAuthenticated ? start : children}</>;
};

export default StartSwitch;
