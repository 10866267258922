import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconType } from '../../../components/common/Icon';
import { Cell, Column } from '../../../components/common/Table';
import { EMPTY_COST_PER_YEAR, EMPTY_COST_PER_YEAR_SERVICE, MAX_INTEGER, NUMBER_LOCALE } from '../../../constants';
import { InputColors } from '../../../enums';
import { CostsPerYear, CostsPerYearService } from '../../../models';
import { convertNumberToDecimalString, getCostCalculations, getServiceName, getUniqueValues, sortBy } from '../../../services';
import { useHook } from '../../Hooks';
import { useAppSelector } from '../../common';
import { setProject } from '../../../store';

export const useCostYears = () => {

    const dispatch = useDispatch();
    const availableValues = useAppSelector(state => state.project.availableValues);
    const project = useAppSelector(state => state.project.project);
    const { getUniqueKey } = useHook(x => x.uniqueKey)();

    const costCalculations = project?.costsCalculations?.filter(x => (x.cost ?? 0) > 0 || x.quantity > 0);
    const costYearsServices = project?.costsPerYears?.flatMap(x => x.services.filter(y => (y.actualCost ?? 0) || (y.plannedCost ?? 0)));
    const countryCostYearServices = project?.countryCostsPerYears?.flatMap(x => x.countryCostsPerYearServices.filter(y => (y.actualCost ?? 0) || (y.plannedCost ?? 0)));
    const allServiceTypes = getCostCalculations(costCalculations ?? [], [...costYearsServices ?? [], ...countryCostYearServices ?? []], availableValues);

    const serviceArray = sortBy([...costCalculations ?? [], ...getUniqueValues(allServiceTypes ?? [], 'serviceCategoryId') ?? []], x => x.serviceCategory.index) ?? [];
    const serviceDisplayLimit = 4;
    const [currentServiceStartIndex, setCurrentServiceStartIndex] = useState(0);
    const currentlyShownServices = serviceArray.slice(currentServiceStartIndex, currentServiceStartIndex + serviceDisplayLimit);

    const addCostPerYear = () => {
        project &&
            dispatch(setProject({
                ...project,
                costsPerYears: [
                    ...project.costsPerYears ?? [],
                    {
                        ...EMPTY_COST_PER_YEAR,
                        projectId: project?.id.length ? project?.id : undefined,
                        key: getUniqueKey()
                    }]
            }));
    };

    const removeCostPerYear = (index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                costsPerYears: [
                    ...project.costsPerYears?.filter((x, i) => index !== i) ?? []
                ]
            }));
    };

    const fiscalYearSorting = (a: CostsPerYear, b: CostsPerYear) => {
        const fiscalYearOrderA = availableValues.fiscalYears.find(x => x.id == a.fiscalYearId)?.order ?? MAX_INTEGER;
        const fiscalYearOrderB = availableValues.fiscalYears.find(x => x.id == b.fiscalYearId)?.order ?? MAX_INTEGER;

        return fiscalYearOrderA < fiscalYearOrderB ? -1 : 1;
    };

    const handleChange = (value: Partial<CostsPerYear>, index: number) => {
        project &&
            dispatch(setProject({
                ...project,
                costsPerYears: [
                    ...project.costsPerYears?.map((x, i) => index === i ? { ...x, ...value } : x) ?? []
                ]
            }));
    };

    const handleServiceClick = (index: number) => {
        index === 3 && currentServiceStartIndex > 0 && setCurrentServiceStartIndex(currentServiceStartIndex - 1);
        index === 3 + serviceDisplayLimit - 1 && currentServiceStartIndex < serviceArray.length - serviceDisplayLimit && setCurrentServiceStartIndex(currentServiceStartIndex + 1);
    };

    const handleServiceCostChange = (value: Partial<CostsPerYearService>, textValue: string, serviceCategoryId: number, inputCostsPerYearIndex: number) => {
        const currentCostsPerYearServices = project?.costsPerYears?.find((x, i) => i == inputCostsPerYearIndex)?.services ?? [{ ...EMPTY_COST_PER_YEAR_SERVICE, serviceCategoryId: serviceCategoryId }];
        const newCostPerYearServices = currentCostsPerYearServices.find(x => serviceCategoryId == x.serviceCategoryId)
            ? currentCostsPerYearServices.map(x => serviceCategoryId === x.serviceCategoryId ? { ...x, ...value } : x)
            : [...currentCostsPerYearServices, { ...EMPTY_COST_PER_YEAR_SERVICE, serviceCategoryId: serviceCategoryId, ...value }];

        !isNaN(Number(textValue)) && handleChange({ services: newCostPerYearServices }, inputCostsPerYearIndex);
    };

    const getCellColor = (fiscalYearId: number, serviceCategoryId: number, plannedCost?: number, displayOnly?: boolean) => {
        let cellColor: InputColors | undefined = undefined;
        const hasHistory = project?.histories?.length && project.histories.flatMap(x => x.costsPerYears).find(x => x?.fiscalYearId == fiscalYearId);
        const lastApprovedCost = project?.histories?.length && project.histories[project.histories.length - 1]
            .costsPerYears?.find(x => x.fiscalYearId == fiscalYearId)?.services
            .find(x => x.serviceCategoryId == serviceCategoryId)?.plannedCost;
        const isHistoryEntryTheSame = Boolean(lastApprovedCost && lastApprovedCost == (plannedCost ?? 0));
        const isHistoryEntryExceeded = Boolean(lastApprovedCost && lastApprovedCost > (plannedCost ?? 0));

        cellColor = hasHistory
            ? displayOnly
                ? InputColors.DisplayOnly
                : lastApprovedCost
                    ? isHistoryEntryExceeded
                        ? InputColors.Yellow
                        : !isHistoryEntryTheSame
                            ? InputColors.Red
                            : undefined
                    : plannedCost
                        ? InputColors.Red
                        : undefined
            : displayOnly
                ? InputColors.DisplayOnlyRed
                : InputColors.Red;

        return cellColor;
    };

    const getServiceActualCostsForRow = (services: CostsPerYearService[]): Cell[] => {
        return currentlyShownServices.map(y => services.find(z => z.serviceCategoryId == y.serviceCategoryId) ?? EMPTY_COST_PER_YEAR_SERVICE)
            .map<Cell>(x => ({ content: convertNumberToDecimalString(x.actualCost ?? 0, 2, NUMBER_LOCALE) }));
    };

    const getServiceColumns = (): Column[] => currentlyShownServices
        .map<Column>((x, i) =>
            i !== 0 && i !== currentlyShownServices.length - 1
                ? {
                    key: i,
                    label: getServiceName(x, availableValues),
                    min: '90px',
                    max: '3fr',
                    bold: true,
                    tooltip: getServiceName(x, availableValues),
                    notClickable: true
                }
                : i == 0
                    ? {
                        key: i,
                        label: getServiceName(x, availableValues),
                        min: '90px',
                        max: '3fr',
                        bold: true,
                        button: { icon: IconType.ArrowheadLeft, position: 'left' },
                        tooltip: getServiceName(x, availableValues),
                        notClickable: currentServiceStartIndex < 1
                    }
                    : {

                        key: i,
                        label: getServiceName(x, availableValues),
                        min: '90px',
                        max: '3fr',
                        bold: true,
                        button: { icon: IconType.ArrowheadRight, position: 'right' },
                        tooltip: getServiceName(x, availableValues),
                        notClickable: currentServiceStartIndex + serviceDisplayLimit >= serviceArray.length
                    }
        );

    return {
        addCostPerYear,
        currentlyShownServices,
        removeCostPerYear,
        fiscalYearSorting,
        handleChange,
        handleServiceClick,
        handleServiceCostChange,
        getCellColor,
        getServiceActualCostsForRow,
        getServiceColumns,
        serviceArray
    };
};
