import React, { RefObject } from 'react';
import { addPrefixToKey, convertNumberToLocal, getScrollToElement, sum, sumArray } from '../../../services';
import { EMPTY_COST_PER_YEAR, EMPTY_COUNTRY_COST_PER_YEAR, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { CostCalculation, CostsPerYear, CostsPerYearService, CountryCostsPerYear, CountryCostsPerYearService, FiscalYear } from '../../../models';
import { useAppSelector, useScrolledPast, useTranslate } from '../../../hooks/common';
import { InputColors } from '../../../enums';
import NumberInput from '../../common/NumberInput';
import Table, { Cell, Column } from '../../common/Table';

export interface CostYearsCountriesStickyTableProps {
    currentServiceStartIndex: number;
    fiscalYearSelected: FiscalYear;
    getServiceColumns: (serviceList: CostCalculation[], currentServiceStartIndex: number, serviceDisplayLimit: number, serviceArray: CostCalculation[]) => Column[];
    handleServiceClick: (index: number) => void;
    mainTableRef: RefObject<HTMLDivElement>;
    serviceArray: CostCalculation[]
    serviceDisplayLimit: number;
    serviceListCurrent: CostCalculation[];
}

const CostYearsCountriesStickyTable = ({
    currentServiceStartIndex,
    fiscalYearSelected,
    getServiceColumns,
    handleServiceClick,
    mainTableRef,
    serviceArray,
    serviceDisplayLimit,
    serviceListCurrent
}: CostYearsCountriesStickyTableProps) => {
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const scrolledPastHeader = useScrolledPast(getScrollToElement(mainTableRef));

    const getPlannedSums = (services: CostsPerYear[]) => services.map(s => sum(s.services ?? [], 'plannedCost') ?? 0);

    const getAssignedSums = (services: CountryCostsPerYear[]) => services.map(s => sum(s.countryCostsPerYearServices ?? [], 'plannedCost') ?? 0);

    const getChargedSums = (services: CountryCostsPerYear[]) => services.map(s => sum(s.countryCostsPerYearServices ?? [], 'actualCost') ?? 0);

    const countryCostsPerYears = project?.countryCostsPerYears ?? [];

    const getArraySum = (arrayToSum: number[]) => arrayToSum
        .reduce((currentSum, x) =>
            x > 0
                ? currentSum + x ?? 0
                : currentSum
            , 0);

    const plannedTotalSum = getArraySum(getPlannedSums((project?.costsPerYears ?? [EMPTY_COST_PER_YEAR]).filter(x => x.fiscalYearId === fiscalYearSelected?.id)));
    const assignedTotalSum = getArraySum(getAssignedSums(countryCostsPerYears.filter(x => x.fiscalYearId == fiscalYearSelected?.id)));
    const chargedTotalSum = getArraySum(getChargedSums(countryCostsPerYears.filter(x => x.fiscalYearId == fiscalYearSelected?.id)));

    const getTotalSumPerService = <K extends keyof CountryCostsPerYearService>(countryCostsPerYear: CountryCostsPerYear[], propertyValue: K) => {
        const arrayToSum = countryCostsPerYear
            .map(x => currentlyShownServiceIds
                .map(y => x.countryCostsPerYearServices
                    .map(z => z.serviceCategoryId).includes(Number(y))
                    ? x.countryCostsPerYearServices[x.countryCostsPerYearServices.findIndex(v => v.serviceCategoryId == y)][propertyValue] ?? 0
                    : 0
                )
            );

        return sumArray(arrayToSum);
    };

    const getTotalCostPerYearsSumPerService = <K extends keyof CostsPerYearService>(costsPerYear: CostsPerYear[], propertyValue: K) => {
        const arrayToSum = costsPerYear
            .map(x => currentlyShownServiceIds
                .map(y => x.services
                    .map(z => z.serviceCategoryId).includes(Number(y))
                    ? x.services[x.services.findIndex(v => v.serviceCategoryId == y)][propertyValue] ?? 0
                    : 0
                )
            );

        return sumArray(arrayToSum);
    };

    const costPerYearsServicesForFiscalYear: CostsPerYear[] = [project?.costsPerYears?.find(x => x.fiscalYearId === fiscalYearSelected?.id) ?? EMPTY_COST_PER_YEAR];
    const countryCostsPerYearForDisplay = countryCostsPerYears.some(x => x.fiscalYearId === fiscalYearSelected?.id)
        ? countryCostsPerYears.filter(x => x.fiscalYearId == fiscalYearSelected?.id)
        : [EMPTY_COUNTRY_COST_PER_YEAR];
    const currentlyShownServiceIds = serviceListCurrent.map(x => x.serviceCategoryId);

    const chargedSumsPerService = getTotalSumPerService(countryCostsPerYearForDisplay, 'actualCost');
    const chargedSumPerServiceCells = chargedSumsPerService.map<Cell>(x => ({ content: convertNumberToLocal(x) }));

    const plannedSumsPerService = getTotalCostPerYearsSumPerService(costPerYearsServicesForFiscalYear, 'plannedCost');
    const plannedSumsPerServiceCells = plannedSumsPerService.map<Cell>(x => ({ content: convertNumberToLocal(x) }));

    const assignedSumsPerService = getTotalSumPerService(countryCostsPerYearForDisplay, 'plannedCost');
    const assignedSumsPerServiceCells = assignedSumsPerService.map<Cell>((x, i) => ({
        content:
            <NumberInput
                color={x > plannedSumsPerService[i] ? InputColors.DisplayOnlyRed : InputColors.DisplayOnly}
                label={convertNumberToLocal(x)}
                key={`${i} ${x} assignedTotalCostForService`}
                name=''
                value=''
                readOnly
            />,
        inputStyle: true
    }));

    const totalRows = [
        {
            key: addPrefixToKey('Plan', project?.id),
            cells: [
                { content: translate(TRANSLATIONS.main.total) },
                { content: translate(TRANSLATIONS.main.fyPlan) },
                { content: convertNumberToLocal(plannedTotalSum) },
                ...plannedSumsPerServiceCells
            ]
        },
        {
            key: addPrefixToKey('Assigned', project?.id),
            cells: [
                { content: translate(TRANSLATIONS.main.total) },
                { content: translate(TRANSLATIONS.main.fyAssigned) },
                {
                    content:
                        <NumberInput
                            color={assignedTotalSum > plannedTotalSum ? InputColors.DisplayOnlyRed : InputColors.DisplayOnly}
                            label={convertNumberToLocal(assignedTotalSum)}
                            key={`${project?.id ?? 'new-project'} assignedTotalSum`}
                            name=''
                            value=''
                            readOnly
                        />,
                    inputStyle: true
                },
                ...assignedSumsPerServiceCells
            ]
        },
        {
            key: addPrefixToKey('Charged', project?.id),
            cells: [
                { content: translate(TRANSLATIONS.main.total) },
                { content: translate(TRANSLATIONS.main.fyCharged) },
                { content: convertNumberToLocal(chargedTotalSum) },
                ...chargedSumPerServiceCells
            ]
        }
    ];

    return <Table
        scrollId={SCROLL_ID.COST_YEARS_COUNTRIES}
        noHeader={!scrolledPastHeader}
        onHeaderClick={handleServiceClick}
        noHeaderLineBreak={true}
        columns={[
            { key: 'fiscalYear', label: '', min: '130px', max: '1fr', bold: true, notClickable: true },
            { label: translate(TRANSLATIONS.main.costType), min: '120px', max: '2fr', bold: true, notClickable: true },
            { label: translate(TRANSLATIONS.main.totalCosts), min: '120px', max: '2fr', bold: true, notClickable: true },
            ...getServiceColumns(serviceListCurrent, currentServiceStartIndex, serviceDisplayLimit, serviceArray),
            { key: 'deleteIcon', label: '', min: '80px', max: '80px', notClickable: true }
        ]}
        rows={[
            ...totalRows
        ].flat()}
    />;
};

export default CostYearsCountriesStickyTable;
