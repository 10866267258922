import React, { RefObject } from 'react';
import { convertStringToLocal, getScrollToElement } from '../../../services';
import { SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { useScrolledPast, useTranslate } from '../../../hooks/common';

import Sticky from '../../common/Sticky';
import Table, { Column } from '../../common/Table';

export interface CostCalculationStickyTableProps {
    columns: Column[];
    getFixedDigitTotalCostsCalculated: () => string;
    mainTableRef: RefObject<HTMLDivElement>;
}

const CostCalculationStickyTable = ({ columns, getFixedDigitTotalCostsCalculated, mainTableRef }: CostCalculationStickyTableProps) => {
    const scrolledPastHeader = useScrolledPast(getScrollToElement(mainTableRef));
    const translate = useTranslate();

    return (
        <Sticky anchor='bottom'>
            <Table
                scrollId={SCROLL_ID.COST_CALCULATION}
                noHeader={!scrolledPastHeader}
                columns={columns}
                rows={[{
                    key: 'total',
                    cells: [
                        { content: translate(TRANSLATIONS.main.total) },
                        { content: '' },
                        { content: '' },
                        { content: '' },
                        { content: convertStringToLocal(getFixedDigitTotalCostsCalculated()) }
                    ]
                }]}
            />
        </Sticky>
    );
};

export default CostCalculationStickyTable;
