import { ApiBase } from '.';
import {
    ProjectListItem, DataResult, PaginatedList, FilterValues, History, Project, Account, AvailableValues, OrderedPaginatedList, Company, Contact, OrderedPaginatedListWithIds, ProjectAttachment,
    OrderRequest, BlobResponse, ReportRequest, DocumentGenerationData, Layout, LayoutSettingsTemplate, LayoutSettingsTemplateCreate, LayoutSettingsTemplateUpdate, FiscalYear, KafkaImportResult
} from '../models';
import { queryBuilder } from './ApiHelper';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api`;

export class Api extends ApiBase {

    public static authorize = () => Api.get(`${baseUrl}/account`) as Promise<DataResult<Account>>;

    public static approveProject = (approveData: Partial<History>) => Api.post(`${baseUrl}/History`, approveData) as Promise<DataResult<History>>;

    public static getSearchedProjects = (filterValues: FilterValues, page: number, orderBy?: string, orderType?: string) => Api.get(
        `${baseUrl}/Project/searchedprojects?PageNumber=${page}&ProjectName=${encodeURIComponent(filterValues.projectName)}`
            + `&CustomerName=${encodeURIComponent(filterValues.customerName)}&ResponsibleName=${encodeURIComponent(filterValues.responsibleName)}`
            + `&SapNr=${encodeURIComponent(filterValues.sapNr)}&ProjectStatusesRaw=${filterValues.statuses.join(';')}${orderBy ? `&OrderBy=${encodeURIComponent(orderBy)}` : ''}`
            + `${orderType ? `&OrderType=${encodeURIComponent(orderType)}` : ''}`
        ) as Promise<DataResult<OrderedPaginatedListWithIds<ProjectListItem, string>>>;

    public static getProject = (id: string) => Api.get(`${baseUrl}/Project/${encodeURIComponent(id)}`) as Promise<DataResult<Project>>;

    public static saveProject = (project: Project) => Api.post(`${baseUrl}/Project`, project) as Promise<DataResult<Project>>;

    public static updateProject = (project: Project) => Api.put(`${baseUrl}/Project`, project) as Promise<DataResult<Project>>;

    public static generateOrderRequest = (orderRequest: OrderRequest) => Api.getFile(`${baseUrl}/Documents/orderRequest?ProjectId=${orderRequest.projectId}&CountryId=${orderRequest.countryId}`
                    + `&FiscalYearId=${orderRequest.fiscalYearId}&Maingroup=${orderRequest.mainGroup}&Description=${orderRequest.description}&Volume=${orderRequest.volume}`
                    + `&Contact=${orderRequest.contact}`) as Promise<BlobResponse>;

    public static generateOrderOverview = (orderRequest: DocumentGenerationData) => Api.getFile(`${baseUrl}/Documents/orderOverview?ProjectId=${orderRequest.projectId}&CountryId=${orderRequest.countryId}`
                    + `&FiscalYearId=${orderRequest.fiscalYearId}`) as Promise<BlobResponse>;

    public static generatePega = (id: string) => Api.getFile(`${baseUrl}/Documents/pega?ProjectId=${id}`, 'application/pdf') as Promise<BlobResponse>;
    
    public static generatePoa = (poaData: DocumentGenerationData) => Api.getFile(`${baseUrl}/Documents/poa?ProjectId=${poaData.projectId}&CountryId=${poaData.countryId}`
                    + `&FiscalYearId=${poaData.fiscalYearId}`) as Promise<BlobResponse>;

    public static generateSaLight = (saLightData: DocumentGenerationData) => Api.getFile(`${baseUrl}/Documents/salight2?ProjectId=${saLightData.projectId}&CountryId=${saLightData.countryId}`
                    + `&FiscalYearId=${saLightData.fiscalYearId}`) as Promise<BlobResponse>;
                    
    public static generateKueb = (kuebData: DocumentGenerationData) => Api.getFile(`${baseUrl}/Documents/kueb?ProjectId=${kuebData.projectId}&CountryId=${kuebData.countryId}`
                    + `&FiscalYearId=${kuebData.fiscalYearId}`) as Promise<BlobResponse>;

    public static getProjects = (page = 1) => Api.get(`${baseUrl}/Project/PageNumber=${page}`) as Promise<DataResult<PaginatedList<ProjectListItem>>>;

    public static getLockedProjects = (page = 1, orderBy?: string, orderType?: string) => Api.get(
        `${baseUrl}/Project/lockedprojects?PageNumber=${page}${orderBy ? `&OrderBy=${encodeURIComponent(orderBy)}` : ''}${orderType ? `&OrderType=${encodeURIComponent(orderType)}` : ''}`
    ) as Promise<DataResult<OrderedPaginatedList<ProjectListItem>>>;

    public static getRemindedProjects = (page = 1, orderBy?: string, orderType?: string) => Api.get(
        `${baseUrl}/Project/remindedprojects?PageNumber=${page}${orderBy ? `&OrderBy=${encodeURIComponent(orderBy)}` : ''}${orderType ? `&OrderType=${encodeURIComponent(orderType)}` : ''}`
    ) as Promise<DataResult<OrderedPaginatedList<ProjectListItem>>>;

    public static getAvailableValues = () => Api.get(`${baseUrl}/Project/availablevalues`) as Promise<DataResult<AvailableValues>>;

    public static getCurrentFiscalYear = () => Api.get(`${baseUrl}/FiscalYear/currentfiscalyear`) as Promise<DataResult<FiscalYear>>;

    public static getFiscalYears = () => Api.get(`${baseUrl}/FiscalYear/fiscalyears`) as Promise<DataResult<FiscalYear[]>>;

    public static saveFiscalYear = (fiscalYear: FiscalYear) => Api.put(`${baseUrl}/FiscalYear`, fiscalYear) as Promise<DataResult<FiscalYear>>;

    public static saveCompany = (company: Company) => Api.post(`${baseUrl}/Company`, company, true) as Promise<DataResult<Company>>;

    public static saveContact = (contact: Contact) => Api.post(`${baseUrl}/Contact`, contact, true) as Promise<DataResult<Contact>>;

    public static getProjectAttachments = (id: string) => Api.get(`${baseUrl}/ProjectAttachment/${id}`) as Promise<DataResult<ProjectAttachment[]>>;

    public static saveProjectAttachment(data: FormData) {
        return Api.post(`${baseUrl}/ProjectAttachment`, data, false, 'none') as Promise<DataResult<ProjectAttachment>>;
    }

    public static deleteProjectAttachments = (ids: string[], projectId: string) => {
        const idParameters = ids.map(id => `id=${id}`).join('&');
        return Api.delete(`${baseUrl}/ProjectAttachment?${idParameters}&projectId=${projectId}`) as Promise<DataResult<boolean>>;
    };

    public static downloadAttachment = (fileName: string, projectId: string) => Api.getFile(`${baseUrl}/ProjectAttachment/download/${fileName}/${projectId}`) as Promise<BlobResponse>;

    public static generateReport = (reportRequest: ReportRequest) => Api.getFile(`${baseUrl}/Report/report?${queryBuilder({
        classes: reportRequest.classes?.map(x => x.toString()),
        columnIds: reportRequest.columnIds?.map(x => x.toString()),
        continents: reportRequest.continents?.map(x => x.toString()),
        corporationGroups: reportRequest.corporationGroup?.map(x => x.id?.toString()),
        countries: reportRequest.countries?.map(x => x.id.toString()),
        creationDateFrom: reportRequest.creationDateFrom,
        creationDateTo: reportRequest.creationDateTo,
        customers: reportRequest.customers?.map(x => x.id?.toString()),
        decisionDateFrom: reportRequest.decisionDateFrom,
        decisionDateTo: reportRequest.decisionDateTo,
        hasSapNo: reportRequest.hasSapNo,
        layoutType: reportRequest.layout?.type.toString(),
        projectStatuses: reportRequest.projectStatuses?.map(x => x.toString()),
        responsibles: reportRequest.responsibles?.map(x => x.id?.toString())
    })}`) as Promise<BlobResponse>;

    public static getLayouts = () => Api.get(`${baseUrl}/Report/layouts`) as Promise<DataResult<Layout[]>>;

    public static createLayoutSettingsTemplate = (layoutSettingsTemplate: LayoutSettingsTemplateCreate) => Api.post(`${baseUrl}/Report/LayoutSettingsTemplate`, layoutSettingsTemplate) as Promise<DataResult<LayoutSettingsTemplate>>;

    public static deleteLayoutSettingsTemplate = (id: number) => Api.delete(`${baseUrl}/Report/LayoutSettingsTemplate/${id}`) as Promise<DataResult<boolean>>;

    public static updateLayoutSettingsTemplate = (layoutSettingsTemplate: LayoutSettingsTemplateUpdate) => Api.put(`${baseUrl}/Report/LayoutSettingsTemplate`, layoutSettingsTemplate) as Promise<DataResult<LayoutSettingsTemplate>>;

    public static importKafkaData = () => Api.post(`${baseUrl}/Kafka/importKafkaData`) as Promise<DataResult<KafkaImportResult>>;
}
