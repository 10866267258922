import React, { RefObject } from 'react';
import { InputColors } from '../../../enums';
import { convertNumberToDecimalString, convertNumberToLocal, getScrollToElement, sum } from '../../../services';
import { CostCalculation, CostsPerYear } from '../../../models';
import { NUMBER_LOCALE, SCROLL_ID, TRANSLATIONS } from '../../../constants';
import { useAppSelector, useScrolledPast, useTranslate } from '../../../hooks/common';
import NumberInput from '../../common/NumberInput';
import Sticky from '../../common/Sticky';
import Table, { Cell, Column, Row } from '../../common/Table';
import { getPlannedActualSums } from '../../../services/CostYearsHelper';

export interface CostYearsStickyTableProps {
    getServiceColumns: () => Column[];
    handleServiceClick: (index: number) => void;
    mainTableRef: RefObject<HTMLDivElement>;
    serviceArray: CostCalculation[]
    currentlyShownServices: CostCalculation[];
}

const CostYearsTable = ({ getServiceColumns, handleServiceClick, mainTableRef, serviceArray, currentlyShownServices }: CostYearsStickyTableProps) => {
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const scrolledPastHeader = useScrolledPast(getScrollToElement(mainTableRef));

    const costsTotalVolume = sum(project?.volumes ?? [], 'totalSales');

    const getCalcSums = (serviceList: CostCalculation[]) => serviceList.map(s => s.serviceCategory.index === 6 ? (s.additionalValue ?? 0) * s.quantity * costsTotalVolume : (s.cost ?? 0) * (s.quantity ?? 0));

    const getTotalRows = (costsPerYears?: CostsPerYear[]): Row[] => {
        const calcSums = getCalcSums(currentlyShownServices);
        const plannedActualSums = getPlannedActualSums(currentlyShownServices, costsPerYears);
        const plannedActualSumTotal = getPlannedActualSums(serviceArray, costsPerYears)
            .reduce((sum, x) =>
                x
                    ? sum + x ?? 0
                    : sum
                , 0);
        const calcMinusPlannedActualSums = calcSums.map((x, i) => x - plannedActualSums[i]);
        const calcMinusPlannedActualSumTotal = getCalcSums(serviceArray)
            .map((x, i) => x - getPlannedActualSums(serviceArray, costsPerYears)[i])
            .reduce((sum, x) =>
                x ?? 0
                    ? sum + x ?? 0
                    : sum
                , 0);
        const costChargedSums = getPlannedActualSums(currentlyShownServices, costsPerYears, 'actualCost');
        const costChargedSumTotal = project?.costsPerYears?.flatMap(x => x.services.map(x => x.actualCost ?? 0))
            .reduce((sum, x) =>
                x ?? 0
                    ? sum + (x ?? 0)
                    : sum
                , 0);
        const plannedActualSumCells = plannedActualSums.map<Cell>(x => ({ content: convertNumberToDecimalString(x, 2, NUMBER_LOCALE) }));
        const calcSumCells = calcSums.map<Cell>(x => ({ content: convertNumberToDecimalString(x, 2, NUMBER_LOCALE) }));
        const calcSumTotal = sum(project?.costsCalculations ?? [], 'cost', 'quantity')
            + (project?.costsCalculations?.find(x => x.serviceCategory.index == 6)?.additionalValue ?? 0) * (project?.costsCalculations?.find(x => x.serviceCategory.index == 6)?.quantity ?? 0) * costsTotalVolume;
        const calcMinusPlannedActualCells = calcMinusPlannedActualSums.map((x, i) => ({
            content:
                <NumberInput
                    color={x < 0 ? InputColors.DisplayOnlyRed : InputColors.DisplayOnly}
                    label={convertNumberToLocal(x)}
                    key={`${project?.id ?? 'new-project'} ${i} deviation`}
                    name=''
                    value=''
                    readOnly
                />,
            inputStyle: true
        }));
        const costChargedCells = costChargedSums.map<Cell>(x => ({ content: convertNumberToDecimalString(x, 2, NUMBER_LOCALE) }));

        return [
            {
                key: 'Calc',
                cells: [
                    { content: translate(TRANSLATIONS.main.total) },
                    { content: translate(TRANSLATIONS.main.costCalculated) },
                    { content: convertNumberToDecimalString(calcSumTotal, 2, NUMBER_LOCALE) },
                    ...calcSumCells
                ]
            },
            {
                key: 'Plan/Act',
                cells: [
                    { content: translate(TRANSLATIONS.main.total) },
                    { content: translate(TRANSLATIONS.main.costPlan) },
                    { content: convertNumberToDecimalString(plannedActualSumTotal, 2, NUMBER_LOCALE) },
                    ...plannedActualSumCells
                ]
            },
            {
                key: 'Calc-Plan/Act',
                cells: [
                    { content: translate(TRANSLATIONS.main.total) },
                    { content: translate(TRANSLATIONS.main.deviation) },
                    {
                        content:
                            <NumberInput
                                color={calcMinusPlannedActualSumTotal < 0 ? InputColors.DisplayOnlyRed : InputColors.DisplayOnly}
                                label={convertNumberToLocal(calcMinusPlannedActualSumTotal)}
                                key={`${project?.id ?? 'new-project'} totalDeviation`}
                                name=''
                                value=''
                                readOnly
                            />,
                        inputStyle: true
                    },
                    ...calcMinusPlannedActualCells
                ]
            },
            {
                key: 'Cost charged',
                cells: [
                    { content: translate(TRANSLATIONS.main.total) },
                    { content: translate(TRANSLATIONS.main.costCharged) },
                    { content: convertNumberToDecimalString(costChargedSumTotal ?? 0, 2, NUMBER_LOCALE) },
                    ...costChargedCells
                ]
            }
        ];
    };

    return (
        <Sticky anchor='bottom'>
            <Table
                scrollId={SCROLL_ID.COST_YEARS}
                noHeader={!scrolledPastHeader}
                noHeaderLineBreak
                onHeaderClick={handleServiceClick}
                columns={[
                    { key: 'fiscalYear', label: '', min: '130px', max: '1fr', bold: true, notClickable: true },
                    { label: translate(TRANSLATIONS.main.costType), min: '140px', max: '2fr', bold: true, notClickable: true },
                    { label: translate(TRANSLATIONS.main.totalCosts), min: '120px', max: '2fr', bold: true, notClickable: true },
                    ...getServiceColumns(),
                    { key: 'deleteIcon', label: '', min: '80px', max: '80px', notClickable: true }]}
                rows={getTotalRows(project?.costsPerYears)}
            />
        </Sticky>
         
    );
};

export default CostYearsTable;
