import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HeaderTabType } from '../../../enums';
import { useHook } from '../../../hooks';
import { useAppSelector, useTranslate } from '../../../hooks/common';
import { PRODUCTION_AREAS, TRANSLATIONS } from '../../../constants';
import { setActiveTabType, setProjectHeaderDisabled } from '../../../store';
import BaseInputs from './BaseInputs';
import CustomerAndCountryInputs from './CustomerAndCountryInputs';
import DateInputs from './DateInputs';
import NewCustomerModal from './NewCustomerModal';
import NewContactModal from './NewContactModal';
import ResponsibleInputs from './ResponsibleInputs';
import Button, { ButtonType } from '../../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../../common/Flex';
import Select from '../../common/Select';
import TextArea from '../../common/TextArea';
import TextInput from '../../common/TextInput';
import Tooltip from '../../common/Tooltip';

const GeneralTab = () => {
    const dispatch = useDispatch();
    const project = useAppSelector(state => state.project.project);
    const translate = useTranslate();
    const { closingFiscalYearRestriction } = useHook(x => x.fiscalYears)();
    const { closed, contact, setContact, customer, setCustomer, handleChange, getDateValue, handleContactChange, handleCustomerChange, setShowCreateNewContactModal,
        setShowNewCustomerModal, showCreateNewContactModal, showNewCustomerModal } = useHook(x => x.general)();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setActiveTabType(HeaderTabType.General));
    }, []);

    const renderSaveTooltip = (id: string, children: ReactElement, missingMandatoryFields: string[]) => {
        type ObjectKey = keyof typeof TRANSLATIONS.main;
        const getFieldTranslation = (value: string) => {
            const key = value as ObjectKey;

            return translate(TRANSLATIONS.main[key]);
        };
        const missingMandatoryFieldsTexts = missingMandatoryFields.map(x => `<br />${getFieldTranslation(x)}`).join(',');
        const text = `<div style = 'text-align: center' > ${translate(TRANSLATIONS.main.missingMandatoryFields)}: ${missingMandatoryFieldsTexts}</div>`;

        return <Tooltip key={text} id={id} delayShow={450} text={text} place='bottom' hide={!missingMandatoryFields.length} >{children}</Tooltip>;
    };

    const renderCreateButtons = () => {
        return !closed && (
            <Flex direction={FlexDirection.Row} justification={FlexJustification.SpaceBetween} style={{ marginTop: 40, marginBottom: 40 }}>
                <Button type={ButtonType.Primary} onClick={() => {
                    setShowNewCustomerModal(true);
                    dispatch(setProjectHeaderDisabled(true));
                }}>
                    {translate(TRANSLATIONS.main.createNewCustomer)}
                </Button>
                <Button type={ButtonType.Primary} onClick={() => {
                    setShowCreateNewContactModal(true);
                    dispatch(setProjectHeaderDisabled(true));
                }}>
                    {translate(TRANSLATIONS.main.createNewContactPerson)}
                </Button>
            </Flex>
        );
    };

    return (
        <div className='general'>
            <BaseInputs closed={closed} handleChange={handleChange} getDateValue={getDateValue} />
            <CustomerAndCountryInputs closed={closed} handleChange={handleChange} />
            <ResponsibleInputs closed={closed} handleChange={handleChange} />
            <DateInputs closed={closed} handleChange={handleChange} getDateValue={getDateValue} />           

            <div className='form-group'>
                <div className='length-3'>
                    <Select 
                        label={translate(TRANSLATIONS.main.productionArea)}
                        value={project?.productionArea ?? ''}
                        values={PRODUCTION_AREAS}
                        disabled={closed}
                        mapToString={x => x ?? ''}
                        onSelect={x => handleChange({ productionArea: x })}
                        onReset={() => handleChange({ productionArea: '' })}
                    />
                </div>
                <div className='length-9'>
                    <TextInput label={translate(TRANSLATIONS.main.reminder)} disabled={closingFiscalYearRestriction()} name='reminder' value={project?.reminder ?? ''} onChange={(x, y) => handleChange({ [y]: x })} />
                </div>
            </div>

            <div className='form-group'>
                <div className='length-12'>
                    <TextArea label={translate(TRANSLATIONS.main.comment)} disabled={closingFiscalYearRestriction()} name='comment' value={project?.comment ?? ''} rows={5} onChange={(x, y) => handleChange({ [y]: x })} />
                </div>
            </div>
                
            {renderCreateButtons()}
            <NewCustomerModal
                customer={customer}
                handleCustomerChange={handleCustomerChange}
                renderSaveTooltip={renderSaveTooltip}
                setCustomer={setCustomer}
                setShowNewCustomerModal={setShowNewCustomerModal}
                showNewCustomerModal={showNewCustomerModal}
            />
            <NewContactModal
                contact={contact}
                handleContactChange={handleContactChange}
                renderSaveTooltip={renderSaveTooltip}
                setContact={setContact}
                setShowCreateNewContactModal={setShowCreateNewContactModal}
                showCreateNewContactModal={showCreateNewContactModal}
            />
        </div>
    );
};

export default GeneralTab;
