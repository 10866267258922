import React, { ChangeEvent, CSSProperties, FocusEvent } from 'react';

interface TextAreaProps {
    label: string;
    name: string;
    rows: number;
    value: string;
    dark?: boolean;
    disabled?: boolean;
    error?: boolean;
    placeholder?: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    getMessage?: (value: string) => string;
    onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
    onChange?: (value: string, name: string) => void;
}

const TextArea = ({ dark, disabled, error, label, name, readonly, placeholder, required, rows, style, value, onBlur, onChange, getMessage }: TextAreaProps) => {
    const message = getMessage ? getMessage(value) : '';

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => onChange && onChange(event.target.value, event.target.name);

    return (
        <div className={`text-area ${dark ? 'dark' : ''}`} style={style}>
            <div className='text-area-field'>
                <textarea className={error ? 'error' : ''} disabled={disabled} name={name} placeholder={placeholder} rows={rows} value={value}
                    readOnly={readonly} required={required} onBlur={onBlur} onChange={handleChange} />
                <div className={`text-area-label ${placeholder || Boolean(value) ? 'filled' : ''}`}>
                    {label}
                </div>
            </div>
            {getMessage &&
                <div className={`text-area-message ${error ? 'error' : ''}`}>
                    {message ? message : <br />}
                </div>
            }
        </div>
    );
};

export default TextArea;
