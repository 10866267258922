import { QueryParameters } from '../models';

export const queryBuilder = (queryParameters: QueryParameters) =>
    Object.keys(queryParameters)
        .filter(key => {
            const value = queryParameters[key];

            return value != null && (!Array.isArray(value) || value.some(x => x != null));
        })
        .map(key => {
            const value = queryParameters[key];

            const getQueryParameter = (queryValue: string) => `${encodeURIComponent(key)}=${encodeURIComponent(queryValue)}`;

            switch (typeof value) {
                case 'string':
                    return getQueryParameter(value);
                case 'boolean':
                    return getQueryParameter(value.toString());
                case 'object':
                    return value.filter(x => x != null).map(x => getQueryParameter(x as string)).join('&');
            }
        })
        .join('&');
    
