import React, { CSSProperties, Key, KeyboardEvent, ReactNode, ReactText, RefObject, useEffect, useRef, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Icon, { IconType } from './Icon';
import Tooltip from './Tooltip';

export interface Cell {
    content: ReactText | JSX.Element;
    inputStyle?: boolean;
}
interface ColumnCommon {
    max?: string;
    min?: string;
}

interface InteractiveColumn extends ColumnCommon {
    label: ReactText;
    bold?: boolean;
    button?: { icon: IconType, position: 'left' | 'right' };
    highlighted?: boolean;
    key?: ReactText;
    tooltip?: ReactText;
    notClickable?: boolean;
    uninteractive?: false;
}
interface UninteractiveColumn extends ColumnCommon {
    key: Key;
    uninteractive: true;
    cellsNotClickable?: boolean;
}

export type Column = InteractiveColumn | UninteractiveColumn;

export interface Row {
    key: ReactText;
    cells: Cell[];
    selected?: boolean;
    divider?: boolean;
    onClick?: () => void;
}

interface TableProps {
    columns: Column[];
    rows: Row[];
    colored?: (index: number) => boolean;
    dark?: boolean;
    hoverable?: boolean;
    indexSortedBy?: number;
    loading?: boolean;
    noHeader?: boolean;
    noScrollbar?: boolean;
    reverse?: boolean;
    scrollId?: string;
    tableRef?: RefObject<HTMLDivElement>;
    thickBottomLine?: (index: number) => boolean;
    onHeaderClick?: (index: number) => void;
    noHeaderLineBreak?: boolean;
}

const AUTO = 'auto';

const Table = ({ columns, colored, dark, hoverable, indexSortedBy, loading, noHeader, noScrollbar, reverse, rows, scrollId, tableRef, thickBottomLine, onHeaderClick, noHeaderLineBreak }: TableProps) => {
    const [enableLoadingAnimation, setEnableLoadingAnimation] = useState(false);
    const mounted = useRef(false);
    const gridStyle: CSSProperties = {
        gridTemplateColumns: columns.map(x => `minmax(${x.min ?? AUTO}, ${x.max ?? AUTO})`).join(' '),
        gridTemplateRows: [AUTO, ...rows.map(x => x.divider ? '1fr' : AUTO)].join(' ')
    };

    useEffect(() => {
        if (!enableLoadingAnimation) {
            if (mounted.current) {
                setEnableLoadingAnimation(true);
            } else {
                mounted.current = true;
            }
        }
    }, [loading]);

    const handleSelect = (column: InteractiveColumn, index: number) => {
        if (onHeaderClick && !column.notClickable) {
            onHeaderClick(index);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, column: InteractiveColumn, index: number) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect(column, index);
        }
    };

    const sized = (column: Column) => Boolean(column.min || column.max);
    
    const selectable = (column: InteractiveColumn) => onHeaderClick && !column.notClickable;
    
    const renderTooltipWrapper = (text: string, tooltipText?: string) => <Tooltip key={text} id={text} delayShow={750} text={tooltipText ?? text}>{text}</Tooltip>;

    const renderScrollSyncWrapper = (children: ReactNode) => scrollId ? <ScrollSyncPane group={scrollId}>{children}</ScrollSyncPane> : children;

    return (
        <div ref={tableRef} className={`table ${dark ? 'dark' : ''}`}>
            {renderScrollSyncWrapper(
                <div className={`table-container ${noScrollbar ? 'no-scrollbar' : ''}`}>
                    <div className='table-grid' style={gridStyle}>
                        {!noHeader &&
                            <div className='table-row'>
                                {columns.map((x, i) => x.uninteractive
                                    ? <div key={x.key} className='table-cell table-header-cell uninteractive' /> 
                                    : <div
                                        key={x.key ?? x.label}
                                        className={`table-cell table-header-cell ${x.bold ? 'bold' : ''} ${x.button ? `button-${x.button.position ?? ''}` : ''}`
                                            + ` ${x.highlighted ? 'highlighted' : ''} ${noHeaderLineBreak ? 'no-header-linebreak' : ''} ${selectable(x) ? 'clickable' : ''}`}
                                        tabIndex={selectable(x) ? 0 : undefined}
                                        onClick={() => handleSelect(x, i)}
                                        onKeyDown={e => handleKeyDown(e, x, i)}
                                    >
                                        {x.tooltip ? renderTooltipWrapper(x.label.toString(), x.tooltip.toString()) : x.label}
                                        {x.button && <div className='button-icon'>{!x.notClickable && <Icon type={x.button.icon} />}</div>}
                                        {indexSortedBy === i
                                            ? <Icon type={reverse ? IconType.SortDescending : IconType.SortAscending} />
                                            : <div className='sorting-placeholder' />
                                        }
                                    </div>
                                )}
                            </div>
                        }
                        {rows.map((x, i) =>
                            <div key={x.key} className={`table-row ${hoverable && !x.divider ? 'hoverable' : ''} ${x.onClick ? 'clickable' : ''} ${noHeader ? 'no-header' : ''} ` 
                                + `${noScrollbar ? 'no-border-last' : ''}`} onClick={x.onClick}>
                                {columns.map((y, j) =>
                                    <div key={y.uninteractive ? y.key : y.key ?? y.label} className={`table-cell table-data-cell ${columns.some(sized) ? 'single-line' : ''} ${sized(y) ? 'sized' : ''} `
                                        + `${x.cells[j]?.inputStyle ? 'input-style' : ''} ${y.uninteractive ? 'uninteractive' : y.highlighted ? 'highlighted' : ''}  ${!x.divider && colored && colored(i) ? 'colored' : ''} `
                                        + `${!x.divider && thickBottomLine && thickBottomLine(i) ? 'thick-bottom-line' : ''}`}>
                                        {x.divider
                                            ? <div className='divider-content' />
                                            : x.cells[j]?.content
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={`loading-overlay ${enableLoadingAnimation ? 'loading-animation' : ''} ${loading ? 'loading' : ''}`}>
                <svg className='loader-icon' viewBox='0 0 50 50'>
                    <circle cx='25' cy='25' r='20' />
                </svg>
            </div>
        </div>
    );
};

export default Table;
