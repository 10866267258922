import React, { useState } from 'react';
import Modal from '../../../common/Modal';
import Flex, { FlexDirection, FlexJustification } from '../../../common/Flex';
import FileUpload from '../../../common/FileUpload';
import Button, { ButtonType } from '../../../common/Button';
import Icon, { IconType } from '../../../common/Icon';
import Title from '../../../common/Title';
import SpacingContainer from '../../../common/SpacingContainer';
import { useHook } from '../../../../hooks';
import { useTranslate } from '../../../../hooks/common';
import { TRANSLATIONS } from '../../../../constants';

interface ProjectAttachmentsUploadModalProps {
    open: boolean;
    onOpenChange: (value: boolean) => void;
    projectId: string;
}

const ProjectAttachmentsUploadModal = ({ open, onOpenChange, projectId }: ProjectAttachmentsUploadModalProps) => {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const translate = useTranslate();

    const uploadDisabled = !fileName && !file;

    const { onSaveProjectAttachment } = useHook(x => x.projectAttachment)();

    const handleFileChange = (file: File) => {
        setFileName(file.name);
        setFile(file);
    };

    const handleModalAfterClose = () => {
        setFileName('');
        setFile(null);
    };

    const handleUpload = () => {
        if (file && projectId) {
            onSaveProjectAttachment(fileName, projectId, file);
            handleModalClose();
        }
    };

    const handleModalClose = () => {
        onOpenChange(false);
    };

return (
    <Modal open={open} movable onAfterClose={handleModalAfterClose}>
        <Title>{translate(TRANSLATIONS.main.uploadAttachment)}</Title>
            <SpacingContainer>
            <FileUpload
                fileName={fileName}
                onFileChange={handleFileChange}
                maxSizeInMegabytes={20}
                validTypes={[
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/msword',
                    'application/pdf',
                    'application/vsd.ms-outlook',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-word.document.macroEnabled.12',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                    'application/vnd.ms-excel',
                    'application/vnd.ms-excel.sheet.macroEnabled.12',
                    'image/jpeg',
                    'image/png'
                ]}
            />
            </SpacingContainer>
            <Flex justification={FlexJustification.FlexEnd} direction={FlexDirection.Row} gap={8} style={{ marginTop: 32 }}>
                <Button type={ButtonType.Primary} disabled={uploadDisabled} onClick={handleUpload}>
                    <Icon type={IconType.Upload} />
                    {translate(TRANSLATIONS.main.upload)}
                </Button>
                <Button type={ButtonType.Tertiary} onClick={handleModalClose}>
                    <Icon type={IconType.CloseBig} />
                    {translate(TRANSLATIONS.main.cancel)}
                </Button>
            </Flex>
        </Modal>
    );
};

export default ProjectAttachmentsUploadModal;
