import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import hash from 'object-hash';
import { Api, apiCall, clearIncompleteDatesFromProject } from '../../../services';
import { EMPTY_HISTORY, EMPTY_PROJECT, TRANSLATIONS } from '../../../constants';
import { ProjectStatus } from '../../../enums';
import { useHook } from '../../Hooks';
import { useAppSelector, useDebounce, usePrevious, useTranslate } from '../../common';
import { addSnackbar, setAvailableValues, setInitialHash, setInitialStatus, setProject, setProjectChanged, setProjectHeaderDisabled, setProjectLoading, setSelectedDropdownValues } from '../../../store';
import { SnackbarType } from '../../../components/common/Snackbar';

export const useProjectLoad = () => {
    const dispatch = useDispatch();
    const debouncedProject = useDebounce(useAppSelector(state => state.project.project), 500);
    const initialHash = useAppSelector(state => state.project.initialHash);
    const project = useAppSelector(state => state.project.project);
    const { handleCurrentFiscalYearGet } = useHook(x => x.fiscalYears)();
    const translate = useTranslate();
    const { id } = useParams();
    const previousId = usePrevious(id);
    const { onProjectAttachmentsGet } = useHook(x => x.projectAttachment)();
    
    useEffect(() => {
        if (id && (id === 'new' || previousId !== 'new')) {
            (async () => {
                dispatch(setProjectLoading(true));

                let projectPromise = Promise.resolve();
                let projectAttachmentsPromise = Promise.resolve();
                let currentFiscalYearPromise = Promise.resolve();
                const availableValuesPromise = handleAvailableValuesGet();
                currentFiscalYearPromise = handleCurrentFiscalYearGet();

                if (id === 'new') {
                    dispatch(setProject(EMPTY_PROJECT));
                    dispatch(setInitialHash(hash(clearIncompleteDatesFromProject(EMPTY_PROJECT))));
                    dispatch(setInitialStatus(ProjectStatus.Intern));
                    dispatch(setProjectHeaderDisabled(false));
                    dispatch(setSelectedDropdownValues({
                        historyDateOfAuthorizedFolder: EMPTY_HISTORY
                    }));
                } else {
                    projectPromise = handleProjectGet(id);
                    projectAttachmentsPromise = onProjectAttachmentsGet(id);
                }

                await projectPromise;
                await projectAttachmentsPromise;
                await currentFiscalYearPromise;
                await availableValuesPromise;

                dispatch(setProjectLoading(false));
            })();
        }
    }, [id]);

    useEffect(() => {
        debouncedProject?.id === project?.id && dispatch(setProjectChanged(hash(clearIncompleteDatesFromProject(debouncedProject) ?? {}) !== initialHash));
    }, [debouncedProject, initialHash]);

    const handleAvailableValuesGet = async () => {
        return apiCall(
            Api.getAvailableValues(),
            async x => {
                dispatch(setAvailableValues(x.data));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadAvailableValues), type: SnackbarType.Error }));
            }
        );
    };

    const handleProjectGet = async (id: string) => {
        return apiCall(
            Api.getProject(id),
            async x => {
                const project = x.data;
                const defaultSelectedHistory = project?.histories ? project.histories[project.histories.length - 1] : EMPTY_HISTORY;

                dispatch(setProject(project));
                dispatch(setInitialHash(hash(clearIncompleteDatesFromProject(project))));
                dispatch(setInitialStatus(project.status));
                dispatch(setProjectHeaderDisabled(false));
                dispatch(setSelectedDropdownValues({
                    historyDateOfAuthorizedFolder: defaultSelectedHistory
                }));
            },
            async () => {
                dispatch(addSnackbar({ text: translate(TRANSLATIONS.snackbarMessages.cannotLoadProjects), type: SnackbarType.Error }));
            }
        );
    };

};
