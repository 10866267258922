import { FilterValues } from '../models';
import { ProjectStatus } from '../enums';

export const INITIAL_FILTER_VALUES: FilterValues = {
    customerName: '',
    projectName: '',
    responsibleName: '',
    sapNr: '',
    statuses: [ProjectStatus.Info, ProjectStatus.Discuss, ProjectStatus.Order, ProjectStatus.Production]
};
